import { ReactNode, createContext, useContext, useState } from "react";

interface ModalContextType {
  isContactSalesModalOpen: boolean;
  isBookADemoModalOpen: boolean;
  openContactSalesModal: () => void;
  closeContactSalesModal: () => void;
  openBookADemoModal: () => void;
  closeBookADemoModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isContactSalesModalOpen, setContactSalesModalOpen] =
    useState<boolean>(false);
  const [isBookADemoModalOpen, setBookADemoModalOpen] =
    useState<boolean>(false);

  const openContactSalesModal = () => {
    setContactSalesModalOpen(true);
    setBookADemoModalOpen(false);
  };

  const closeContactSalesModal = () => {
    setContactSalesModalOpen(false);
  };

  const openBookADemoModal = () => {
    setBookADemoModalOpen(true);
    setContactSalesModalOpen(false); // Ensure the other modal is closed
  };

  const closeBookADemoModal = () => {
    setBookADemoModalOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        isContactSalesModalOpen,
        isBookADemoModalOpen,
        openContactSalesModal,
        closeContactSalesModal,
        openBookADemoModal,
        closeBookADemoModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
};
