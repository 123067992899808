import React, { useEffect, useState } from "react";
import faqIcon from "../../assets/icons/faq-message-chat.svg";
import Product from "./Product";
import Audit from "./Audit";
import Framework from "./Framework";
import Security from "./Security";
import Support from "./Support";
import Cost from "./Cost";
import Button from "../../components/Button";
import { ContactSalesCard } from "../Home";
import contactSaleImg from "../../assets/images/contact-sale-img.svg";
import speakToUs from "../../assets/icons/speak-to-us-icon.svg";
import emailUs from "../../assets/icons/email-us-icon.svg";
import getDemo from "../../assets/icons/get-demo-icon.svg";
import homeRocketIcon from "../../assets/icons/home-rocket-icon.svg";
import homeDashboardImg from "../../assets/images/vegeel-dashboard-home-img.svg";
import homeDashboardImgTwo from "../../assets/images/desktop-home-img.svg";
import { useMenuContext, useModalContext } from "../../context";

interface TabsProps {
  tab: number;
  switchTab: (tab: number) => void;
}

function FaqTabs({ tab, switchTab }: TabsProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="flex lg:justify-evenly justify-between items-center overflow-x-scroll horizontal-scroll lg:w-[70%]">
      <div
        className={`${
          tab === 1
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(1)}
      >
        Product
      </div>
      <div
        className={`${
          tab === 2
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(2)}
      >
        Audit
      </div>
      <div
        className={`${
          tab === 3
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(3)}
      >
        Framework
      </div>

      <div
        className={`${
          tab === 4
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(4)}
      >
        Security
      </div>

      <div
        className={`${
          tab === 5
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(5)}
      >
        Support
      </div>

      <div
        className={`${
          tab === 6
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(6)}
      >
        Cost
      </div>
    </section>
  );
}

export default function Faq() {
  const [tab, setTab] = useState<number>(1);
  const { closeDropdown } = useMenuContext();
  const { openBookADemoModal, openContactSalesModal } = useModalContext();

  return (
    <div onClick={() => closeDropdown()}>
      <div className="bg-[#000033] py-[3rem] lg:py-[8rem] flex justify-center items-center flex-col">
        <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[258px] lg:w-[320px] h-[40px] lg:h-[48px] rounded-full">
          <span className="pr-2">
            <img src={faqIcon} alt="icon" />
          </span>
          <h3 className="text-[0.75rem] lg:text-[0.875rem] text-[#93AAFD]">
            Frequently Asked Questions (FAQs)
          </h3>
        </div>
        <div className="px-5 text-center text-[#FFFFFF] pt-4 lg:pt-[20px] flex justify-center items-center flex-col">
          <h1 className="text-[3rem] lg:text-[5rem] font-medium leading-[56px] lg:leading-[92px] px-3 lg:tracking-tight">
            Frequently Asked <br className="hidden lg:flex" /> Questions{" "}
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              (FAQs).
            </span>
          </h1>
          <h2 className="text-[1rem] lg:text-[1.25rem] pt-4 lg:pt-[20px] leading-[28px]">
            Take insights from our frequently asked <br className="lg:hidden" />{" "}
            questions, as we provide clear answers <br className="lg:hidden" />{" "}
            ranging <br className="hidden lg:flex" /> from the product, our
            solutions, cost, <br className="lg:hidden" />
            security etc.
          </h2>
        </div>
      </div>

      <section className="py-[48px] lg:flex lg:justify-center lg:items-center lg:flex-col px-[18px]">
        <FaqTabs tab={tab} switchTab={setTab} />
        {tab === 1 ? (
          <Product />
        ) : tab === 2 ? (
          <Audit />
        ) : tab === 3 ? (
          <Framework />
        ) : tab === 4 ? (
          <Security />
        ) : tab === 5 ? (
          <Support />
        ) : (
          <Cost />
        )}
        <div className="border border-[#D7DFE9] rounded-[6px] lg:rounded-[15px] p-[20px] lg:px-[20px] lg:py-[30px] mt-[32px] lg:mt-[48px] lg:w-[70%] lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-[78%]">
            <h2 className="text-[#060B59] font-medium text-[1.25rem] lg:text-[2rem] pb-[24px] lg:pb-[12px]">
              Still have questions?
            </h2>
            <p className="text-[#455468] text-[1rem] lg:text-[1.125rem] pb-[24px] lg:pb-0">
              Can’t find the answer you’re looking for? Please contact us with
              your questions.
            </p>
          </div>

          <div className="lg:w-[18%]">
            <Button
              onClick={openContactSalesModal}
              variant="contained"
              size="large"
              icon={false}
            >
              Contact sales
            </Button>
          </div>
        </div>
      </section>

      <section className="px-[15px] bg-[#EDF1FF] py-[48px] lg:px-[150px]">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-[40%]">
            <h2 className="text-[#060B59] text-[1.75rem] font-bold">
              Contact sales
            </h2>
            <p className="text-[#455468] text-[1rem] leading-[28px]">
              Tell us what your business needs and we will find the best
              solution for you. We’d be happy to walk you through a demo or
              discuss how we can simplify and automate your compliance process.{" "}
            </p>
          </div>
          <div className="lg:w-[45%]">
            <div className="pt-6 w-full h-full">
              <img
                src={contactSaleImg}
                alt="Contact Sale"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>

        <div className="pt-4 lg:pt-10 flex justify-center items-center flex-col gap-4 lg:flex-row">
          <ContactSalesCard
            image={speakToUs}
            name="Speak with us"
            detail="+234-901 495 7203"
            type="phone"
            phoneNo={"+2349014957203"}
          />
          <ContactSalesCard
            image={emailUs}
            name="Email us"
            detail="hello@vegeel.com"
            type="email"
            phoneNo={""}
          />
          <ContactSalesCard
            image={getDemo}
            name="Get demo"
            detail="Schedule now"
            type="demo"
            phoneNo={""}
            onClick={openBookADemoModal}
          />
        </div>
      </section>

      <section className="pt-[48px] bg-[#040B4E]">
        <div className="flex justify-center items-center flex-col">
          <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[300px] lg:370px h-[44px] lg:h-[48px] rounded-full py-[12px] px-[15px]">
            <span className="pr-2">
              <img src={homeRocketIcon} alt="rocket" />
            </span>
            <h3 className="text-[0.75rem] text-[#93AAFD]">
              Begin your journey to compliance efficiency
            </h3>
          </div>
          <h2 className="text-[3rem] lg:text-[3.5rem] font-bold text-[#FFFFFF] text-center px-5 leading-[56px] lg:leading-[64px] pt-3">
            Elevate your <br className="lg:hidden" />
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              security
            </span>{" "}
            and <br />
            <span className="gradient-text bg-gradient-to-l-custom bg-clip-text text-transparent">
              compliance
            </span>{" "}
            posture with <br className="lg:hidden" /> Vegeel
          </h2>
          <p className="text-[#FAFAFA] text-[1rem] leading-[28px] text-center pt-4">
            Join the ranks of forward-thinking <br className="lg:hidden" />{" "}
            organizations that trust Vegeel to fortify <br /> their security and
            compliance efforts.
          </p>
          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 px-7 w-full lg:w-[40%]">
            <Button
              onClick={openBookADemoModal}
              variant="contained"
              size="large"
              icon
            >
              Request demo
            </Button>
            <Button
              onClick={openContactSalesModal}
              variant="plain"
              size="large"
              icon
              iconType="arrow"
            >
              Contact sales
            </Button>
          </div>

          <div className="pt-14 w-full h-full lg:hidden">
            <img
              src={homeDashboardImg}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
          <div className="pt-14 w-full h-full hidden lg:flex lg:px-[4.5rem]">
            <img
              src={homeDashboardImgTwo}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
