import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/vegeel-logo.svg";
import Button from "../../Button";
import { navlinks } from "../../../constants";
import chevrondown from "../../../assets/icons/chevron-down.svg";
import chevronup from "../../../assets/icons/chevronup.svg";
import CustomIcon from "../../CustomIcon";
import { useMenuContext, useModalContext } from "../../../context";

export default function DesktopHeader() {
  const { activeMenu, handleNavigate, showDropdown, toggleDropdown } =
    useMenuContext();
  const { openBookADemoModal } = useModalContext();

  const navigate = useNavigate();

  return (
    <div className="h-full w-[90%] flex justify-between items-center px-[20px] py-[6px]">
      <div className="w-[20%]">
        <Link to={"/"}>
          <img
            src={logo}
            alt="Vegeel Logo"
            title="Vegeel Logo"
            className="w-[117px]"
          />
        </Link>
      </div>

      <div className="w-[50%] flex justify-between">
        {navlinks.map((link) => {
          const isSolutions = link.label === "Solutions";
          return (
            <div key={link.label} className="w-full flex relative">
              <button
                onClick={() =>
                  link.pathList
                    ? toggleDropdown(link.label)
                    : handleNavigate(link.path, link.label, navigate)
                }
                className={`h-[48px] w-full flex items-center gap-[10px] text-center ${
                  activeMenu === link.label && !link.pathList
                    ? "bg-transparent"
                    : ""
                }`}
              >
                <span
                  className={`${
                    showDropdown === link.label
                      ? "text-main_color"
                      : "text-[#111111]"
                  } font-medium leading-[28px]`}
                >
                  {link.label}
                </span>
                {link.pathList && (
                  <img
                    src={showDropdown === link.label ? chevronup : chevrondown}
                    alt="dropdown"
                  />
                )}
              </button>
              {link.pathList && showDropdown === link.label && (
                <ul
                  className={`${
                    isSolutions ? "solutions-dropdown" : "default-dropdown"
                  } p-3 flex-wrap justify-between items-center gap-4 text-dark_color font-medium leading-[28px] absolute top-[70px] left-0`}
                >
                  {link.pathList.map((path, i) => (
                    <li
                      key={i}
                      onClick={() =>
                        handleNavigate(path.path, path.label, navigate)
                      }
                      className={`py-[10px] px-2 flex items-center gap-4 hover:bg-gray-200 rounded-[6px] cursor-pointer ${
                        activeMenu === path.label ? "bg-gray-200" : ""
                      }`}
                    >
                      <CustomIcon SvgIcon={path.img} size={48} />
                      <div className="flex flex-col gap-[2px] w-[80%]">
                        <span className="text-[#060B59] text-lg font-medium leading-[30px]">
                          {path.label}
                        </span>
                        <span className="text-[#111111] font-normal leading-[24px]">
                          {path.desc}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        })}
      </div>

      <ul className="w-[20%] flex gap-6 items-center justify-end">
        {/* <a
          href="/#"
          target="_blank"
          className="text-main_color font-medium leading-[28px]"
        >
          Sign in
        </a> */}

        <Button
          onClick={openBookADemoModal}
          variant="contained"
          icon={false}
          size="small"
        >
          Request Demo
        </Button>
      </ul>
    </div>
  );
}
