interface TabsProps {
  tab: number;
  switchTab: (tab: number) => void;
}

export function LegalTabs({ tab, switchTab }: TabsProps) {
  return (
    <section className="flex lg:justify-evenly justify-between overflow-x-scroll horizontal-scroll lg:w-[70%]">
      <div
        className={`${
          tab === 1
            ? " text-[#1B4DFF] border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(1)}
      >
        Terms of service
      </div>
      <div
        className={`${
          tab === 2
            ? " text-[#1B4DFF] border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(2)}
      >
        Privacy Policy
      </div>
      <div
        className={`${
          tab === 3
            ? " text-[#1B4DFF] border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 lg:text-[20px] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(3)}
      >
        Cookies Policy
      </div>
    </section>
  );
}
