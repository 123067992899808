import React, { useEffect, useState } from "react";

import MobileHeader from "./Mobile";
import DesktopHeader from "./Desktop";

export default function Header() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="h-[56px] w-full bg-white border-b border-[#D7DFE9] fixed top-0 left-0 lg:h-[96px] lg:flex lg:justify-center z-20">
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
    </header>
  );
}
