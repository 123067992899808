import React from "react";
import { FaqItem } from "../Product";

const Security = () => {
  const faqData = [
    {
      question:
        "How does Vegeel ensure the confidentiality, integrity, and availability of our information?",
      answer:
        "Vegeel is committed to safeguarding your critical information. Our platform employs advanced security measures to maintain the confidentiality, integrity, and availability of your data. This includes robust encryption, real-time monitoring, and compliance with industry standards to protect against unauthorized access and data breaches.",
    },
    {
      question: "How does Vegeel ensure the security of my data?",
      answer:
        "Vegeel employs advanced security measures to ensure the confidentiality, integrity, and availability of your data. This includes robust encryption, secure data storage, regular security audits, and adherence to industry best practices to protect against unauthorized access and data breaches.",
    },
    {
      question:
        "How does Vegeel handle data privacy and compliance with regulations?",
      answer:
        "Vegeel is committed to data privacy and compliance with relevant regulations. We have strict data protection policies in place to ensure that your data is handled with the utmost care and in accordance with legal requirements.",
    },
    {
      question:
        "What measures does Vegeel take to prevent unauthorized access?",
      answer:
        "Vegeel employs multiple layers of security to prevent unauthorized access, including multi-factor authentication (MFA), role-based access controls, regular security audits, and continuous monitoring of our systems for potential threats.",
    },
    {
      question: "Can I control who has access to my data in Vegeel's software?",
      answer:
        "Yes, Vegeel allows you to control access to your data through role-based access controls. You can assign different levels of access to users based on their roles and responsibilities within your organization.",
    },
    {
      question: "How can I learn more about Vegeel's security practices?",
      answer:
        "To learn more about Vegeel's security practices, you can visit our website at www.vegeel.com/security where we provide detailed information on our security measures and policies. You can also contact our security team directly at security@vegeel.com for specific inquiries.",
    },
  ];

  return (
    <div className="pt-[32px] px-[15px] flex justify-center items-center flex-col w-full">
      {faqData.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default Security;
