import React from "react";

export default function Infowecollect() {
  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <h1 className="font-[700] text-[28px] text-dark_color">
          Information We Collect
        </h1>
        <p className="text-dark_color text-[16px]">
          At Vegeel Technology Limited, we are dedicated to safeguarding your
          personal and non-personal information. This section outlines the types
          of data we collect and the methods we employ in gathering this
          information. We adhere to the Nigeria Data Protection Regulation
          [NDPR] and other relevant data protection laws, ensuring that your
          privacy is maintained at all times.
        </p>
        <div>
          <h2 className="font-bold text-dark_color text-[20px]">
            Personal Information
          </h2>
          <p className="text-dark_color text-[16px]">
            Personal information refers to any data that can be used to identify
            you as an individual. This includes, but is not limited to:
          </p>
          <ol className="list-decimal text-dark_color flex flex-col gap-[20px]">
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Business Contact Information:
                </span>{" "}
                This covers your email address, telephone number, business
                address, and any other contact details you provide to us.
              </p>
            </li>
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Financial Information:
                </span>{" "}
                If you engage in transactions through our services, we or our
                partners may collect your payment details, including credit card
                numbers, bank account information, and billing addresses.
              </p>
            </li>
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Employment Information:
                </span>{" "}
                For clients utilizing our corporate services, we may collect
                information about your job title, employer, and professional
                qualifications.
              </p>
            </li>
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  {" "}
                  Account Information:
                </span>{" "}
                When you create an account with us, we gather your username,
                password, and other security-related information used to protect
                your account.
              </p>
            </li>
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span>Technical Information: </span>This includes your IP
                address, login data, browser type and version, time zone
                setting, browser plug-in types and versions, operating system
                and platform, and other technology on the devices you use to
                access our website.
              </p>
            </li>
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">Usage Data:</span>{" "}
                Information about how you use our website, products, and
                services, including your browsing patterns and interactions with
                our online resources.
              </p>
            </li>
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Communications Data:{" "}
                </span>
                Any communication you send to us, including emails, phone calls,
                social media messages, and other correspondence, may be recorded
                and stored.
              </p>
            </li>
          </ol>
        </div>
        <div>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Non-Personal Information
          </h2>
          <p className="text-dark_color text-[16px]">
            Non-personal information is data that does not directly identify
            you. This information is typically aggregated and anonymized, and is
            used to enhance our services without compromising your identity.
            Examples include:
          </p>
          <ol className="list-decimal flex flex-col gap-[20px]">
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Aggregated Data:
                </span>{" "}
                This is statistical or demographic data derived from your
                personal information but does not directly reveal your identity.
                For example, we may aggregate your usage data to calculate the
                percentage of users accessing a specific website feature.
              </p>
            </li>
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Technical Data:
                </span>{" "}
                Similar to the personal technical information, but anonymized.
                This includes data about your devices and internet connection,
                collected to improve user experience and optimize our services.
              </p>
            </li>
            <li className="">
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Behavioral Data:
                </span>{" "}
                Insights gathered from user interactions with our website, such
                as clickstream data, page visits, and interaction times, which
                help us understand how users engage with our content.
              </p>
            </li>
          </ol>
        </div>
        <div>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Collection Methods
          </h2>
          <p className="text-dark_color text-[16px]">
            We employ various methods to collect personal and non-personal
            information, ensuring transparency and consent in line with NDPR
            requirements:
          </p>
          <ol className="list-decimal flex flex-col gap-[20px]">
            <li className="">
              <p className="text-dark_color text-[16px]">
                {" "}
                <span className="font-bold text-dark_color">
                  Direct Interactions:
                </span>{" "}
                You provide data directly when you:
              </p>

              <ul className="list-disc">
                <li>
                  <p className="text-dark_color text-[16px]">
                    Create an account on our website.
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    Subscribe to our services or publications.
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    Request marketing information or contact us via email,
                    phone, or social media.{" "}
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    Participate in surveys, contests, or promotions.
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    Provide feedback or communicate with us.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Automated Technologies:{" "}
                </span>
                We collect technical data through:{" "}
              </p>
              <ul className="list-disc">
                <li>
                  <p className="text-dark_color text-[16px]">
                    <span className="font-bold text-dark_color">
                      Cookies and Similar Technologies:{" "}
                    </span>{" "}
                    When you visit our website, we use cookies and similar
                    technologies to collect data. Cookies are small files placed
                    on your device that help us understand user behavior, track
                    your interactions with our site, and personalize your
                    experience. You can manage cookie preferences through your
                    browser settings.{" "}
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    <span className="font-bold text-dark_color">
                      Log Files:{" "}
                    </span>
                    These files automatically record data about your use of our
                    website, including your IP address, browser type, internet
                    service provider, referring/exit pages, and date/time
                    stamps.{" "}
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    <span className="font-bold text-dark_color">
                      Web Beacons:{" "}
                    </span>{" "}
                    These are small graphic images included in our emails and
                    web pages to monitor user behavior and gather data on email
                    interactions and website traffic.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p className="text-dark_color text-[16px]">
                <span className="font-bold text-dark_color">
                  Third-Party Sources:{" "}
                </span>{" "}
                We may receive data from third parties, including:{" "}
              </p>
              <ul className="list-disc">
                <li>
                  <p className="text-dark_color text-[16px]">
                    <span className="font-bold text-dark_color">
                      Analytics Providers:
                    </span>{" "}
                    Services like Google Analytics that provide insights into
                    how users interact with our website.{" "}
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    <span className="font-bold text-dark_color">
                      Advertising Networks:
                    </span>{" "}
                    Information from advertising networks that help us
                    understand user preferences and deliver relevant ads.{" "}
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    <span className="font-bold text-dark_color">
                      Service Providers:
                    </span>{" "}
                    Data from service providers who assist us in running our
                    business, such as Infrastructure and customer support
                    platforms.{" "}
                  </p>
                </li>
                <li>
                  <p className="text-dark_color text-[16px]">
                    <span className="font-bold text-dark_color">
                      Publicly Available Sources:
                    </span>{" "}
                    Information from public databases and social media
                    platforms, which we use to enhance our services and customer
                    interactions.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
