import React from "react";

export default function Choicesright() {
  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <h1 className="font-[700] text-[28px] text-dark_color">
          Your Choices and Rights
        </h1>
        <p className="text-dark_color text-[16px]">
          At Vegeel Technology Limited, we are committed to ensuring that you
          have control over your personal information. This section outlines
          your rights regarding the access, updating, and deletion ofyour data,
          as well as your options for opting out of certain data processing
          activities. We adhere tothe Nigeria Data Protection Regulation [NDPR]
          and other applicable data protection laws to protectyour privacy and
          empower you with choices about how your information is used.
        </p>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Access and Update Information
        </h2>
        <p className="text-dark_color text-[16px]">
          You have the right to access and update the personal information we
          hold about you. This ensures that your data is accurate, current, and
          complete. Here's how you can exercise this right:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p className="text-dark_color text-[16px]">Requesting Access</p>
            <ul className="text-dark_color text-[16px] list-disc">
              <li>
                What You Can Request: You have the right to request a copy of
                the personal information we hold about you. This includes data
                related to your account, transactions, communications, and any
                other information you have provided to us.
              </li>
              <li>
                How to Request Access: To request access to your information,
                please contact us at 35 Adebisi St, Yaba, Lagos. We will provide
                you with a form to fill out, and we may require proof of
                identity to ensure that we are disclosing data to the correct
                individual.
              </li>
              <li>
                Response Time: We aim to respond to all access requests within
                one month, in accordance with NDPR guidelines. If your request
                is complex or involves a large amount of data, we may extend
                this period by up to two additional months, but we will inform
                you of any delay and the reasons for it.
              </li>
            </ul>
          </li>
          <li>
            <p>Updating Your Information</p>
            <ul className="list-disc">
              <li>
                What You Can Update: You can update any personal information
                that is inaccurate or incomplete. This includes your contact
                details, account information, and any preferences or consents
                you have provided.
              </li>
              <li>
                How to Update Information: You can update your information
                directly through your account settings on our website. If you
                need assistance, you can also contact us at 35 Adebisi St, Yaba,
                Lagos, and our support team will help you make the necessary
                changes.
              </li>
              <li>
                Verification Process: For certain changes, particularly those
                related to sensitive information or security settings, we may
                require you to verify your identity before making updates to
                ensure the security of your account.
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Opt-out Options
        </h2>
        <p className="text-dark_color text-[16px]">
          We offer several options to allow you to opt out of specific types of
          data processing. These options give you control over how your
          information is used for marketing, analytics, and other purposes:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Marketing Communications</p>
            <ul className="list-disc">
              <li>
                Opting Out of Emails: If you no longer wish to receive marketing
                emails from us, you can opt out at any time by clicking the
                "unsubscribe" link at the bottom of our emails or by adjusting
                your preferences in your account settings.
              </li>
              <li>
                Opting Out of Other Communications: For other types of marketing
                communications, such as SMS or postal mail, you can opt out by
                contacting us at 35 Adebisi St, Yaba, Lagos and specifying your
                preferences.
              </li>
              <li>
                Processing Time: We aim to process opt-out requests as quickly
                as possible, but please allow up to 10 business days for email
                opt-outs to take effect and up to 30 days for other types of
                communications.
              </li>
            </ul>
          </li>
          <li>
            <p>Targeted Advertising</p>
            <ul className="list-disc">
              <li>
                Managing Preferences: You can manage your preferences for
                targeted advertising through your account settings or by using
                industry opt-out tools such as the Network Advertising
                Initiative [NAI] or the Digital Advertising Alliance [DAA]
                opt-out mechanisms.
              </li>
              <li>
                Cookies and Tracking Technologies: You can control the use of
                cookies and similar technologies through your browser settings.
                Most browsers allow you to block or delete cookies, and you can
                also use browser extensions to manage tracking preferences.
              </li>
              <li>
                Mobile Advertising: For targeted advertising on mobile devices,
                you can adjust your preferences through your device settings.
                Look for options related to ad tracking or personalized
                advertising.
              </li>
            </ul>
          </li>
          <li>
            <p>Analytics and Research</p>
            <ul className="list-disc">
              <li>
                Opting Out of Analytics: If you prefer not to have your data
                used for analytics or research purposes, you can opt out by
                adjusting your preferences in your account settings or by
                contacting us at 35 Adebisi St, Yaba, Lagos.
              </li>
              <li>
                Impact on Services: Please note that opting out of analytics may
                affect the functionality and performance of our services, as we
                use this data to improve user experience and service quality.
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Data Deletion
        </h2>
        <p className="text-dark_color text-[16px]">
          You have the right to request the deletion of your personal
          information, also known as the "right tobe forgotten." This right
          ensures that you can remove your data from our systems under
          certainconditions. Here's how you can exercise this right:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Conditions for Deletion</p>
            <ul className="list-disc">
              <li>
                When You Can Request Deletion: You can request deletion of your
                data if it is no longer necessary for the purposes for which it
                was collected, if you withdraw your consent, if you object to
                the processing and there are no overriding legitimate grounds,
                if the data has been unlawfully processed, or if we are required
                to delete it to comply with a legal obligation.
              </li>
              <li>
                Exceptions: There are certain exceptions where we may not be
                able to fulfill a deletion request. These include situations
                where we need to retain the data to comply with a legal
                obligation, for the establishment, exercise, or defense of legal
                claims, or for certain public interest reasons.
              </li>
            </ul>
          </li>
          <li>
            <p>How to Request Deletion</p>
            <ul className="list-disc">
              <li>
                Submitting a Request: To request the deletion of your data,
                please contact us at 35 Adebisi St, Yaba, Lagos. You will need
                to provide specific details about the data you wish to delete
                and the reasons for your request.
              </li>
              <li>
                Verification Process: We may require proof of identity and
                additional information to verify the authenticity of the
                deletion request and to ensure that we delete the correct data.
              </li>
              <li>
                Response Time: We aim to respond to deletion requests within one
                month. If the request is complex or involves a significant
                amount of data, we may extend this period by up to two
                additional months, but we will inform you of any delay and the
                reasons for it.
              </li>
            </ul>
          </li>
          <li>
            <p>Data Deletion Process</p>
            <ul className="list-disc">
              <li>
                Permanent Deletion: When we delete your data, we ensure that it
                is permanently removed from our active systems and databases. We
                also notify any third parties with whom we have shared the data
                of the deletion request.
              </li>
              <li>
                Backup Systems: Data may continue to exist in our backup systems
                for a limited period due to our data retention policies, but it
                will not be used for any further processing and will be
                permanently deleted according to our backup schedule.
              </li>
              <li>
                Confirmation of Deletion: We will provide you with confirmation
                once your data has been deleted. If we are unable to delete
                certain data, we will explain the reasons and any steps we have
                taken to address your concerns.
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Your Rights Under NDPR
        </h2>
        <p className="text-dark_color text-[16px]">
          In addition to the specific rights mentioned above, the NDPR provides
          you with several other rights regarding your personal information:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            Right to Object: You have the right to object to the processing of
            your data for specific purposes, such as direct marketing,
            profiling, or research.
          </li>
          <li>
            Right to Restrict Processing: You can request that we restrict the
            processing of your data under certain conditions, such as when you
            contest the accuracy of the data or when the processing is unlawful,
            but you do not want the data deleted.
          </li>
          <li>
            Right to Data Portability: You have the right to request that we
            transfer your data to another organization or directly to you in a
            structured, commonly used, and machine-readable format.
          </li>
        </ol>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Exercising Your Rights
        </h2>
        <p className="text-dark_color text-[16px]">
          To exercise any of your rights under the NDPR, please contact us at 35
          Adebisi St, Yaba, Lagos. We are committed to responding to your
          requests promptly and ensuring that your rights are respected and
          upheld.
        </p>
      </div>
    </div>
  );
}
