import React from "react";

export default function Purpose() {
  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <h1 className="font-bold text-[28px] text-dark_color">
          Purpose of the Policy
        </h1>
        <p className="text-dark_color text-[16px]">
          In today's digital age, the protection of personal data has become
          increasingly important, particularly in the field of cyber security.
          As a company dedicated to protecting the online presence and data of
          our clients, we understand the critical need for robust privacy
          measures. This Privacy Policy serves multiple key purposes:
        </p>
        <ol className="list-decimal flex flex-col gap-[20px]">
          <li className="">
            <p className="text-dark_color text-[16px]">
              <span className="font-bold text-dark_color">
                Transparency and Trust
              </span>
              :We believe in maintaining transparency with our clients regarding
              the data we collect and how we use it. This policy aims to provide
              a clear and comprehensive explanation of our data handling
              practices, ensuring that you are fully informed and can trust us
              with your sensitive information.
            </p>
          </li>
          <li className="">
            <p className="text-dark_color text-[16px]">
              <span className="font-bold text-dark_color">
                Legal Compliance:
              </span>
              Our operations comply with all relevant data protection laws and
              regulations, including the General Data Protection Regulation
              [GDPR], the California Consumer Privacy Act [CCPA],Nigeria Data
              Protection Regulation [NDPR] and other applicable privacy laws.
              This policy outlines our adherence to these regulations, ensuring
              that your rights and freedoms are respected and protected.
            </p>
          </li>
          <li>
            <p className="text-dark_color text-[16px]">
              <span className="font-bold text-dark_color">Data Security:</span>
              As a cyber security company, we prioritize the security of the
              information we collect. This policy details the security measures
              we implement to protect your data from unauthorized access,
              disclosure, alteration, and destruction. Our commitment to data
              security extends to all aspects of our business, from our online
              services to our internal processes.
            </p>
          </li>
          <li className="">
            <p className="text-dark_color text-[16px]">
              <span className="font-bold text-dark_color">
                Data Minimization:
              </span>
              We collect only the data that is necessary for the provision of
              our services and the improvement of our offerings. This policy
              explains the types of data we collect, why we collect it, and how
              it is used. By limiting the scope of data collection, we minimize
              the risk of misuse and enhance the protection of your privacy.
            </p>
          </li>
          <li className="">
            <p className="text-dark_color text-[16px]">
              <span className="font-bold text-dark_color">
                User Rights and Control:
              </span>
              We recognize your rights to access, update, delete, and control
              your personal information. This policy outlines your rights and
              how you can exercise them. We provide clear instructions on how to
              manage your data preferences and ensure that you have control over
              your personal information at all times.
            </p>
          </li>
          <li className="">
            <p className="text-dark_color text-[16px]">
              <span className="font-bold text-dark_color">
                Improvement of Services:
              </span>
              Understanding how our clients interact with our services helps us
              to improve and tailor our offerings to better meet your needs.
              This policy explains how we use data analytics to enhance our
              services while ensuring that your privacy is maintained.
            </p>
          </li>
        </ol>
        <p className="text-dark_color text-[16px]">
          At Vegeel Technology Limited, your privacy is our priority. We are
          dedicated to maintaining the confidentiality, integrity, and
          availability of your data. This Privacy Policy reflects our commitment
          to protecting your information and providing you with a secure and
          transparent service experience. If you have any questions or concerns
          about this policy, or about how your data is handled, please do not
          hesitate to contact us at{" "}
          <span className="text-blue-500">hello@vegeel.com.</span> Your trust is
          of utmost importance to us, and we are here to address any issues you
          may have regarding your privacy and data security.
        </p>
      </div>
    </div>
  );
}
