import React from "react";
interface LightBLueCardProps {
  icon: string;
  header: string;
  subHead: string;
  header2?: string;
  header3?: string;
  Lcheader: string;
  Lcheader2?: string;
  height: string;
}

export const LightBLueCard: React.FC<LightBLueCardProps> = ({
  icon,
  header,
  subHead,
  header2,
  Lcheader,
  Lcheader2,
  height,
}) => {
  return (
    <div
      className={`p-[1.25rem] bg-[#D4D4D426] light-card-border rounded-[0.625rem] flex flex-col gap-[1rem] lg:gap-[24px] lg:p-[30px] lg:w-[340px] lg:h-[${height}px]`}
    >
      <div className="h-[2.75rem] w-[2.75rem] lg:w-[56px] lg:h-[56px] bg-white flex justify-center items-center rounded-[0.625rem] lg:p-[10px]">
        <img src={icon} alt=" file icon" className=" lg:w-[100%]" />
      </div>

      <div className="flex flex-col gap-[0.5rem]  lg:justify-between lg:gap-[12px] ">
        <div>
          <p className="lg:hidden font-[700] text-[1.25rem] text-white">
            {header}
          </p>
          <p className="lg:hidden font-[700] text-[1.25rem] text-white">
            {header2}
          </p>
          <p className="hidden lg:block font-[700] text-[1.5rem] text-white">
            {Lcheader}
          </p>
          <p className="hidden lg:block font-[700] text-[1.5rem] text-white">
            {Lcheader2}
          </p>
        </div>
        <div>
          <p className="font-[400] text-[1rem] text-white leading-[1.75rem] lg:text-[18px] lg:leading-[30px] ">
            {subHead}
          </p>
        </div>
      </div>
    </div>
  );
};
