import React from "react";
import { FaqItem } from "../Product";

const Cost = () => {
  const faqData = [
    {
      question: "Does Vegeel offer a free trial or demo of the software?",
      answer:
        "Yes, Vegeel offers a free demo of the software so you can experience its features and capabilities firsthand. To request a demo, please visit our website at www.vegeel.com/request a demo or contact our sales team.",
    },
    {
      question: "Are there any hidden fees associated with Vegeel's software?",
      answer:
        "No, there are no hidden fees associated with Vegeel's software. Our pricing is transparent, and we will provide a detailed breakdown of all costs involved during the purchasing process. Any additional services or features will be clearly outlined in your plan.",
    },
    {
      question: "Are there additional costs for updates and new features?",
      answer:
        "Updates and new features are typically included in your subscription plan at no additional cost. Vegeel is committed to continuously improving our software, and we ensure that our users have access to the latest enhancements and security updates.",
    },
    {
      question: " Can we customize our plan to fit our budget and needs?",
      answer:
        "Yes, Vegeel offers customizable plans to fit your budget and specific requirements. Our sales team will work with you to create a tailored solution that meets your compliance needs without exceeding your budget.",
    },
  ];

  return (
    <div className="pt-[32px] px-[15px] flex justify-center items-center flex-col w-full">
      {faqData.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default Cost;
