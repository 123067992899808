import React from "react";
import { size, variant } from "../../types";
import btnIcon from "../../assets/icons/upright.svg";
import btnIconRight from "../../assets/icons/arrow-right-plain.svg";
import arrowRightBlue from "../../assets/icons/arrow-right-blue.svg";
import arrowRightWhite from "../../assets/icons/arrow-right-white.svg";

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: variant;
  icon: boolean;
  iconType?: "exit" | "arrow" | "arrowWhite";
  size?: size;
}

export default function Button({
  size = "medium",
  variant = "contained",
  icon = false,
  iconType,
  children,
  disabled,
  ...props
}: ButtonProps) {
  const getBackgroundClass = () => {
    switch (variant) {
      case "contained":
        return "bg-main_color text-white";
      case "outlined":
        return "border border-main_color text-main_color";
      case "plain":
        return "bg-white text-main_color";
      default:
        return;
    }
  };

  const getButtonSize = () => {
    switch (size) {
      case "small":
        return "px-4";
      case "medium":
        return "w-6/12";
      case "large":
        return "w-full";

      default:
        return;
    }
  };

  return (
    <button
      className={`${getBackgroundClass()} ${getButtonSize()} font-medium h-[48px] flex justify-center items-center gap-2 rounded-md`}
      {...props}
    >
      <span className="font-medium">{children}</span>
      {icon && iconType === "exit" && (
        <img src={variant === "plain" ? btnIcon : btnIcon} alt="" />
      )}
      {icon && iconType === "arrow" && <img src={btnIconRight} alt="" />}
      {icon && iconType === "arrowWhite" && (
        <img src={arrowRightWhite} alt="" />
      )}
    </button>
  );
}
