import React from "react";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: any;
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationProps) => {
  const pageNumbers = [];

  pageNumbers.push(1);

  if (currentPage > 3) {
    pageNumbers.push("...");
  }

  if (currentPage > 2) {
    pageNumbers.push(currentPage - 1);
  }

  if (currentPage !== 1 && currentPage !== totalPages) {
    pageNumbers.push(currentPage);
  }

  if (currentPage < totalPages - 1) {
    pageNumbers.push(currentPage + 1);
  }

  if (currentPage < totalPages - 2) {
    pageNumbers.push("...");
  }

  if (totalPages > 1) {
    pageNumbers.push(totalPages);
  }

  return (
    <div className="flex justify-center items-center space-x-6">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="w-[38px] h-[38px] border border-[#D7DFE9] rounded-[6px] text-[#455468] text-[1.3rem] disabled:opacity-50"
      >
        &lt;
      </button>
      {pageNumbers.map((number, index) => (
        <button
          key={index}
          onClick={() => number !== "..." && onPageChange(number)}
          className={`text-[1rem]  ${
            currentPage === number
              ? "bg-[#E5EAFC] w-[28px] h-[28px] rounded-[6px] text-[1rem] text-[#1B4DFF]"
              : "text-[#98A2B3]"
          }`}
          disabled={number === "..."}
        >
          {number}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="w-[38px] h-[38px] border border-[#D7DFE9] rounded-[6px] text-[#455468] text-[1.3rem] disabled:opacity-50"
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
