import React, { useEffect, useState } from "react";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiesPolicy from "./CookiesPolicy";
import { LegalTabs } from "../../components/LegalTab";
import legalIcon from "../../assets/icons/legal-icon.svg";
import { useMenuContext } from "../../context";

export default function Legal() {
  const [tab, setTab] = useState<number>(1);
  const { closeDropdown } = useMenuContext();

  return (
    <div onClick={() => closeDropdown()}>
      <Hero />
      <section className="py-[48px] lg:flex lg:justify-center lg:items-center lg:flex-col px-[18px]">
        <LegalTabs tab={tab} switchTab={setTab} />
        {tab === 1 ? (
          <TermsOfService />
        ) : tab === 2 ? (
          <PrivacyPolicy />
        ) : (
          <CookiesPolicy />
        )}
      </section>
    </div>
  );
}

function Hero() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-[#000033] pt-[3rem] lg:pt-[8rem] pb-[48px] flex justify-center items-center flex-col">
      <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[129px] h-[40px] lg:h-[48px] rounded-full">
        <span className="pr-2">
          <img src={legalIcon} alt="icon" />
        </span>
        <h3 className="text-[0.75rem] text-[#93AAFD]">Legal</h3>
      </div>

      <div className="px-5 text-center text-[#FFFFFF] pt-4 flex justify-center items-center flex-col">
        <h1 className="text-[3rem] lg:text-[5rem] font-medium leading-[56px] lg:leading-[82px] px-3 lg:tracking-tight">
          Legal
        </h1>

        <h2 className="text-[1rem] lg:text-[1.25rem] leading-[28px] pt-4 lg:pt-[20px]  lg:pb-5">
          Read through our legal documentations, terms of service,{" "}
          <br className="hidden lg:flex" /> privacy policy and cookie policy.
        </h2>
      </div>
    </div>
  );
}
