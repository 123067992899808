import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/icons/blog-detail-arrow-left.svg";
import avatarOne, {
  ReactComponent as Avatar,
} from "../../assets/icons/blog-detail-avatar.svg";
import copyLinkIcon from "../../assets/icons/blog-detail-desktop-link-angled.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/blog-detail-link-icon.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/blog-detail-linkedin-icon.svg";
import { ReactComponent as Twitter } from "../../assets/icons/blog-detail-x-icon.svg";
import arrowUpRight from "../../assets/icons/blog-details-up-right.svg";
import { ReactComponent as Times } from "../../assets/icons/times.svg";

import blogMainImg from "../../assets/images/hidden_compliance_cost.jpg";

import CustomIcon from "../../components/CustomIcon";
import { useMenuContext } from "../../context";
import { BlogPost } from "../Blog/All";

export default function BlogDetailFive() {
  const navigate = useNavigate();
  const { closeDropdown } = useMenuContext();
  const linkValue = "https://www.vegeel.com/";
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = () => {
    navigate(-1);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(linkValue);
      setShowCopyMessage(true);
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div onClick={() => closeDropdown()}>
      <div className=" pt-[2rem] lg:pt-[6rem] px-[18px] lg:px-[5rem] flex justify-start lg:justify-center items-start lg:items-start flex-col">
        <div
          onClick={handleNavigate}
          className="gap-2 text-[1rem] text-[#060B59] flex justify-center items-center font-medium hero-blog-detail-go-back-border py-[8px] px-[13px] lg:px-[16px] rounded-[6px] cursor-pointer"
        >
          <span>
            <img src={arrowLeft} alt="icon" />
          </span>
          Back to blog
        </div>
        <div className="pt-[32px] lg:px-[3.5rem] ">
          <h1 className="text-[#060B59] text-[2.375rem] lg:text-[3.75rem] font-bold pb-[16px] lg:pb-[24px] leading-[60px]">
            The Hidden Cost of Compliance: How Manual Processes Are Bleeding
            Your Business Dry
          </h1>
          <div className="lg:hidden">
            <div className="pt-[16px] flex justify-start items-center gap-2">
              <CustomIcon SvgIcon={Avatar} size={28} />
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <div className="flex justify-between items-center pt-[16px]">
              <h1 className="text-[#455468] text-[1rem]">
                Monday, August 12, 2024
              </h1>
              <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
                Framework
              </h2>
            </div>
          </div>
          <div className="hidden lg:flex lg:justify-start lg:items-center lg:gap-3">
            <div className="flex justify-start items-center gap-2">
              <div className="h-[32px] w-[32px]">
                <img src={avatarOne} alt="avater" className="w-full h-full" />
              </div>
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>
            <h1 className="text-[#455468] text-[1rem]">
              Monday, August 12, 2024
            </h1>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>

            <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
              Framework
            </h2>
          </div>
        </div>
        <div className="pt-[16px] w-full lg:px-[3.5rem]">
          <img
            src={blogMainImg}
            alt="blog-main"
            className="rounded-[15px] w-full h-full"
          />
        </div>

        <div className="lg:py-[48px] lg:flex lg:justify-between lg:items-start lg:px-[3.5rem]">
          <div className="lg:w-[28%]">
            <div className="pt-[32px] ">
              <h1 className="text-[#060B59] font-bold text-[1.125rem] lg:text-[1.25rem] pb-[8px] lg:pb-[16px]">
                Contents
              </h1>

              <div className="flex">
                <div className="relative">
                  <div className="absolute bg-[#E5EAFC] w-1 h-full rounded-[6px]">
                    <div
                      className={`absolute left-0 bg-blue-500 w-1 h-[50%] rounded-[6px]`}
                    ></div>
                  </div>
                </div>

                <div className="pl-[12px]">
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium  mb-[8px]  lg:w-full">
                    How Manual Processes Are Bleeding Your Business Dry
                  </h1>
                  {/* <h1 className="text-[#1B4DFF] text-[1rem] font-medium">
                    5 steps to help unify your approach to GRC
                  </h1> */}
                </div>
              </div>
            </div>

            <div className="hidden lg:flex lg:pt-[64px] lg:flex-col lg:justify-start lg:items-start">
              <h1 className="text-[#060B59] text-[1.25rem] pb-[1rem]">
                Share article
              </h1>

              <h1 className="text-[#1B4DFF] text-[1rem] font-medium flex justify-start items-center gap-2 cursor-pointer">
                LinkedIn{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1 className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start items-center gap-2 cursor-pointer">
                Twitter{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1
                onClick={copyLink}
                className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start  items-center gap-2 cursor-pointer"
              >
                Copy link{" "}
                <span>
                  <img src={copyLinkIcon} alt="icon" />
                </span>
              </h1>
            </div>
          </div>

          <div className="pt-[32px] lg:px-[rem] lg:w-[68%]">
            <h1 className="text-[#111111] text-[2rem] lg:text-[2.25rem] font-bold leading-[36px] lg:leading-[50px] pb-[12px] lg:pb-[20px]">
              The Hidden Cost of Compliance: How Manual Processes Are Bleeding
              Your Business Dry
            </h1>
            <p className="text-[#111111] text-[1rem] lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide">
              Are you still relying on manual processes for compliance? You're
              not alone, but here's the harsh truth you most probably don't want
              to hear.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              Manual compliance processes are quietly draining your resources,
              stifling growth, and putting your business at risk.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              Here comes the big question, How does Manual Compliance Process
              cost your business?
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              1. Time-Consuming: Manual processes devour hours, days, and weeks
              of valuable employee time, taking away from core business
              activities.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              2. Error-Prone: Human error leads to costly mistakes, fines, and
              reputational damage.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              3. Inefficiency: Manual processes hinder scalability, making it
              difficult to adapt to changing regulations and business needs.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              4. Lack of Visibility: No real-time insights, making it
              challenging to identify and mitigate risks.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              The Consequences that comes with this cost includes:
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] py-2 tracking-wide">
              1. Financial Losses: Fines, penalties, and legal fees eat into
              your bottom line.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  pb-2  tracking-wide">
              2. Reputational Damage: Non-compliance erodes customer trust and
              loyalty.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  pb-2  tracking-wide">
              3. Missed Opportunities: Inefficient processes hinder innovation
              and growth.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              Do you want to know how you can break Free from Manual Compliance
              and save your business from bleeding? It's very simple Automate
              your Cybersecurity Compliance process.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-3 tracking-wide">
              Automate compliance processes with Vegeel AI-POWERED Software
              streamlining workflows, and ensuring accuracy, efficiency, and
              scalability. Gain real-time insights, reduce risk, and focus on
              growth.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] lg:pt-[20px] tracking-wide">
              Here's how you can take the First Step today
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] lg:pt-[20px] tracking-wide">
              Schedule a demo today and start saving time, money, and resources:
              <a
                className="text-blue-600"
                target="_blank"
                rel="noreferrer"
                href="https://tidycal.com/36kgx91/vegeel-demo"
              >
                Vegeel demo
              </a>
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              For further inquiries, contact:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="mailto:hello@vegeel.com"
              >
                hello@vegeel.com
              </a>{" "}
              <br />
              Follow Us <br />
              Twitter:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://x.com/Vegeel_Tech"
              >
                Vegeel_Tech
              </a>{" "}
              <br />
              Linkedin:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.linkedin.com/company/vegeel/"
              >
                Vegeel
              </a>{" "}
              <br />
              Instagram:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.instagram.com/vegeelcompliance"
              >
                Vegeelcompliance
              </a>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="py-[48px] flex justify-center items-center gap-[48px] w-full lg:hidden">
          <h1 className="text-[1.125rem] text-[#060B59] font-medium">
            Share article
          </h1>
          <div className="flex justify-start items-center gap-[24px]">
            <CustomIcon SvgIcon={Twitter} size={24} />
            <CustomIcon SvgIcon={Linkedin} size={24} />
            <CustomIcon SvgIcon={LinkIcon} size={24} />
          </div>
        </div>
      </div>
      <div className="py-[48px] px-[18px] bg-[#EDF1FF] flex justify-center items-center lg:items-start flex-col lg:px-[8.5rem]">
        <h1 className="pb-[32px] text-[#060B59] font-bold text-[1.75rem]">
          Related resources
        </h1>
        <div className="lg:flex lg:justify-center lg:items-start lg:flex-col">
          <BlogPost
            blogImg={
              "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750,g_auto/v1723188350/Ultimate_guide_Vegeel_rdlgj1.jpg"
            }
            blogHeader="The Ultimate Guide to Automating Cybersecurity Compliance"
            blogDate="Thursday, August 09, 2024"
            blogCategory="Framework"
            blogDetail="Stay Secure, Save Time and resources!!!! In today's digital landscape, cybersecurity compliance is no longer a nice-to-have, but a must-have. Yet, managing compliance manually can be quite stressful, prone to errors and inefficiencies. That's where automation comes in, a game changer for businesses seeking to fortify their defenses while freeing up resources."
            blogDesktopPaddingTop="50"
            navigateToBlogPost="blog-details/03"
          />
        </div>
      </div>
      {showCopyMessage && (
        <div className="h-[10vh] w-[100vw] fixed top-0 bg-green-500 z-20 flex justify-between items-center p-[20px] px-[40px]">
          <h1 className="text-white font-medium text-[1rem]">
            ✔ Copied to clipboard
          </h1>
          <div onClick={() => setShowCopyMessage(false)}>
            <CustomIcon SvgIcon={Times} size={24} />
          </div>
        </div>
      )}
    </div>
  );
}
