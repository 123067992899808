import React, { useEffect, useRef } from "react";
import img from "../../assets/icons/shield-check-icon.svg";
import Button from "../../components/Button";
import Commitment from "../../components/commitment";
import img1 from "../../assets/icons/secure.svg";
import img2 from "../../assets/icons/key.svg";
import img3 from "../../assets/icons/lock.svg";
import img4 from "../../assets/icons/file-lock.svg";
import img5 from "../../assets/icons/shield.svg";
import img6 from "../../assets/icons/plug.svg";
import img7 from "../../assets/icons/mail-05.svg";
import img8 from "../../assets/icons/lightbulb-02.svg";
import img9 from "../../assets/icons/key-01.svg";
import img10 from "../../assets/icons/data.svg";
import img11 from "../../assets/icons/qrcode.svg";
import img12 from "../../assets/icons/computer.svg";
import img13 from "../../assets/icons/internet.svg";
import img14 from "../../assets/icons/alert-triangle.svg";
import img15 from "../../assets/icons/users-profiles-02.svg";
import img16 from "../../assets/icons/shield-check.svg";
import homeRocketIcon from "../../assets/icons/home-rocket-icon.svg";
import homeDashboardImg from "../../assets/images/vegeel-dashboard-home-img.svg";
import homeDashboardImgTwo from "../../assets/images/desktop-home-img.svg";
import DataCard from "../../components/DataCard";
import { LightBLueCard } from "../../components/solution-components/Light-Blue-Card";
import { useMenuContext, useModalContext } from "../../context";

export default function Security() {
  const { closeDropdown } = useMenuContext();
  const { openBookADemoModal, openContactSalesModal } = useModalContext();
  const SecuritySectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const scrollToSecuritySection = () => {
    if (SecuritySectionRef.current) {
      SecuritySectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div onClick={() => closeDropdown()}>
      <div className="bg-[#000033] py-[3rem] lg:py-[8rem] flex justify-center items-center flex-col">
        <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] py-[12px] pl-[30px] pr-[35px] rounded-full">
          <span className="pr-2">
            <img src={img} alt="light-bulb" />
          </span>
          <h3 className="text-[0.75rem] text-[#93AAFD]">
            Security and compliance
          </h3>
        </div>

        <div className="px-5 text-center text-[#FFFFFF] pt-4 flex justify-center items-center flex-col">
          <h1 className="text-[3rem] lg:text-[5rem] font-medium leading-[56px] lg:leading-[82px] px-3 lg:tracking-tight">
            Well{" "}
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent font-bold">
              fortified
            </span>{" "}
            and <br className="hidden lg:flex" />
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent font-bold">
              Secured
            </span>{" "}
            solution.
          </h1>

          <h2 className="text-[1rem] lg:text-[1.25rem] leading-[28px] pt-4 lg:pt-[20px]  lg:pb-5">
            Security is a paramount concern at Vegeel, and safeguarding your
            data is at the core <br className="hidden lg:flex" />
            of our mission. We are dedicated to ensuring the confidentiality,
            integrity, and <br className="hidden lg:flex" /> availability of
            your valuable information.
          </h2>

          <div className="flex justify-center items-center px-[34px] pt-4 lg:pt-0 w-full lg:w-[30%]">
            <Button
              icon={true}
              onClick={scrollToSecuritySection}
              variant="contained"
              children="Learn more"
              size="large"
              iconType="arrowWhite"
            />
          </div>
        </div>
      </div>

      <section ref={SecuritySectionRef} className="">
        <div className="flex flex-col justify-center items-center pt-[64px]">
          <h1 className="font-[700] text-[28px] lg:text-[48px] lg:leading-[64px] leading-[36px] text-[#060B59]">
            Vegeel’s commitment
          </h1>
          <p className="font-[400] text-[16px] lg:text-[20px] leading-[28px] lg:leading-[34px] pt-[12px] text-dark_color text-center">
            At Vegeel, we are committed to safeguarding the confidentiality,
            integrity, and availability <br className="hidden lg:flex" /> of
            your critical information.
          </p>
        </div>

        <div className="">
          <div className="flex flex-col lg:flex-row lg:flex-wrap gap-[32px] justify-center items-center py-[32px] lg:gap-[48px]">
            <Commitment
              icon={img1}
              text="We employ robust encryption protocols to protect all traffic, including application, database, webhook, and API communications, ensuring that your data remains confidential during transit."
              title="Secure infrastructure"
            />
            <Commitment
              icon={img2}
              text="All data stored within Vegeel's systems is encrypted at the disk level, with additional encryption applied to sensitive information stored within databases, guaranteeing that your data is secure at rest."
              title="Encrypted data storage"
            />
            <Commitment
              icon={img4}
              title="Limited data handling"
              text="Only essential data required for service operation is processed and retained, and all data is promptly deleted upon cancellation of service, minimizing any potential exposure."
            />
            <Commitment
              icon={img3}
              text="Our employees undergo rigorous training and background checks, and access to customer data is strictly limited to authorized personnel within our Engineering and  Operations teams."
              title="Stringent Access Controls"
            />
          </div>
          <div className="flex justify-center items-center w-full pb-[64px]">
            <Commitment
              icon={img5}
              title="Regular security assessment"
              text="Vegeel undergoes annual security assessments and penetration testing conducted by reputable third-party firms to identify and address any vulnerabilities, ensuring that our systems remain resilient against evolving threats"
            />
          </div>
        </div>
      </section>

      <section className="flex lg:justify-center lg:items-center flex-col gap-[2rem] py-[3rem] pb-16 px-4 bg-[#EDF1FF]">
        <div className="bg-[#1B4DFF] rounded-[0.938rem] py-[1.875rem] lg:pt-[64px] px-[1.25rem] lg:px-[84px] flex justify-center items-center flex-col w-[97%] lg:w-[95%] lg:rounded-[1.875rem]">
          <div className="flex justify-center items-center">
            <div className="py-[10px] px-[15px] md:px-[30px] rounded-[25px] bg-[#FFFAEB] text-center w-fit ">
              <h1 className="text-[#EA6F03] flex justify-center items-center gap-[12px]">
                <span className="font-[500] text-[12px] md:text-[16px] md:leading-[28px] leading-[20px]">
                  Fully fortified
                </span>
              </h1>
            </div>
          </div>

          <div className="pt-[12px] pb-[32px] lg:pb-[48px]">
            <h1 className="text-[28px] text-center font-[700] text-white lg:text-[48px] leading-[36px] lg:leading-[64px] ">
              Comprehensive security measures
            </h1>
          </div>
          <div className="flex flex-col gap-[2rem] lg:flex-row lg:gap-[45px]">
            <LightBLueCard
              icon={img6}
              Lcheader="Enhanced"
              Lcheader2="Authentication"
              header="Enhanced"
              header2="Authentication"
              subHead="Vegeel implements two-factor authentication (2FA) across its platform, adding an extra layer of security to user accounts and preventing unauthorized access."
              height="434"
            />
            <LightBLueCard
              icon={img7}
              Lcheader="Email Security"
              Lcheader2="Measures"
              header="Email Security"
              header2="Measures"
              subHead="We employ advanced email security measures, including domain-based message authentication and encryption, to safeguard against phishing attacks and ensure the integrity of communication."
              height="434"
            />
            <LightBLueCard
              icon={img8}
              Lcheader="Continuous"
              Lcheader2="Development & Testing"
              header="Continuous"
              header2="Development & Testing"
              subHead="Vegeel follows a rigorous development lifecycle, incorporating continuous delivery practices, continuous integration (CI), and automated testing to identify and mitigate security vulnerabilities promptly"
              height="434"
            />
          </div>
        </div>
      </section>

      <section className="bg-white py-[48px] lg:flex lg:justify-center lg:items-center lg:flex-col">
        <h1 className="text-[28px] lg:text-[48px] lg:leading-[64px] leading-[36px] text-center font-[700] text-[#060b59] block lg:hidden">
          Data privacy and
          <br /> protection
        </h1>
        <h1 className="text-[28px] lg:text-[48px] lg:leading-[64px] leading-[36px] text-center font-[700] text-[#060B59] hidden lg:block">
          Data privacy and protection
        </h1>
        <div className="pt-[32px] px-[16px] flex justify-center items-center gap-[24px] flex-wrap lg:gap-[45.5px] lg:w-[95%]">
          <DataCard
            icon={img9}
            title="Encryption protocols"
            text="All data transmitted to and from Vegeel's platform is encrypted using industry-standard TLS protocols, ensuring the confidentiality of your information."
          />
          <DataCard
            icon={img10}
            title="Data retention and removal"
            text="Vegeel retains event data for a limited period, removing individual events and webhooks after 90 days. Upon termination of service, all customer data is promptly erased from our servers."
          />
          <DataCard
            icon={img11}
            title="Vulnerability disclosure"
            text="We welcome and encourage the reporting of any security vulnerabilities or concerns. Our dedicated security team promptly investigates and addresses reported issues to ensure the ongoing security of our platform."
          />
        </div>
      </section>

      <section className="bg-white py-[48px]">
        <h1 className="text-[28px] lg:text-[48px] lg:leading-[64px] leading-[36px] text-center font-[700] text-[#060B59]">
          Infrastructure security
        </h1>
        <div className="pb-[40px] pt-[64px] px-[16px] flex justify-center items-center gap-[24px] flex-wrap lg:gap-[45.5px]">
          <Commitment
            icon={img12}
            title="Physical and logical access control"
            text="Vegeel's infrastructure is hosted on secure cloud platforms like AWS, with access strictly controlled and monitored."
          />
          <Commitment
            icon={img13}
            title="Disaster recovery and business continuity"
            text="We maintain redundant server configurations and encrypted backups to ensure high availability and rapid recovery in the event of unforeseen incidents or disasters"
          />
        </div>
      </section>

      <section className="bg-white pt-[48px]">
        <h1 className="text-[28px] lg:text-[48px] lg:leading-[64px] leading-[36px] text-center font-[700] text-[#060B59] block md:hidden">
          Corporate security <br /> practice
        </h1>
        <h1 className="text-[28px] lg:text-[48px] lg:leading-[64px] leading-[36px] text-center font-[700] text-[#060B59] hidden md:block">
          Corporate security practice
        </h1>
        <div className="pb-[40px] lg:pb-0 pt-[64px] px-[16px] flex justify-center items-center gap-[24px] flex-wrap lg:gap-[45.5px]">
          <div className="w-[343px] rounded-[10px] p-[20px] md:h-[434px]">
            <div className="w-[44px] h-[44px] rounded-[10px] bg-main_color p-[10px]">
              <img src={img14} alt="veegeel icon" title="veegeel icon" />
            </div>
            <div className="pt-[16px] md:pt-[24px] md:pb-[qwpx] pb-[8px]">
              <h1 className="font-[500] text-[20px] md:text-[24px] md:leading-[36px] leading-[34px] text-[#060B59]">
                Risk management
              </h1>
            </div>
            <div className="text-[16px] md:text-[18px] md:leading-[30px] font-[400] leading-[28px] text-[#455468]">
              <h1>
                Vegeel employs stringent risk management practices throughout
                its operations, including regular risk assessments and
                contingency planning to mitigate potential threats effectively.
              </h1>
            </div>
          </div>

          <div className="w-[343px] rounded-[10px] p-[20px]  md:h-[434px]">
            <div className="w-[44px] h-[44px] rounded-[10px] bg-main_color p-[10px]">
              <img src={img15} alt="veegeel icon" title="veegeel icon" />
            </div>
            <div className="pt-[16px] md:pt-[24px] md:pb-[qwpx] pb-[8px]">
              <h1 className="font-[500] text-[20px] md:text-[24px] md:leading-[36px] leading-[34px] text-[#060B59]">
                Employee training and background checks
              </h1>
            </div>
            <div className="text-[16px] md:text-[18px] md:leading-[30px] font-[400] leading-[28px] text-[#455468]">
              <h1>
                All Vegeel employees undergo thorough background checks and
                receive comprehensive security training to uphold the highest
                standards of data protection and confidentiality
              </h1>
            </div>
          </div>

          <div className="w-[343px] rounded-[10px] p-[20px]  md:h-[434px]">
            <div className="w-[44px] h-[44px] rounded-[10px] bg-main_color p-[10px]">
              <img src={img16} alt="veegeel icon" title="veegeel icon" />
            </div>
            <div className="pt-[16px] md:pt-[24px] md:pb-[qwpx] pb-[8px]">
              <h1 className="font-[500] text-[20px] md:text-[24px] md:leading-[36px] leading-[34px] text-[#060B59]">
                Compliance standards
              </h1>
            </div>
            <div className="text-[16px] md:text-[18px] md:leading-[30px] font-[400] leading-[28px] text-[#455468]">
              <h1>
                Vegeel complies with industry standards and regulations,
                including ISO 27001 to ensure the privacy and security of
                customer data.
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="flex justify-center items-center">
        <div className="w-[95%] lg:w-[80%] border-t pb-[48px] pt-[32px]">
          <h1 className="font-[400] text-[20px] leading-[34px] text-center">
            Established in 2023 and headquartered in Delaware, DE, Vegeel is
            committed to providing industry-leading security and compliance
            practices to safeguard your data.
          </h1>
          <br />
          <h1 className="font-[400] text-[20px] leading-[34px] text-center">
            For any questions or concerns regarding security, please contact us{" "}
            <span className="text-main_color">security@vegeel.com</span>
          </h1>
        </div>
      </section>

      <section className="pt-[48px] bg-[#040B4E]">
        <div className="flex justify-center items-center flex-col">
          <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[300px] lg:370px h-[44px] lg:h-[48px] rounded-full py-[12px] px-[15px]">
            <span className="pr-2">
              <img src={homeRocketIcon} alt="rocket" />
            </span>
            <h3 className="text-[0.75rem] text-[#93AAFD]">
              Begin your journey to compliance efficiency
            </h3>
          </div>
          <h2 className="text-[3rem] lg:text-[3.5rem] font-bold text-[#FFFFFF] text-center px-5 leading-[56px] lg:leading-[64px] pt-3">
            Elevate your <br className="lg:hidden" />
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              security
            </span>{" "}
            and <br />
            <span className="gradient-text bg-gradient-to-l-custom bg-clip-text text-transparent">
              compliance
            </span>{" "}
            posture with <br className="lg:hidden" /> Vegeel
          </h2>
          <p className="text-[#FAFAFA] text-[1rem] leading-[28px] text-center pt-4">
            Join the ranks of forward-thinking <br className="lg:hidden" />{" "}
            organizations that trust Vegeel to fortify <br /> their security and
            compliance efforts.
          </p>
          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 px-7 w-full lg:w-[40%]">
            <Button
              onClick={openBookADemoModal}
              variant="contained"
              size="large"
              icon
            >
              Request demo
            </Button>
            <Button
              onClick={openContactSalesModal}
              variant="plain"
              size="large"
              icon
              iconType="arrow"
            >
              Contact sales
            </Button>
          </div>

          <div className="pt-14 w-full h-full lg:hidden">
            <img
              src={homeDashboardImg}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
          <div className="pt-14 w-full h-full hidden lg:flex lg:px-[4.5rem]">
            <img
              src={homeDashboardImgTwo}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
