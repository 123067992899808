import mail from "../../../assets/icons/mail-01.svg";
import twitter from "../../../assets/icons/twitter.svg";
import linkendin from "../../../assets/icons/linkendin.svg";
import facebook from "../../../assets/icons/facebook.svg";
import { Link } from "react-router-dom";
import Button from "../../Button";
import { useModalContext } from "../../../context";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import iso9001 from "../../../assets/images/iso-9001-img.svg";
import goldenIso27001 from "../../../assets/images/golden-iso-27001-img.svg";

interface DirectionProp {
  icon: any;
  styler: string;
}
export const Commitment = ({ icon, styler }: DirectionProp) => {
  return (
    <section className={`flex ${styler} flex-col gap-[1.25rem]`}>
      <div>
        <img src={icon} alt="" />
      </div>
      <div className="font-normal text-[0.875rem] leading-[1.5rem]">
        <p>
          We are committed to helping businesses across Africa build and
          maintain trust with their users, and prospects through our
          cutting-edge security and compliance automation solution.
        </p>
      </div>
      <div className="flex flex-col gap-[0.5rem]">
        <h2 className="font-semibold leading-[1.5rem]">Corporate Offices:</h2>
        <div className="font-normal text-sm leading-[1.5rem]">
          <p>
            <span className="font-medium">United States:</span> 8 The Green Ste
            A Dover, DE 19901
          </p>
          <p>
            <span className="font-medium">Nigeria:</span> 35 Adebisi Str, Yaba,
            Lagos.
          </p>
          <p>
            <span className="font-medium">Email:</span>{" "}
            <a href="test">hello@vegeel.com</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export const Product = () => {
  const { openBookADemoModal } = useModalContext();

  return (
    <div className="font-medium text-sm leading-6 flex flex-col gap-[1rem]">
      <h2 className="text-[#101828] font-medium text-[0.875rem]">Product</h2>
      <ul className="text-[#1B4DFF] text-[1rem] font-medium flex flex-col gap-[16px]">
        <li>
          <Link to={"/about"}>About</Link>
        </li>
        <li className="cursor-pointer" onClick={openBookADemoModal}>
          Demo
        </li>
        {/* <li>Pricing</li> */}
        <li>
          <Link to={"/faqs"}>FAQs</Link>
        </li>
        {/* <li>Sign In</li> */}
      </ul>
    </div>
  );
};

export const Others = () => {
  const { openContactSalesModal } = useModalContext();

  return (
    <div className="font-medium text-sm leading-6 flex flex-col gap-[1rem]">
      <h2 className="text-[#101828] font-medium text-[0.875rem]">Others</h2>
      <ul className="text-[#1B4DFF] text-[1rem] font-medium flex flex-col gap-[16px]">
        <li>
          <Link to={"/blogs"}>Blog</Link>
        </li>
        <li>
          <Link to={"/security"}> Security</Link>
        </li>
        {/* <li>
          <Link to={"/about"}>
            Careers{" "}
            <span className="bg-[#ECFDF3] text-green-800 p-1 ml-1 rounded-md">
              Hiring!
            </span>
          </Link>
        </li> */}
        <li>
          <Link to={"/legal"}>Legal</Link>
        </li>
        <li className="cursor-pointer" onClick={openContactSalesModal}>
          Contact us
        </li>
      </ul>
    </div>
  );
};

interface NewsLetterProps {
  styler: string;
  text: string;
  buttonSize: any;
}

export const NewsLetter: React.FC<NewsLetterProps> = ({
  styler,
  text,
  buttonSize,
}) => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");

  useEffect(() => {
    if (emailError) {
      const timer = setTimeout(() => {
        setEmailError("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [emailError]);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleNewsLetter = async () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://email-transporter.onrender.com/news-letter/send",
        { email }
      );
      if (response.status === 200) {
        setEmail("");
        setEmailError("");
        toast.success("Newsletter subscription successful!🎉🎇");
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error("Network error. Please check your internet connection.");
      } else if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (emailError) setEmailError("");
  };

  return (
    <section className="flex flex-col gap-[0.8rem]">
      <div className="flex flex-col gap-[0.5rem]">
        <h1 className="font-bold text-[#111111] text-[2rem] leading-[1.875rem]">
          Join our newsletter
        </h1>
        <p className="text-[1rem] text-[#455468] leading-[1.875rem]">{text}</p>
      </div>
      <div className={`flex ${styler} gap-[1rem] items-center w-full`}>
        <div className="h-[49px] border rounded-md flex items-center w-full">
          <div className="flex gap-3 h-[24px] w-full px-2">
            <img src={mail} width={20} height={20} alt="mail" />
            <div className="border"></div>
            <input
              placeholder="Enter your email"
              className="w-full outline-none leading-[24px]"
              type="email"
              onChange={handleEmailChange}
              value={email}
            />
          </div>
        </div>
        <div className="w-full lg:w-[20%]">
          {loading ? (
            <Button
              disabled={loading}
              variant="contained"
              size="large"
              icon={false}
            >
              <ScaleLoader
                color={"#ffffff"}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </Button>
          ) : (
            <Button
              onClick={handleNewsLetter}
              size={buttonSize}
              variant={"contained"}
              icon
              children={"Subscribe"}
            />
          )}
        </div>
      </div>
      {emailError && <p className="text-red-500 text-sm mt-2">{emailError}</p>}
      <div className="flex justify-between items-center w-[50%] lg:w-[40%] pt-4">
        <div>
          <img src={iso9001} alt="icon" className="rounded-full" />
        </div>
        <div>
          <img src={goldenIso27001} alt="icon" />
        </div>
      </div>
    </section>
  );
};

// export const NewsLetter = ({ styler, text, buttonSize }:News) => {
//   const [email, setEmail] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [emailError, setEmailError] = useState('');

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const handleNewsLetter = async () => {
//     if (!validateEmail(email)) {
//       setEmailError('Please enter a valid email address.');
//       return;
//     }
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         'https://email-transporter.onrender.com/news-letter/send',
//         { email }
//       );
//       if (response.status === 200) {
//         setEmail('');
//         setEmailError('');
//         toast.success('Newsletter subscription successful!🎉🎇');
//       }
//     } catch (error) {
//       if (error.message === 'Network Error') {
//         toast.error('Network error. Please check your internet connection.');
//       } else if (error.response) {
//         toast.error(error.response.data.message);
//       } else {
//         toast.error('An error occurred. Please try again later.');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//     if (emailError) setEmailError('');
//   };

//   return (
//     <section className="flex flex-col gap-[0.8rem]">
//       <div className="flex flex-col gap-[0.5rem]">
//         <h1 className="font-bold text-[#111111] text-[2rem] leading-[1.875rem]">
//           Join our newsletter
//         </h1>
//         <p className="text-[1rem] text-[#455468] leading-[1.875rem]">{text}</p>
//       </div>
//       <div className={`flex ${styler} gap-[1rem] items-center w-full`}>
//         <div className="h-[49px] border rounded-md flex items-center w-full">
//           <div className="flex gap-3 h-[24px] w-full px-2">
//             <img src={mail} width={20} height={20} alt="mail" />
//             <div className="border"></div>
//             <input
//               placeholder="Enter your email"
//               className="w-full outline-none leading-[24px]"
//               type="email"
//               onChange={handleEmailChange}
//               value={email}
//             />
//           </div>
//         </div>
//         <div className="w-full lg:w-[20%]">
//           {loading ? (
//             <Button
//               disabled={loading}
//               variant="contained"
//               size="large"
//               icon={false}
//             >
//               <ScaleLoader
//                 color={"#ffffff"}
//                 loading={loading}
//                 aria-label="Loading Spinner"
//                 data-testid="loader"
//               />
//             </Button>
//           ) : (
//             <Button
//               onClick={handleNewsLetter}
//               size={buttonSize}
//               variant={"contained"}
//               icon
//               children={"Subscribe"}
//             />
//           )}
//         </div>
//       </div>
//       {emailError && (
//         <p className="text-red-500 text-sm mt-2">{emailError}</p>
//       )}
//       <div className="flex justify-between items-center w-[50%] lg:w-[40%] pt-4">
//         <div>
//           <img src={iso9001} alt="icon" className="rounded-full" />
//         </div>
//         <div>
//           <img src={goldenIso27001} alt="icon" />
//         </div>
//       </div>
//     </section>
//   );
// };

export const Copyright = () => {
  const year = new Date().getFullYear();
  return (
    <section className="pt-6 border-t flex justify-center flex-col items-center lg:items-center lg:w-[87%] ">
      <div className="flex gap-4 flex-col lg:flex-row-reverse lg:justify-between lg:items-center w-full ">
        <div className="flex gap-6 justify-center lg:justify-start lg:items-center">
          <a href="test" target="_blank">
            <img src={twitter} alt="" />
          </a>
          <a href="test" target="_blank">
            <img src={linkendin} alt="" />
          </a>
          <a href="test" target="_blank">
            <img src={facebook} alt="" />
          </a>
        </div>
        <p className="text-base leading-7 text-center">
          © {year} Vegeel. All rights reserved.
        </p>
      </div>
    </section>
  );
};
