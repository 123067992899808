import React from "react";
import {
  Commitment,
  Copyright,
  NewsLetter,
  Others,
  Product,
} from "../FotComponents";
import veggellogo from "../../../assets/images/vegeel-favicon.svg";

export default function MobileFooter() {
  return (
    <footer className="bg-white text-sm flex flex-col gap-[2.125rem] py-[2.125rem] px-[1.25rem] border-t border-[#EAECF0]">
      <Commitment icon={veggellogo} styler="" />
      <NewsLetter buttonSize={"large"} styler="flex-col" text="" />
      <section className="flex justify-between">
        <Product />
        <Others />
      </section>
      <Copyright />
    </footer>
  );
}
