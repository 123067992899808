import React from "react";
import { FaqItem } from "../Product";

const Audit = () => {
  const faqData = [
    {
      question: "How does Vegeel help with the audit process?",
      answer:
        "Vegeel simplifies the audit process by automating evidence collection, monitoring controls in real-time, and generating comprehensive compliance reports. This ensures that all necessary documentation and evidence are readily available for auditors, making the audit process more efficient and less stressful.",
    },
    {
      question: "Can Vegeel generate audit-ready reports?",
      answer:
        "Yes, Vegeel can generate detailed, audit-ready reports that include all necessary evidence, control effectiveness assessments, and documentation of compliance activities. These reports are designed to meet the requirements of various compliance frameworks and facilitate a smooth audit process.",
    },
    {
      question: "How does Vegeel ensure continuous audit readiness?",
      answer:
        "Vegeel ensures continuous audit readiness by conducting automated checks and real-time monitoring of your controls and processes. This proactive approach helps identify and address any compliance gaps promptly, ensuring that your organization is always prepared for audits.",
    },
    {
      question:
        "What types of evidence can Vegeel collect and manage for audits?",
      answer:
        "Vegeel ensures continuous audit readiness by conducting automated checks and real-time monitoring of your controls and processes. This proactive approach helps identify and address any compliance gaps promptly, ensuring that your organization is always prepared for audits.",
    },
    {
      question: "Can Vegeel assist with both internal and external audits?",
      answer:
        "Yes, Vegeel is designed to support both internal and external audits. Our platform provides tools for conducting internal audits to assess compliance status and prepare for external audits by third-party auditors or regulatory bodies.",
    },
    {
      question: "How does Vegeel handle audit findings and corrective actions?",
      answer:
        "Vegeel tracks audit findings and facilitates the implementation of corrective actions. Our platform helps you document identified issues, assign responsibilities, and monitor the progress of remediation efforts to ensure timely resolution and compliance improvement.",
    },
    {
      question:
        "Does Vegeel support multiple compliance frameworks for audit purposes?",
      answer:
        "Yes, Vegeel supports multiple compliance frameworks, such as ISO 27001, PCI-DSS, ISO 20000, ISO 22301, and SOC 2. This allows organizations to use a single platform to manage and audit compliance across various standards, streamlining the audit process.",
    },
    {
      question: "How often should we conduct audits using Vegeel?",
      answer:
        "The frequency of audits depends on your specific compliance requirements and internal policies. Vegeel supports continuous monitoring and periodic audits, allowing you to schedule and conduct audits as needed to maintain compliance and readiness for external assessments.",
    },
  ];

  return (
    <div className="pt-[32px] px-[15px] flex justify-center items-center flex-col w-full">
      {faqData.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default Audit;
