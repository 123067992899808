import { useEffect, useState } from "react";
import MobileFooter from "./Mobile";
import DesktopFooter from "./Desktop";

export default function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile ? <MobileFooter /> : <DesktopFooter />;
}
