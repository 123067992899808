export default function CookiesPolicy() {
  return (
    <article className="text-[#455468] lg:w-[70%] lg:py-[48px]">
      <header className="py-5 flex flex-col gap-3 lg:gap-[20px] items-center">
        <h1 className="lg:text-[60px] text-4xl leading-[3.125rem] tracking-[-1%] text-[#060B59] font-bold">
          Cookies Policy
        </h1>
        <p className="flex gap-2 text-xl leading-[2.125rem] tracking-[-0.3%]">
          <span className="text-[#455468] font-normal">Last Updated: </span>
          <span className="font-medium text-[#111111]">June 1, 2024</span>
        </p>
      </header>

      <div className="flex flex-col px-3 gap-[20px] leading-7 tracking-[0.3%] lg:pt-[48px]">
        <section className="flex flex-col gap-[20px]">
          <h1 className="font-[700] text-[28px] text-dark_color">
            Introduction
          </h1>
          <p className="text-dark_color text-[16px]">
            Welcome to the Cookie Policy of Vegeel Technology Limited ["we,"
            "our," "us," or "the Company"]. This Cookie Policy is designed to
            provide clear and comprehensive information about the cookies and
            other tracking technologies we use on our website and digital
            platforms. Our commitment to your privacy and data protection is
            paramount, and this policy outlines how we utilize cookies to
            enhance your experience, ensure security, and comply with the
            Nigeria Data Protection Regulation [NDPR] and other relevant data
            protection laws.
          </p>
          <p className="text-dark_color text-[16px]">
            This Cookie Policy was last updated on July 1, 2024. As our services
            evolve and data protection regulations change, we may need to update
            this policy to reflect new practices or legal requirements. We
            encourage you to review this policy periodically to stay informed
            about our use of cookies and related technologies.
          </p>
        </section>
        <section className="flex flex-col gap-[20px]">
          <h1 className="font-[700] text-[28px] text-dark_color">
            What are Cookies?
          </h1>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Definition of Cookies
          </h2>
          <p className="text-dark_color text-[16px]">
            Cookies are small text files that are stored on your computer or
            mobile device when you visit a website. They are created by your web
            browser at the request of the website you are viewing. Cookies serve
            various purposes, such as remembering your preferences, enabling
            certain functionalities, and enhancing the overall user experience.
            They are a fundamental part of modern web technology, facilitating
            seamless and efficient interactions between users and websites.
          </p>
          <p className="text-dark_color text-[16px]">
            Cookies can be either "persistent" or "session" cookies. Persistent
            cookies remain on your device for a specified period or until you
            delete them, while session cookies are temporary and disappear once
            you close your web browser. Cookies can also be classified as
            "first-party" or "third-party" depending on whether they are set by
            the website you are visiting [first-party] or by a third party, such
            as an advertiser or analytics service [third-party].
          </p>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Types of Cookies
          </h2>
          <ol className="list-decimal text-dark_color flex flex-col gap-[20px]">
            <li>
              <p className="font-bold text-dark_color">Essential Cookies</p>
              <ul className="list-disc">
                <li>
                  <span className="font-bold text-dark_color">Purpose:</span>{" "}
                  Essential cookies are crucial for the proper functioning of
                  our website. They enable basic features such as page
                  navigation, secure access to protected areas, and the ability
                  to process online transactions. Without these cookies, certain
                  services on our website cannot function properly.
                </li>
                <li>
                  <span className="font-bold text-dark_color">Example:</span>{" "}
                  Authentication cookies that keep you logged in during your
                  session.
                </li>
              </ul>
            </li>
            <li>
              <p className="font-bold text-dark_color">Performance Cookies</p>
              <ul className="list-disc">
                <li>
                  <span className="font-bold text-dark_color">Purpose:</span>{" "}
                  Performance cookies collect information about how visitors use
                  our website. They help us understand which pages are most and
                  least popular, how visitors navigate the site, and where they
                  encounter errors. This data is used to improve the overall
                  performance and user experience of our website.
                </li>
                <li>
                  <span className="font-bold text-dark_color">Example: </span>
                  Google Analytics cookies that track user behavior on our site.
                </li>
              </ul>
            </li>
            <li>
              <p className="font-bold text-dark_color">Functional Cookies</p>
              <ul className="list-disc">
                <li>
                  <span className="font-bold text-dark_color">Purpose</span>:
                  Functional cookies enable enhanced functionality and
                  personalization. They may be set by us or by third-party
                  providers whose services we have added to our pages. These
                  cookies allow the website to remember your preferences, such
                  as your username, language, region, and other customizable
                  elements.{" "}
                </li>
                <li>
                  <span className="font-bold text-dark_color">Example:</span>{" "}
                  Cookies that remember your language preference.
                </li>
              </ul>
            </li>
            <li>
              <p className="font-bold text-dark_color">Targeting Cookies</p>
              <ul className="list-disc">
                <li>
                  <span className="font-bold text-dark_color">Purpose:</span>{" "}
                  Targeting cookies are used to deliver relevant advertisements
                  to you and measure the effectiveness of our advertising
                  campaigns. They may be set by us or by our advertising
                  partners. These cookies track your online activity to help
                  advertisers deliver ads that are more relevant to your
                  interests.{" "}
                </li>
                <li>
                  <span className="font-bold text-dark_color">Example:</span>{" "}
                  Cookies that track your browsing history and show you ads
                  based on that history.
                </li>
              </ul>
            </li>
            <li>
              <p className="font-bold text-dark_color">Social Media Cookies</p>
              <ul className="list-disc">
                <li>
                  <span className="font-bold text-dark_color">Purpose:</span>{" "}
                  Social media cookies are used to enable you to share our
                  content with your social media networks and to track your
                  engagement with our social media content. They may also be
                  used for advertising purposes by social media platforms.
                </li>
                <li>
                  <span className="font-bold text-dark_color">Example:</span>{" "}
                  Facebook or Twitter cookies that allow you to share content
                  and track your interactions.
                </li>
              </ul>
            </li>
          </ol>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Third-Party Cookies
          </h2>
          <p className="text-dark_color">
            In addition to our own cookies, we may also use various third-party
            cookies to report usage statistics of the website, deliver
            advertisements on and through the website, and so on. These
            third-party cookies are subject to the privacy policies of the
            respective third parties.
          </p>
        </section>
        <section className="flex flex-col gap-[20px]">
          <h1 className="font-[700] text-[28px] text-dark_color">
            How We Use Cookies
          </h1>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Purpose of Cookie Use
          </h2>
          <p className="text-dark_color text-[16px]">
            Cookies are essential tools that enable us to provide a better, more
            personalized experience for our users. Our use of cookies serves
            several important purposes, aligning with our commitment to data
            protection and privacy as stipulated by the Nigeria Data Protection
            Regulation [NDPR] and other relevant laws. Below, we detail the
            primary purposes for which we use cookies:
          </p>
          <ol className="list-decimal text-dark_color flex flex-col gap-[20px]">
            <li>
              <span className="font-bold text-dark_color">
                Enhancing User Experience:
              </span>{" "}
              Cookies help us tailor your experience on our website by
              remembering your preferences and settings. This includes language
              selection, login details, and personalization features. By storing
              these preferences, we ensure that you do not have to reset them
              each time you visit our site, thus providing a seamless and
              convenient browsing experience.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Improving Website Performance:
              </span>{" "}
              We use cookies to monitor and analyze how users interact with our
              website. Performance cookies collect data about your visit, such
              as the pages you view and the links you click on. This information
              allows us to identify areas for improvement, enhance site
              functionality, and ensure that the website operates smoothly.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Ensuring Security:{" "}
              </span>
              Security is a cornerstone of our operations, especially given the
              nature of our services as a cybersecurity company. Cookies play a
              critical role in safeguarding your data and our systems. They help
              detect and prevent malicious activities, such as unauthorized
              access, fraud, and other security threats. For instance, we use
              cookies to identify suspicious login attempts and to keep your
              session secure.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Providing Relevant Content and Advertising:
              </span>{" "}
              We strive to make our content and advertisements relevant to your
              interests. Cookies allow us to understand your browsing behavior
              and preferences, enabling us to serve you with targeted content
              and ads. This personalization ensures that you see information
              that is pertinent and valuable to you, improving your overall
              experience on our website.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Compliance with Legal Requirements:
              </span>{" "}
              Our use of cookies also ensures compliance with legal obligations.
              By using cookies to manage consent and data collection practices,
              we adhere to the NDPR and other data protection laws, safeguarding
              your rights and ensuring transparent data processing.
            </li>
          </ol>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Types of Data Collected
          </h2>
          <p className="text-dark_color text-[16px]">
            Cookies help us collect various types of data that can be broadly
            categorized as follows:
          </p>
          <ol className="list-decimal text-dark_color flex flex-col gap-[20px]">
            <li>
              <span className="font-bold text-dark_color">
                Personal Information:
              </span>{" "}
              While cookies do not typically collect personal information
              directly, they can associate your device with previously provided
              personal information. This may include your name, email address,
              and other contact details if you have created an account or
              provided this information on our website. It is important to note
              that any personal information linked to cookies is handled in
              accordance with our Privacy Policy and the NDPR.
            </li>
            <li>
              <span className="font-bold text-dark_color">Technical Data:</span>{" "}
              Cookies gather technical data about your device and browsing
              behavior. This includes your IP address, browser type, operating
              system, and device model. Technical data helps us ensure
              compatibility and optimize the performance of our website across
              different devices and browsers.
            </li>
            <li>
              <span className="font-bold text-dark_color">Usage Data: </span>
              Usage data includes information about how you interact with our
              website. This encompasses the pages you visit, the time you spend
              on each page, the links you click, and the frequency of your
              visits. Usage data is crucial for understanding user behavior and
              improving site navigation and content layout.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Preference Data:
              </span>{" "}
              Cookies remember your preferences and settings, such as language
              selection, region, and customized layouts. Preference data ensures
              that your subsequent visits are more tailored and user-friendly,
              saving you time and effort in resetting preferences.
            </li>
            <li>
              <span className="font-bold text-dark_color">Security Data:</span>{" "}
              Security-related cookies collect data to identify and prevent
              security threats. This includes information about failed login
              attempts, unusual account activity, and other potential security
              risks. Security data helps us maintain a safe and secure
              environment for all users.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                {" "}
                Advertising Data:
              </span>{" "}
              Advertising cookies collect data about your browsing habits and
              interactions with advertisements. This includes information about
              the ads you have seen and clicked on, your interests inferred from
              browsing behavior, and interactions with our marketing emails.
              Advertising data enables us to deliver more relevant and targeted
              ads to you.
            </li>
          </ol>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Managing and Disabling Cookies
          </h2>
          <p className="text-dark_color text-[16px]">
            You have the right to manage and disable cookies according to your
            preferences. Most web browsers allow you to control cookies through
            their settings. You can choose to block or delete cookies, or to be
            notified when cookies are being set. However, please note that
            disabling cookies may affect the functionality and performance of
            our website.{" "}
          </p>
          <h2 className="font-bold text-dark_color text-[20px] ">
            How to manage Cookies in Your Browser
          </h2>
          <ul className="text-dark_color text-[16px] list-disc">
            <li>
              <span className="font-bold text-dark_color">Google Chrome:</span>{" "}
              Go to Settings- Privacy and Security- Cookies and other site data.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Mozilla Firefox:
              </span>{" "}
              Go to Options- Privacy & Security- Cookies and Site Data.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Microsoft Edge:{" "}
              </span>
              Go to Settings Site permissions- Cookies and site data.
            </li>
            <li>
              <span className="font-bold text-dark_color">Safari:</span> Go to
              Preferences Privacy- Cookies and website data.
            </li>
          </ul>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Third-Party Cookies
          </h2>
          <p className="text-dark_color text-[16px]">
            In addition to our own cookies, we may also use various third-party
            cookies to report usage statistics of the website, deliver
            advertisements on and through the website, and so on. These
            third-party cookies are subject to the privacy policies of the
            respective third parties.
          </p>
          <h2 className="font-bold text-dark_color text-[20px] ">
            Consent and Control
          </h2>
          <p className="text-dark_color text-[16px]">
            When you first visit our website, you will be presented with a
            cookie consent banner that allows you to accept or manage your
            cookie preferences. By clicking "Accept All Cookies," you consent to
            the use of all cookies. By clicking "Manage Cookies," you can choose
            which types of cookies to accept. You can change your cookie
            preferences at any time through our website's cookie settings or
            your browser settings.
          </p>
        </section>
        <section className="flex flex-col gap-[20px]">
          <h1 className="font-[700] text-[28px] text-dark_color">
            Managing Cookies
          </h1>
          <h2 className="font-bold text-dark_color text-[20px]">
            User Control and Settings
          </h2>
          <p className="text-dark_color text-[16px]">
            At Vegeel, we believe in empowering our users with the ability to
            manage their cookie preferences. Understanding that each user may
            have different levels of comfort with cookie usage, we provide
            several ways for you to control and customize your cookie settings.
            This section explains how you can manage cookies on our website to
            align with your preferences and compliance with the Nigeria Data
            Protection Regulation [NDPR].
          </p>
          <h3 className="font-bold text-dark_color">Browser Settings</h3>
          <p className="text-dark_color text-[16px]">
            Most web browsers allow you to control cookies through their
            settings. You can choose to block or delete cookies, or to be
            notified when cookies are being set. Here are the steps to manage
            cookies in the most popular browsers:
          </p>
          <ul className="list-disc text-dark_color flex flex-col gap-[20px]">
            <li>
              <h4 className="font-bold">Google Chrome:</h4>
              <ol className="list-decimal text-dark_color">
                <li>
                  Open Chrome and click on the three dots in the upper right
                  corner.
                </li>
                <li>Select "Settings" from the dropdown menu.</li>
                <li>
                  Under "Privacy and Security," click on "Cookies and other site
                  data."
                </li>
                <li>
                  You can choose to allow all cookies, block third-party
                  cookies, or block all cookies. You can also manage and delete
                  specific cookies.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="font-bold">Mozilla Firefox:</h4>
              <ol className="list-decimal">
                <li>
                  Open Firefox and click on the three horizontal lines in the
                  upper right corner.
                </li>
                <li>Select "Options" from the dropdown menu.</li>
                <li>
                  Go to "Privacy & Security" and scroll down to "Cookies and
                  Site Data."
                </li>
                <li>
                  Here, you can choose to block all cookies, block third-party
                  cookies, or manage exceptions.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="font-bold">Microsoft Edge:</h4>
              <ol className="list-decimal">
                <li>
                  Open Edge and click on the three dots in the upper right
                  corner.
                </li>
                <li>Select "Settings" from the dropdown menu.</li>
                <li>
                  Click on "Cookies and site permissions" under the "Site
                  permissions" section.
                </li>
                <li>
                  You can choose to allow all cookies, block third-party
                  cookies, or block all cookies.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="font-bold">Safari:</h4>
              <ol className="list-decimal">
                <li>Open Safari and go to "Preferences."</li>
                <li>Click on the "Privacy" tab.</li>
                <li>
                  Under "Cookies and website data," you can choose to block all
                  cookies, allow cookies from the current website only, or allow
                  cookies from websites you visit.
                </li>
              </ol>
            </li>
          </ul>
          <h3 className="font-bold text-dark_color text-[18px]">
            Cookie Consent Banner
          </h3>
          <p className="text-dark_color text-[16px]">
            When you first visit our website, you will be presented with a
            cookie consent banner that allows you to accept or manage your
            cookie preferences. By clicking "Accept All Cookies," you consent to
            the use of all cookies. By clicking "Manage Cookies," you can choose
            which types of cookies to accept. This banner ensures that you are
            fully informed and have control over the cookies being used on our
            website.
          </p>
          <h2 className="font-bold text-dark_color text-[20px]">
            Opt-Out Mechanisms
          </h2>
          <p className="text-dark_color text-[16px]">
            We provide several opt-out mechanisms to give you more control over
            your data and enhance your privacy. These mechanisms allow you to
            withdraw your consent to the use of certain types of cookies at any
            time. Here are the primary methods for opting out of cookies on our
            website:
          </p>
          <h3 className="font-bold text-dark_color text-[18px]">
            Cookie Settings
          </h3>
          <p className="text-dark_color text-[16px]">
            You can access our cookie settings page at any time to change your
            cookie preferences. This page allows you to enable or disable
            different categories of cookies, such as essential, performance,
            functional, and targeting cookies. Any changes you make will be
            applied immediately, giving you real-time control over your cookie
            settings.
          </p>
          <h3 className="font-bold text-dark_color text-[18px]">
            Opt-Out Links for Third-Party Cookies
          </h3>
          <p className="text-dark_color text-[16px]">
            Some of our third-party service providers offer their own opt-out
            mechanisms. For example, if we use Google Analytics or other similar
            services, you can opt-out of data collection by these providers
            through their opt-out links. These links are usually found in the
            privacy policies of the respective third parties. We provide these
            links on our cookie settings page for your convenience.
          </p>
          <h3 className="font-bold text-dark_color text-[18px]">
            Do Not Track [ONT] Signals
          </h3>
          <p className="text-dark_color text-[16px]">
            While our website currently does not respond to "Do Not Track" [ONT]
            signals, you can use other privacy-enhancing tools and browser
            extensions to block tracking cookies. ONT is a browser setting that
            requests that a web application disable its tracking or cross-site
            user tracking. However, not all websites and third-party services
            honor ONT signals, so it is not a guaranteed method for opting out
            of tracking.
          </p>
          <h3 className="font-bold text-dark_color text-[18px]">
            Impact of Disabling Cookies
          </h3>
          <p className="text-dark_color text-[16px]">
            It is important to note that disabling cookies may affect the
            functionality and performance of our website. Essential cookies are
            necessary for the website to function properly, and disabling them
            may prevent you from using certain features or accessing secure
            areas. Similarly, disabling performance or functional cookies may
            impact your experience by reducing personalization and site
            responsiveness
          </p>
        </section>
        <section className="flex flex-col gap-[20px]">
          <h1>Changes to this Policy</h1>
          <h2 className="font-bold text-dark_color text-[20px]">
            Notification of Changes
          </h2>
          <p className="text-dark_color text-[16px]">
            We are always committed to maintaining transparency and keeping you
            informed about how we use cookies and other tracking technologies.
            As part of this commitment, we may periodically update this Cookie
            Policy to reflect changes in our practices, technologies, or legal
            requirements, including compliance with the Nigeria Data Protection
            Regulation [NDPR] and other applicable laws.Whenever we make
            significant changes to this Cookie Policy, we will notify you in
            several ways to ensure that you are aware of the updates:
          </p>
          <ol className="list-decimal text-dark_color flex flex-col gap-[20px]">
            <li>
              <span className="font-bold text-dark_color">
                Website Notification:
              </span>{" "}
              We will post a prominent notice on our website to inform you of
              the changes. This notification will typically appear as a banner
              or pop-up message when you visit our site, highlighting that the
              Cookie Policy has been updated and providing a link to the revised
              policy.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Email Notification:{" "}
              </span>
              If you have provided us with your email address and consented to
              receivecommunications from us, we may send you an email
              notification about the changes. This emailwill summarize the key
              updates and provide a direct link to the full Cookie Policy on our
              website.
            </li>
            <li>
              <span className="font-bold text-dark_color">
                Updated Policy Date:{" "}
              </span>
              The "Last Updated" date at the top of this Cookie Policy will be
              revised to reflect the date of the most recent changes. This
              allows you to quickly identify when the policy was last modified.
            </li>
          </ol>
          <p className="text-dark_color text-[16px]">
            We encourage you to review this Cookie Policy periodically to stay
            informed about how we use cookies and the choices you have regarding
            their use. Your continued use of our website after any changes to
            this policy indicates your acceptance of the updated terms.
          </p>
          <h2 className="font-bold text-dark_color text-[20px]">
            Effective Date of Changes
          </h2>
          <p className="text-dark_color text-[16px]">
            The changes to this Cookie Policy will take effect immediately upon
            posting the updated policy on our website, unless otherwise
            specified. The "Effective Date" of the changes will be clearly
            indicated at the top of the policy. By continuing to use our website
            and services after the effective date, you agree to be bound by the
            revised Cookie Policy.
          </p>
          <p className="text-dark_color text-[16px]">
            In some cases, where required by law or if the changes are
            significant, we may provide a longer notice period before the
            changes become effective. This gives you ample time to review the
            updates and adjust your cookie preferences or settings accordingly.
          </p>
          <p className="text-dark_color text-[16px]">
            If you do not agree with the changes to this Cookie Policy, you
            should discontinue using our website and services. We value your
            privacy and are committed to providing you with the information and
            tools necessary to make informed decisions about your data.
          </p>
        </section>
        <section className="flex flex-col gap-[20px]">
          <h1 className="font-[700] text-[28px] text-dark_color">
            Contact Information
          </h1>
          <h2 className="font-bold text-dark_color text-[20px] ">
            How to Reach Us for Cookie Concerns
          </h2>
          <p className="text-dark_color text-[16px]">
            We prioritize transparency and are committed to addressing any
            concerns you may have regarding our use of cookies and other
            tracking technologies. If you have questions, concerns, or need
            further information about our Cookie Policy, please do not hesitate
            to reach out to us.
          </p>
          <div>
            <h2 className="font-bold text-dark_color text-[18px]">
              Contact Details
            </h2>
            <p className="text-dark_color text-[16px]">
              You can contact us through the following channels:
            </p>
          </div>
          <div>
            <h2 className="font-bold text-dark_color text-[18px]">Email:</h2>
            <p className="text-dark_color text-[16px]">
              For any inquiries related to our Cookie Policy or to exercise your
              data protection rights under the Nigeria Data Protection
              Regulation [NDPR], you can email us at: privacy@vegeel.com
            </p>
          </div>
          <div>
            <h2 className="font-bold text-dark_color text-[18px]">
              Postal Mail:
            </h2>
            <p className="text-dark_color text-[16px]">
              If you prefer to send us a letter, please address your
              correspondence to: Vegeel Technology Limited
            </p>
            <p className="text-dark_color text-[16px]">
              Attn: Data Protection Officer
            </p>
            <p className="text-dark_color text-[16px]">35 Adebisi St, Yaba,</p>
            <p className="text-dark_color text-[16px]">Lagos, Nigeria.</p>
          </div>
          <div>
            <h2 className="font-bold text-dark_color text-[18px]">
              Website Contact Form:
            </h2>
            <p className="text-dark_color text-[16px]">
              We also provide a contact form on our website for your
              convenience. You can submit your queries or concerns through the
              form available at:{" "}
              <span className="text-main_color"> www.vegeel.com/contact</span>
            </p>
          </div>
          <div>
            <h2 className="font-bold text-dark_color text-[18px]">
              Data Protection Officer [DPO]:
            </h2>
            <p className="text-dark_color text-[16px]">
              Our Data Protection Officer is responsible for overseeing our data
              protection strategy and implementation. For specific queries
              related to data protection, including cookie usage, you can
              contact our DPO directly at:{" "}
              <span className="text-main_color">privacy@vegeel.com</span>
            </p>
          </div>
          <div>
            <h2 className="font-bold text-dark_color text-[18px]">
              Response Time
            </h2>
            <p className="text-dark_color text-[16px]">
              We aim to respond to all inquiries within 4 business days. Your
              feedback is important to us, and we strive to address your
              concerns promptly and effectively.
            </p>
          </div>
        </section>
      </div>
    </article>
  );
}
