import React from "react";

export default function Legalreq() {
  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <h1 className="font-[700] text-[28px] text-dark_color">
          Legal Requirements
        </h1>
        <p className="text-dark_color text-[16px]">
          In certain circumstances, we may be required to disclose your personal
          information to comply with legal obligations. These situations
          include:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Compliance with Laws</p>
            <ul className="list-disc">
              <li>
                Requirement: We may disclose your data to comply with applicable
                laws, regulations, or legal processes. This includes responding
                to court orders, subpoenas, or government requests.Data Shared:
                The specific data disclosed will depend on the legal requirement
                and the nature of the request.
              </li>
              <li>
                Data Protection: We ensure that any disclosure complies with
                legal standards and takes into account your privacy rights.
              </li>
            </ul>
          </li>
          <li>
            <p>Protection of Rights and Safety</p>
            <ul className="list-disc">
              <li>
                Requirement: We may share information when necessary to protect
                our rights, property, or safety, as well as the rights,
                property, or safety of our users, employees, or others.
              </li>
              <li>
                Data Shared: This can include identification information,
                contact details, and any relevant data necessary to address the
                situation.
              </li>
              <li>
                Data Protection: We limit disclosures to what is strictly
                necessary and ensure that your data is handled securely.
              </li>
            </ul>
          </li>
          <li>
            <p>Preventing Harm and Fraud</p>
            <ul className="list-disc">
              <li>
                Requirement: We may disclose information to prevent or
                investigate potential wrongdoing, fraud, or security breaches.
              </li>
              <li>
                Data Shared: This may include account details, transaction
                history, and any data relevant to the investigation.
              </li>
              <li>
                Data Protection: Measures are taken to ensure that disclosures
                are limited to necessary information and that the data is
                protected during the process.
              </li>
            </ul>
          </li>
          <li>
            <p>Regulatory Compliance</p>
            <ul className="list-disc">
              <li>
                Requirement: We may be required to share information to comply
                with regulatory requirements, such as financial reporting
                obligations and data protection audits.
              </li>
              <li>
                Data Shared: This can include financial information, transaction
                records, and compliance related data.
              </li>
              <li>
                Data Protection: Disclosures are made in a manner that ensures
                compliance with regulatory requirements while protecting your
                data.
              </li>
            </ul>
          </li>
          <li>
            <p>Merger or Acquisition</p>
            <ul className="list-disc">
              <li>
                Requirement: In the event of a merger, acquisition, or sale of
                assets, we may need to disclose personal information to the
                involved parties.
              </li>
              <li>
                Data Shared: This includes any information necessary for due
                diligence and the transaction process, such as customer
                databases and financial records.
              </li>
              <li>
                Data Protection: Any sharing of data in these circumstances will
                be governed by confidentiality agreements and will comply with
                data protection laws.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
}
