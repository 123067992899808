import React, { useEffect, useState } from "react";
import lightBulb from "../../assets/icons/lightbulb.svg";
import Button from "../../components/Button";
import heroImg from "../../assets/images/vegeel-hero-img.svg";
import iso27001logo from "../../assets/images/iso-27001-logo.svg";
import soc2 from "../../assets/images/soc-2-logo.svg";
import pciDss from "../../assets/images/pci-dss-logo.svg";
import iso20000 from "../../assets/images/iso-20000-logo.svg";
import iso22301 from "../../assets/images/iso-22301.svg";
import ims from "../../assets/icons/ims.svg";
import rocket from "../../assets/icons/rocket.svg";
import tailorImg from "../../assets/images/tailor-hero-img.svg";
import whyVegeelImg from "../../assets/images/why-vegeel-img.svg";
import contactSaleImg from "../../assets/images/contact-sale-img.svg";
import speakToUs from "../../assets/icons/speak-to-us-icon.svg";
import emailUs from "../../assets/icons/email-us-icon.svg";
import getDemo from "../../assets/icons/get-demo-icon.svg";
import complianceStack from "../../assets/images/compliance-stack-img.svg";
import prodoctIso27001 from "../../assets/images/product-iso-27001-logo.svg";
import productPci from "../../assets/images/product-pci-logo.svg";
import productSoc2 from "../../assets/images/product-soc-2-logo.svg";
import productIms from "../../assets/images/product-ims-logo.svg";
import productIso200000 from "../../assets/images/product-iso-20000-logo.svg";
import productIso22301 from "../../assets/images/product-iso-22301-logo.svg";
import homeRocketIcon from "../../assets/icons/home-rocket-icon.svg";
import homeDashboardImg from "../../assets/images/vegeel-dashboard-home-img.svg";
import effortEvidence from "../../assets/images/new-why-vegeel-img.svg";
import strengthenYourCompliance from "../../assets/images/strengthen-your-compliance-img.svg";
import streamlineAuditProcess from "../../assets/images/streamline-audit-process-img.svg";
import homeDashboardImgTwo from "../../assets/images/desktop-home-img.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";
import { useMenuContext, useModalContext } from "../../context";
import BookADemo from "../../components/BookADemoModal";
import ContactSales from "../../components/ContactSalesModal";
import axios from "axios";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";

export interface SupportedFrameworksProps {
  name: string;
  logo: any;
}
export interface WhyVegeelFirstProps {
  bgColor: string;
  image: any;
  imageAlt: string;
  whyVegeelHeader: string;
  whyVegeelDetail: string;
  textColor: string;
  textHeaderColor: string;
}
export interface WhyVegeelSecondProps {
  bgColor: string;
  image: any;
  imageAlt: string;
  whyVegeelHeader: string;
  whyVegeelDetail: string;
  textColor: string;
  textHeaderColor: string;
}
export interface ContactSalesCardProps {
  image: any;
  name: string;
  detail: string;
  phoneNo: string | "";
  type: "phone" | "email" | "demo";
  onClick?: () => void;
}

interface DemoData {
  [key: string]: any;
}

type Errors = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  email?: string;
  product?: string;
};

const SupportedFrameworks = ({ logo, name }: SupportedFrameworksProps) => {
  return (
    <div className="w-[100px] lg:w-[125px] h-[120px] lg:h-[156px] bg-[#FFFFFF] border border-[#D7DFE9] rounded-lg flex justify-center items-center flex-col gap-4">
      <div className="">
        <img src={logo} alt={name} />
      </div>
      <h1 className="text-[#455468] text-[1rem] lg:text-[1.25rem] font-bold">
        {name}
      </h1>
    </div>
  );
};

const WhyVegeelFirst = ({
  bgColor,
  image,
  imageAlt,
  whyVegeelHeader,
  whyVegeelDetail,
  textColor,
  textHeaderColor,
}: WhyVegeelFirstProps) => {
  const { openBookADemoModal } = useModalContext();

  return (
    <div
      className={`w-[95%] lg:w-[85%] lg:h-[350px] flex flex-col-reverse lg:flex-row lg:justify-between lg:items-center lg:gap-6 ${bgColor} px-3 lg:px-[50px] py-4 lg:py-[20px] p rounded-2xl`}
    >
      <div className="lg:w-[45%]">
        <h2
          className={`text-[2rem] font-bold ${textHeaderColor} pt-4 leading-[36px] lg:w-[70%]`}
        >
          {whyVegeelHeader}
        </h2>
        <p
          className={`text-[1rem] ${textColor} pt-3 leading-[28px] tracking-wide`}
        >
          {whyVegeelDetail}
        </p>
        <div className="pt-4 w-[50%]">
          <Button
            onClick={openBookADemoModal}
            variant="plain"
            size="large"
            icon
            iconType="arrow"
          >
            Request demo
          </Button>
        </div>
      </div>
      <div className="w-full lg:w-[45%] h-full">
        <img src={image} alt={imageAlt} className="w-full h-full" />
      </div>
    </div>
  );
};

const WhyVegeelSecond = ({
  bgColor,
  image,
  imageAlt,
  whyVegeelHeader,
  whyVegeelDetail,
  textColor,
  textHeaderColor,
}: WhyVegeelSecondProps) => {
  const { openBookADemoModal } = useModalContext();

  return (
    <div
      className={`w-[95%] lg:w-[85%] lg:h-[350px] lg:flex lg:justify-between lg:items-center lg:gap-6 ${bgColor} px-3 lg:px-[50px] py-4 lg:py-[20px] p rounded-2xl`}
    >
      <div className="w-full lg:w-[45%] h-full">
        <img src={image} alt={imageAlt} className="w-full h-full" />
      </div>
      <div className="lg:w-[45%]">
        <h2
          className={`text-[2rem] font-bold ${textHeaderColor} pt-4 leading-[36px] lg:w-[60%]`}
        >
          {whyVegeelHeader}
        </h2>
        <p
          className={`text-[1rem] ${textColor} pt-3 leading-[28px] tracking-wide`}
        >
          {whyVegeelDetail}
        </p>
        <div className="pt-4 w-[50%]">
          <Button
            onClick={openBookADemoModal}
            variant="contained"
            size="large"
            icon
            iconType="arrowWhite"
          >
            Request demo
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ContactSalesCard = ({
  image,
  name,
  detail,
  type,
  phoneNo,
  onClick,
}: ContactSalesCardProps) => {
  const renderContent = () => {
    switch (type) {
      case "phone":
        return (
          <a href={`tel:${phoneNo}`} className="text-[1.25rem] text-[#1B4DFF]">
            {detail}
          </a>
        );
      case "email":
        return (
          <a
            href={`mailto:${detail}`}
            className="text-[1.25rem] text-[#1B4DFF]"
          >
            {detail}
          </a>
        );
      case "demo":
        return (
          <button onClick={onClick} className="text-[1.25rem] text-[#1B4DFF]">
            {detail}
          </button>
        );
      default:
        return <h1 className="text-[1.25rem] text-[#1B4DFF]">{detail}</h1>;
    }
  };

  return (
    <div className="bg-[#FFFFFF] border border-[#D7DFE9] rounded-[6px] flex justify-start items-center py-[20px] px-[30px] gap-6 lg:gap-3 w-full">
      <div className="">
        <img src={image} alt="Call Us" />
      </div>
      <div className="">
        <h1 className="text-[#111111] text-[1.13rem] font-medium">{name}</h1>
        {/* <h1 className="text-[1.25rem] text-[#1B4DFF]">{detail}</h1> */}
        {renderContent()}
      </div>
    </div>
  );
};

const handleBookDemo = async (
  data: DemoData,
  setLoading: (loading: boolean) => void
) => {
  try {
    const response = await axios.post(
      "https://email-transporter.onrender.com/book-a-demo/send",
      data
    );

    if (response.status === 200) {
      if (response.status === 200) {
        setLoading(false);
        window.open("https://tidycal.com/36kgx91/vegeel-demo", "_blank");
      }
    }
  } catch (error: any) {
    if (error.message === "Network Error") {
      toast.error("Network error. Please check your internet connection.");
    } else if (error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    setLoading(false);
  }
};

export default function Home() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const { closeDropdown } = useMenuContext();
  const { openContactSalesModal, openBookADemoModal } = useModalContext();

  const validateInputs = () => {
    const newErrors: Errors = {};
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!companyName) newErrors.companyName = "Company name is required";
    if (!email) newErrors.email = "Email is required";
    if (!product.length)
      newErrors.product = "At least one product must be selected";
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setTimeout(() => {
        setErrors({});
      }, 3000);
    }

    return Object.keys(newErrors).length === 0;
  };

  const bookDemo = async () => {
    if (validateInputs()) {
      setLoading(true);
      const demoData = {
        firstName,
        lastName,
        companyName,
        email,
        products: product.join(", "),
      };

      await handleBookDemo(demoData, setLoading);
    }
  };

  const handleCheckboxChange = (productName: string, isChecked: boolean) => {
    if (isChecked) {
      setProduct([...product, productName]);
    } else {
      setProduct(product.filter((item) => item !== productName));
    }
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div onClick={() => closeDropdown()}>
      <div className="bg-[#000033] pt-[3rem] lg:pt-[8rem] flex justify-center items-center flex-col">
        <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[314px] h-[40px] rounded-full py-[10px] pr-[20px] pl-[15px]">
          <span className="pr-2">
            <img src={lightBulb} alt="light-bulb" />
          </span>
          <h3 className="text-[0.75rem] text-[#93AAFD]">
            AI-driven cybersecurity compliance platform
          </h3>
        </div>

        <div className="px-5 text-center text-[#FFFFFF] pt-4 flex justify-center items-center flex-col">
          <h1 className="text-[3rem] lg:text-[5rem] font-medium leading-[56px] lg:leading-[82px] px-3 lg:tracking-tight">
            Effortless <br className="lg:hidden" />
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              security
            </span>{" "}
            and <br className="hidden lg:flex" />
            <span className="gradient-text bg-gradient-to-l-custom bg-clip-text text-transparent">
              compliance <br className="lg:hidden" />
            </span>{" "}
            management
          </h1>

          <h2 className="text-[1rem] lg:text-[1.25rem] leading-[28px] pt-4 lg:pt-[20px]  lg:pb-5">
            Vegeel automates compliance tasks, control monitoring, and audit
            management, making <br className="hidden lg:flex" /> your processes
            seamless and efficient.
          </h2>
          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 lg:pt-7 px-7 w-full lg:w-[55%]">
            <Button
              onClick={openBookADemoModal}
              variant="contained"
              size="large"
              icon={false}
            >
              Request demo
            </Button>
            <Button
              onClick={openContactSalesModal}
              variant="plain"
              size="large"
              icon
              iconType="arrow"
            >
              Contact sales
            </Button>
          </div>
          <div className="pt-8 lg:pt-12 w-full">
            <img src={heroImg} alt="" className="w-full h-full" />
          </div>
        </div>
      </div>
      <section className="pt-[48px] lg:pt-[64px] lg:px-[200px] pb-[12px] bg-[#EDF1FF] px-4 flex justify-center items-center flex-col">
        <h1 className="text-center text-[1.75rem] lg:text-[3rem] text-[#060B59] font-bold">
          Supported frameworks
        </h1>
        <div className="pt-4 flex justify-center items-center flex-wrap gap-5 lg:gap-5 lg:hidden">
          <SupportedFrameworks logo={iso27001logo} name={"ISO 27001"} />
          <SupportedFrameworks logo={soc2} name={"SOC 2"} />
          <SupportedFrameworks logo={pciDss} name={"PCI DSS"} />
          <SupportedFrameworks logo={iso20000} name={"ISO 20000"} />
          <SupportedFrameworks logo={iso22301} name={"ISO 22301"} />
          <SupportedFrameworks logo={ims} name={"IMS"} />
        </div>

        <div className="pt-4 w-full hidden lg:flex">
          <Swiper
            spaceBetween={10}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
              reverseDirection: false,
            }}
            speed={6000}
            loop={true}
            modules={[Autoplay]}
            className="mySwiper"
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
          >
            <SwiperSlide>
              <SupportedFrameworks logo={iso27001logo} name={"ISO 27001"} />
            </SwiperSlide>
            <SwiperSlide>
              <SupportedFrameworks logo={soc2} name={"SOC 2"} />
            </SwiperSlide>
            <SwiperSlide>
              <SupportedFrameworks logo={pciDss} name={"PCI DSS"} />
            </SwiperSlide>
            <SwiperSlide>
              <SupportedFrameworks logo={iso20000} name={"ISO 20000"} />
            </SwiperSlide>
            <SwiperSlide>
              <SupportedFrameworks logo={iso22301} name={"ISO 22301"} />
            </SwiperSlide>
            <SwiperSlide>
              <SupportedFrameworks logo={ims} name={"IMS"} />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="bg-[#EDF1FF] pt-[12px] lg:pt-[64px] lg:px-[150px] flex justify-center items-center flex-col px-[15px]">
        <div className="flex justify-center items-center bg-[#FFFAEB] w-[314px] h-[40px] rounded-full py-[10px] px-[15px]">
          <span className="pr-2">
            <img src={rocket} alt="rocket" />
          </span>
          <h3 className="text-[0.75rem] text-[#EA6F03] lg:font-medium">
            Stay in control and ensure compliance
          </h3>
        </div>
        <h1 className="text-[#060B59] font-bold text-[1.75rem] lg:text-[3rem] text-center pt-2 lg:pt-5 leading-[32px] lg:px-7">
          Tailored to your <br className="lg:hidden" /> company’s needs
        </h1>
        <p className="text-center pt-3 lg:pt-5 text-[1rem] lg:text-[1.25rem] text-[#455468] lg:w-[80%]">
          Our platform is tailored to cater to the unique compliance needs of
          companies, ensuring seamless adherence to local regulations.
        </p>
        <div className="pt-6 w-full h-full">
          <img
            src={tailorImg}
            alt="vegeel-dashboard"
            className="w-full h-full"
          />
        </div>
      </section>
      <section className="py-[48px] flex justify-center items-center flex-col px-[10px]">
        <h2 className="text-[#060B59] text-[1.75rem] lg:text-[3rem] font-bold ">
          Why Vegeel?
        </h2>
        <p className="text-center text-[#455468] leading-[28px] w-[95%] lg:w-[60%]">
          We are committed to helping businesses across Africa build and
          maintain trust with their users, customers, partners, and prospects
          through our cutting-edge security and compliance automation solution.
        </p>
        <div className="pt-6 flex justify-center items-center flex-col gap-7">
          <WhyVegeelFirst
            bgColor="bg-[#1A4BFF]"
            image={effortEvidence}
            imageAlt="effortless evidende collection"
            whyVegeelHeader="Effortless evidence collection"
            whyVegeelDetail="Collecting and validating evidence has never been easier. With Vegeel,
        you can effortlessly gather evidence automatically through integrations,
        manual uploads, and a rich set of in-product resources."
            textColor="text-[#FAFAFA]"
            textHeaderColor="text-[#FFFFFF]"
          />
          <WhyVegeelSecond
            bgColor="bg-[#EDF1FF]"
            image={whyVegeelImg}
            imageAlt="Continuous monitoring"
            whyVegeelHeader="Continuous monitoring"
            whyVegeelDetail="Gain real-time insights into your security status through meticulously crafted control tests and implemented security measures."
            textHeaderColor="text-[#060B59]"
            textColor="text-[#060B59"
          />
          <WhyVegeelFirst
            bgColor="bg-[#1A4BFF]"
            image={strengthenYourCompliance}
            imageAlt="Strengthen your compliance"
            whyVegeelHeader="Strengthen your compliance"
            whyVegeelDetail="Bid farewell to the hassles of manual GRC processes. Vegeel supports an extensive array of global standards, including ISO 27001, and seamlessly integrates with custom frameworks."
            textColor="text-[#FAFAFA]"
            textHeaderColor="text-[#FFFFFF]"
          />
          <WhyVegeelSecond
            bgColor="bg-[#EDF1FF]"
            image={streamlineAuditProcess}
            imageAlt="Streamline audit process"
            whyVegeelHeader="Streamline audit process"
            whyVegeelDetail="Centralize your audit procedures for a seamless, swift, and efficient auditing experience. Vegeel makes audits a breeze, saving you time and resources."
            textHeaderColor="text-[#060B59]"
            textColor="text-[#060B59"
          />
        </div>
      </section>
      <section className="px-[15px] bg-[#EDF1FF] py-[48px] lg:px-[150px]">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-[40%]">
            <h2 className="text-[#060B59] text-[1.75rem] font-bold lg:pb-[20px]">
              Contact sales
            </h2>
            <p className="text-[#455468] text-[1rem] leading-[28px]">
              Tell us what your business needs and we will find the best
              solution for you. We’d be happy to walk you through a demo or
              discuss how we can simplify and automate your compliance process.{" "}
            </p>
          </div>
          <div className="lg:w-[45%]">
            <div className="pt-6 w-full h-full">
              <img
                src={contactSaleImg}
                alt="Contact Sale"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>

        <div className="pt-4 lg:pt-10 flex justify-center items-center flex-col gap-4 lg:flex-row">
          <ContactSalesCard
            image={speakToUs}
            name="Speak with us"
            detail="+234-901 495 7203"
            type="phone"
            phoneNo={"+2349014957203"}
          />
          <ContactSalesCard
            image={emailUs}
            name="Email us"
            detail="hello@vegeel.com"
            type="email"
            phoneNo={""}
          />
          <ContactSalesCard
            image={getDemo}
            name="Get demo"
            detail="Schedule now"
            type="demo"
            phoneNo={""}
            onClick={openBookADemoModal}
          />
        </div>
      </section>
      <section className="lg:p-[35px] bg-[#EDF1FF]">
        <div className="bg-[#042BB2] py-[48px] px-[15px] lg:flex lg:justify-between lg:items-start lg:rounded-3xl lg:p-[50px]">
          <div className="lg:w-[45%]">
            <div className="flex justify-center items-center bg-[#FFFAEB] w-[127px] lg:w-[189px] h-[40px] lg:h-[48px] rounded-full py-[10px] px-[15px]">
              <h3 className="text-[0.75rem] lg:text-[1rem] text-[#EA6F03] font-medium">
                Schedule a demo
              </h3>
            </div>
            <h1 className="text-[#FFFFFF] text-[1.75rem] lg:text-[3rem] lg:leading-[64px] font-bold pt-1 lg:pt-3">
              Schedule a personalized demo with Vegeel.
            </h1>
            <p className="leading-[28px] lg:leading-[34px] text-[1rem] lg:text-[1.25rem] pt-2 lg:pt-7 text-[#FFFFFF] lg:tracking-tight">
              With a streamlined workflow and dedicated expert guidance, Vegeel
              automates the entire compliance process, and increase your audit
              readiness. Complete the form and let’s explore how Vegeel can fit
              your exact needs.
            </p>
            <h1 className="pt-6 lg:pt-[50px] text-[1rem] text-[#FFFFFF]">
              Our compliance stack
            </h1>
            <div className="pt-2">
              <img src={complianceStack} alt="Our Stack" />
            </div>
          </div>
          <div className="bg-[#FFFFFF] border border-[#D7DFE9] rounded-[10px] p-[24px] mt-8 lg:mt-0 lg:w-[50%]">
            <div className="lg:flex lg:justify-between lg:items-center">
              <div className="pb-3 lg:w-[47%]">
                <label
                  htmlFor="firstname"
                  className="text-[#455468] text-[1rem] block pb-1"
                >
                  First name <span className="text-[#1B4DFF]">*</span>
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Enter first name"
                  value={firstName}
                  className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                  onChange={handleFirstNameChange}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs pt-1">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div className="pb-3 lg:w-[47%]">
                <label
                  htmlFor="lastname"
                  className="text-[#455468] text-[1rem] block pb-1"
                >
                  Last name <span className="text-[#1B4DFF]">*</span>
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Enter last name"
                  value={lastName}
                  className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                  onChange={handleLastNameChange}
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs pt-1">{errors.lastName}</p>
                )}
              </div>
            </div>
            <div className="pb-3 lg:pb-5">
              <label
                htmlFor="companyname"
                className="text-[#455468] text-[1rem] block pb-1"
              >
                Company name <span className="text-[#1B4DFF]">*</span>
              </label>
              <input
                type="text"
                name="companyname"
                id="companyname"
                placeholder="Enter company name"
                value={companyName}
                className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                onChange={handleCompanyNameChange}
              />
              {errors.companyName && (
                <p className="text-red-500 text-xs pt-1">
                  {errors.companyName}
                </p>
              )}
            </div>
            <div className="pb-3 lg:pb-6">
              <label
                htmlFor="email"
                className="text-[#455468] text-[1rem] block pb-1"
              >
                Email <span className="text-[#1B4DFF]">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter email address"
                value={email}
                className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                onChange={handleEmailChange}
              />
              {errors.email && (
                <p className="text-red-500 text-xs pt-1">{errors.email}</p>
              )}
            </div>
            <h2 className="text-[#455468] text-[1rem]">
              What product are you interested in?{" "}
              <span className="text-[#1B4DFF]">*</span>
            </h2>
            {errors.product && (
              <p className="text-red-500 text-xs pt-1">{errors.product}</p>
            )}
            <div className="lg:flex lg:justify-between lg:items-center lg:w-[83%]">
              <div className="">
                <label className="flex justify-start items-center gap-4 pt-4 lg:pt-5">
                  <input
                    type="checkbox"
                    style={{
                      width: 16,
                      height: 16,
                      border: "1px solid #D7DFE9",
                      borderRadius: 10,
                    }}
                    checked={product.includes("ISO 27001")}
                    onChange={(e) =>
                      handleCheckboxChange("ISO 27001", e.target.checked)
                    }
                  />
                  <span>
                    <img src={prodoctIso27001} alt="Iso 27001" />
                  </span>
                  <span className="text-[#455468] text-[1rem]">ISO 27001</span>
                </label>
                <label className="flex justify-start items-center gap-4 pt-4 lg:pt-5">
                  <input
                    type="checkbox"
                    style={{
                      width: 16,
                      height: 16,
                      border: "1px solid #D7DFE9",
                      borderRadius: 10,
                    }}
                    checked={product.includes("PCI DSS")}
                    onChange={(e) =>
                      handleCheckboxChange("PCI DSS", e.target.checked)
                    }
                  />
                  <span>
                    <img src={productPci} alt="PCI DSS" />
                  </span>
                  <span className="text-[#455468] text-[1rem]">PCI DSS</span>
                </label>
                <label className="flex justify-start items-center gap-4 pt-4 lg:pt-5">
                  <input
                    type="checkbox"
                    style={{
                      width: 16,
                      height: 16,
                      border: "1px solid #D7DFE9",
                      borderRadius: 10,
                    }}
                    checked={product.includes("SOC 2")}
                    onChange={(e) =>
                      handleCheckboxChange("SOC 2", e.target.checked)
                    }
                  />
                  <span>
                    <img src={productSoc2} alt="SOC 2" />
                  </span>
                  <span className="text-[#455468] text-[1rem]">SOC 2</span>
                </label>
              </div>
              <div className="">
                <label className="flex justify-start items-center gap-4 pt-4 lg:pt-5">
                  <input
                    type="checkbox"
                    style={{
                      width: 16,
                      height: 16,
                      border: "1px solid #D7DFE9",
                      borderRadius: 10,
                    }}
                    checked={product.includes("IMS")}
                    onChange={(e) =>
                      handleCheckboxChange("IMS", e.target.checked)
                    }
                  />
                  <span>
                    <img src={productIms} alt="IMS" />
                  </span>
                  <span className="text-[#455468] text-[1rem]">IMS</span>
                </label>
                <label className="flex justify-start items-center gap-4 pt-4 lg:pt-5">
                  <input
                    type="checkbox"
                    style={{
                      width: 16,
                      height: 16,
                      border: "1px solid #D7DFE9",
                      borderRadius: 10,
                    }}
                    checked={product.includes("ISO 20000")}
                    onChange={(e) =>
                      handleCheckboxChange("ISO 20000", e.target.checked)
                    }
                  />
                  <span>
                    <img src={productIso200000} alt="ISO 20000" />
                  </span>
                  <span className="text-[#455468] text-[1rem]">ISO 20000</span>
                </label>
                <label className="flex justify-start items-center gap-4 pt-4 lg:pt-5">
                  <input
                    type="checkbox"
                    style={{
                      width: 16,
                      height: 16,
                      border: "1px solid #D7DFE9",
                      borderRadius: 10,
                    }}
                    checked={product.includes("ISO 22301")}
                    onChange={(e) =>
                      handleCheckboxChange("ISO 22301", e.target.checked)
                    }
                  />
                  <span>
                    <img src={productIso22301} alt="ISO 22301" />
                  </span>
                  <span className="text-[#455468] text-[1rem]">ISO 22301</span>
                </label>
              </div>
            </div>
            <div className="pt-4 lg:pt-6">
              {loading ? (
                <Button
                  disabled={loading}
                  onClick={bookDemo}
                  variant="contained"
                  size="large"
                  icon={false}
                >
                  <ScaleLoader
                    color={"#ffffff"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </Button>
              ) : (
                <Button
                  disabled={loading}
                  onClick={bookDemo}
                  variant="contained"
                  size="large"
                  icon
                  iconType="arrowWhite"
                >
                  Schedule your demo
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="pt-[48px] bg-[#040B4E]">
        <div className="flex justify-center items-center flex-col">
          <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[300px] lg:370px h-[44px] lg:h-[48px] rounded-full py-[12px] px-[15px]">
            <span className="pr-2">
              <img src={homeRocketIcon} alt="rocket" />
            </span>
            <h3 className="text-[0.75rem] text-[#93AAFD]">
              Begin your journey to compliance efficiency
            </h3>
          </div>
          <h2 className="text-[3rem] lg:text-[3.5rem] font-bold text-[#FFFFFF] text-center px-5 leading-[56px] lg:leading-[64px] pt-3">
            Elevate your <br className="lg:hidden" />
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              security
            </span>{" "}
            and <br />
            <span className="gradient-text bg-gradient-to-l-custom bg-clip-text text-transparent">
              compliance
            </span>{" "}
            posture with <br className="lg:hidden" /> Vegeel
          </h2>
          <p className="text-[#FAFAFA] text-[1rem] leading-[28px] text-center pt-4">
            Join the ranks of forward-thinking <br className="lg:hidden" />{" "}
            organizations that trust Vegeel to fortify <br /> their security and
            compliance efforts.
          </p>
          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 px-7 w-full lg:w-[40%]">
            <Button
              onClick={openBookADemoModal}
              variant="contained"
              size="large"
              icon
            >
              Request demo
            </Button>
            <Button
              onClick={openContactSalesModal}
              variant="plain"
              size="large"
              icon
              iconType="arrow"
            >
              Contact sales
            </Button>
          </div>

          <div className="pt-14 w-full h-full lg:hidden">
            <img
              src={homeDashboardImg}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
          <div className="pt-14 w-full h-full hidden lg:flex lg:px-[4.5rem]">
            <img
              src={homeDashboardImgTwo}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
