import React from "react";
import { ContactSalesCard } from "../../pages/Home";
import speakToUs from "../../assets/icons/speak-to-us-icon.svg";
import emailUs from "../../assets/icons/email-us-icon.svg";
import getDemo from "../../assets/icons/get-demo-icon.svg";
import contactSaleImg from "../../assets/images/contact-sale-img.svg";
import { ReactComponent as Times } from "../../assets/icons/times-black.svg";
import CustomIcon from "../CustomIcon";
import { useModalContext } from "../../context";

const ContactSales = () => {
  const { openBookADemoModal, closeContactSalesModal } = useModalContext();

  return (
    <div className="w-full h-full lg:w-[926px] lg:h-[544.81px] lg:rounded-[15px] bg-[#EDF1FF] p-[20px] lg:py-[40px] lg:px-[45px] overflow-auto lg:overflow-hidden">
      <div className="flex justify-between items-center pb-[8px]">
        <h2 className="text-[#060B59] text-[1.75rem] font-bold lg:pb-[20px]">
          Contact sales
        </h2>

        <div
          onClick={closeContactSalesModal}
          className="flex justify-end items-center cursor-pointer"
        >
          <CustomIcon SvgIcon={Times} size={24} />
        </div>
      </div>
      <div className="lg:flex lg:justify-between lg:items-start">
        <div className="w-[335px] h-[450px] lg:w-[47%] lg:h-full">
          <div className="">
            <p className="text-[#455468] text-[1rem] leading-[28px]">
              Tell us what your business needs and we will find the best
              solution for you. We’d be happy to walk you through a demo or
              discuss how we can simplify and automate your compliance process.{" "}
            </p>
          </div>
          <div className="">
            <div className="pt-6 w-full h-full">
              <img
                src={contactSaleImg}
                alt="Contact Sale"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>

        <div className="w-[335px] h-[450px] lg:h-full pt-4 lg:pt-0 flex justify-start items-start flex-col gap-4 lg:w-[48%]">
          <h1 className="text-[#060B59] text-[1.125rem] font-medium">
            You can reach us via these channels.
          </h1>

          <ContactSalesCard
            image={speakToUs}
            name="Speak with us"
            detail="+234-901 495 7203"
            type="phone"
            phoneNo={"+2349014957203"}
          />
          <ContactSalesCard
            image={emailUs}
            name="Email us"
            detail="hello@vegeel.com"
            type="email"
            phoneNo={""}
          />
          <ContactSalesCard
            image={getDemo}
            name="Get demo"
            detail="Schedule now"
            type="demo"
            phoneNo={""}
            onClick={openBookADemoModal}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactSales;
