import React from "react";

export interface securityProps {
  icon: string;
  title: string;
  text: string;
}

function index({ icon, text, title }: securityProps) {
  return (
    <div>
      <div className="w-[343px] rounded-[10px] p-[20px] bg-[#FAFAFA] lg:h-[350px]">
        <div className="w-[44px] h-[44px] rounded-[10px] bg-main_color p-[10px]">
          <img src={icon} alt="veegeel icon" title="veegeel icon" />
        </div>
        <div className="pt-[16px] lg:pt-[24px] md:pb-[12px] pb-[8px]">
          <h1 className="font-[500] text-[20px] lg:text-[24px] md:leading-[36px] leading-[34px] text-[#060B59] lg:pr-6">
            {title}
          </h1>
        </div>
        <div className="text-[16px] lgtext-[18px] lg:leading-[30px] leading-[28px] text-[#455468]">
          <h1>{text}</h1>
        </div>
      </div>
    </div>
  );
}

export default index;
