import React from "react";

export default function Cookiestracking() {
  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <h1 className="font-[700] text-[28px] text-dark_color">
          Cookies and Tracking Technologies
        </h1>
        <p className="text-dark_color text-[16px]">
          At Vegeel Technology Limited, we use cookies and other tracking
          technologies to enhance your experience on our website, analyze site
          usage, and assist in our marketing efforts. Here detailed information
          about the types of cookies we use, their purposes, and how you can
          control their use, in accordance with the Nigeria Data Protection
          Regulation [NDPR] and other applicable laws.
        </p>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Types of Cookies Used
        </h2>
        <p className="text-dark_color text-[16px]">
          Cookies are small text files that are placed on your device by
          websites you visit. They are widelyused to make websites work more
          efficiently, as well as to provide information to the site owners.
          Weuse various types of cookies for different purposes:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Essential Cookies</p>
            <ul className="list-disc">
              <li>
                Purpose: These cookies are necessary for the basic functionality
                of our website. They enable core features such as security,
                network management, and accessibility.
              </li>
              <li>
                Examples: Session cookies that maintain your login status as you
                navigate through different pages of the site, security cookies
                that help detect and prevent fraudulent activities.
              </li>
              <li>
                Duration: Essential cookies are usually session-based and expire
                when you close your browser.
              </li>
            </ul>
          </li>
          <li>
            <p>Performance and Analytics Cookies</p>
            <ul className="list-disc">
              <li>
                Purpose: These cookies collect information about how visitors
                use our website, such as which pages are visited most often and
                any error messages encountered. This helps us improve the
                performance and user experience of our site.
              </li>
              <li>
                Examples: Cookies set by analytics services like Google
                Analytics to track page views, visitor behavior, and site
                performance.
              </li>
              <li>
                Duration: Performance cookies can be session-based or
                persistent, depending on their purpose.
              </li>
            </ul>
          </li>
          <li>
            <p>Functional Cookies</p>
            <ul className="list-disc">
              <li>
                Purpose: Functional cookies enable our website to remember
                choices you make [such as your username, language, or region]
                and provide enhanced, more personalized features.
              </li>
              <li>
                Examples: Cookies that remember your language preference or
                allow you to watch videos on our site.
              </li>
              <li>
                Duration: These cookies can be session-based or persistent,
                depending on the function they support.
              </li>
            </ul>
          </li>
          <li>
            <p>Targeting and Advertising Cookies</p>
            <ul>
              <li>
                Purpose: These cookies are used to deliver content and
                advertisements that are more relevant to your interests. They
                also help us measure the effectiveness of our marketing
                campaigns.
              </li>
              <li>
                Examples: Cookies set by advertising networks to track your
                browsing habits across different websites and build a profile of
                your interests.
              </li>
              <li>
                Duration: Targeting and advertising cookies are typically
                persistent and remain on your device for a set period unless you
                delete them.
              </li>
            </ul>
          </li>
          <li>
            <p>Third-Party Cookies</p>
            <ul className="list-disc">
              <li>
                Purpose: Our website may also include third-party cookies from
                partners and service providers who perform services on our
                behalf or integrate their features into our site.
              </li>
              <li>
                Examples: Social media cookies that allow you to share content
                directly on social networks, cookies from embedded videos hosted
                on platforms like YouTube.
              </li>
              <li>
                Duration: The duration of third-party cookies varies depending
                on the specific cookie and its purpose.
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="font-bold text-dark_color text-[20px] ">
          User Control Over Cookies
        </h2>
        <p className="text-dark_color text-[16px]">
          You have the right to control the use of cookies and other tracking
          technologies. Here are the ways you can manage your cookie
          preferences:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Browser Settings</p>
            <ul className="list-disc">
              <li>
                Managing Cookies: Most web browsers allow you to control cookies
                through their settings. You can set your browser to block or
                delete cookies, or to notify you when a cookie is being placed
                on your device.
              </li>
              <li>
                Instructions: The steps to manage cookies vary depending on the
                browser you use. Refer to the help section or settings menu of
                your browser for detailed instructions. Common browsers include
                Google Chrome, Mozilla Firefox, Safari, and Microsoft Edge.
              </li>
            </ul>
          </li>
          <li>
            <p>Cookie Consent Tools</p>
            <ul className="list-disc">
              <li>
                Our Website: When you first visit our website, you will see a
                cookie consent banner that provides information about our use of
                cookies. You can choose to accept all cookies, reject
                non-essential cookies, or customize your cookie preferences.
              </li>
              <li>
                Changing Preferences: You can update your cookie preferences at
                any time by accessing the cookie settings link available on our
                website. This allows you to change your consent or withdraw it
                entirely.
              </li>
            </ul>
          </li>
          <li>
            <p>Opt-Out Mechanisms</p>
            <ul className="list-disc">
              <li>
                Analytics and Advertising: Many analytics and advertising
                services offer opt-out mechanisms that allow you to prevent your
                data from being used for certain purposes. For example, you can
                opt-out of Google Analytics by installing the Google Analytics
                Opt-Out Browser Add-on.
              </li>
              <li>
                Industry Tools: Use industry opt-out tools such as the Network
                Advertising Initiative [NAI] Opt-Out Tool or the Digital
                Advertising Alliance [DAA] Consumer Choice Page to control the
                use of cookies by participating companies.
              </li>
            </ul>
          </li>
          <li>
            <p>Mobile Device Settings</p>
            <ul className="list-disc">
              <li>
                Ad Preferences: For mobile devices, you can adjust your ad
                preferences through your device settings. Look for options
                related to ad tracking or personalized advertising, typically
                found in the privacy or security settings of your device.
              </li>
              <li>
                App-Specific Controls: Some mobile apps provide their own
                settings to manage cookie and tracking preferences within the
                app.
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Impact of Disabling Cookies
        </h2>
        <p className="text-dark_color text-[16px]">
          Please note that disabling cookies may affect the functionality and
          performance of our website. Essential cookies are necessary for the
          site to operate properly, and blocking them may prevent you from
          accessing certain features or services. Performance, functional, and
          targeting cookies enhance your experience, and opting out of them may
          result in a less personalized experience.
        </p>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Your Rights Under NDPR
        </h2>
        <p className="text-dark_color text-[16px]">
          Under the Nigeria Data Protection Regulation [NDPR], you have the
          following rights related to cookies and tracking technologies:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            Right to be Informed: You have the right to be informed about the
            types of cookies we use and their purposes.
          </li>
          <li>
            Right to Consent: You have the right to give or withdraw consent for
            the use of non-essential cookies.
          </li>
          <li>
            Right to Access: You have the right to request information about the
            cookies and tracking technologies used on our site and how your data
            is processed.
          </li>
          <li>
            Right to Object: You have the right to object to the processing of
            your data for specific purposes, such as targeted advertising or
            analytics.
          </li>
        </ol>
      </div>
    </div>
  );
}
