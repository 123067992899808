import React from "react";
import bluecheckIcon from "../../../assets/icons/blue-checkbox-icon.svg";

interface CardProps {
  majorIcon: string;
  header: string;
  subHead1: string;
  subHead2: string;
  subtext1: string;
  list1: string;
  list2: string;
}

export const Card: React.FC<CardProps> = ({
  majorIcon,
  header,
  subHead1,
  subHead2,
  subtext1,
  list1,
  list2,
}) => {
  return (
    <div className="px-3 w-full">
      <div className="h-[30.875rem] flex flex-col gap-[1rem] bg-[#FAFAFA] rounded-[0.9375rem]   py-[1.875rem] px-[1.25rem]">
        <div className="flex items-center gap-3 justify-start w-[18.938rem] h-[3.125rem] ">
          <div>
            <img src={majorIcon} alt="shieldIcon" />
          </div>
          <div className="text-{#111111}] text-[1.25rem] font-[500]">
            {header}
          </div>
        </div>

        <div className="h-[1px] bg-[#455468] w-full"></div>
        <div>
          <p className="text-[1.125rem] text-[#667085] font-[500]">
            {subHead1}
          </p>
        </div>

        <div className="text-[#111111] font-[400] text-[1rem] leading-[1.75rem]">
          {subtext1}
        </div>

        <div className="h-[1px] bg-[#455468] w-full"></div>
        <div>
          <p className="text-[1.125rem] text-[#667085] font-[500]">
            {subHead2}
          </p>
        </div>

        <div>
          <div className="flex items-center gap-3 justify-start w-[18.938rem] h-[3.125rem] ">
            <div>
              <img src={bluecheckIcon} alt="shieldIcon" />
            </div>
            <div className="text-{#111111}] text-[1rem] font-[400]">
              {list1}
            </div>
          </div>

          <div className="flex items-center gap-3 justify-start w-[18.938rem] h-[3.125rem] ">
            <div>
              <img src={bluecheckIcon} alt="shieldIcon" />
            </div>
            <div className="text-{#111111}] text-[1rem] font-[400]">
              {list2}
            </div>
          </div>
          <div className="h-[1px] bg-[#455468] w-full"></div>
        </div>
      </div>
    </div>
  );
};
