import React from "react";

export interface AboutProps {
  title: string;
  about: string;
}

function AboutCard({ title, about }: AboutProps) {
  return (
    <div>
      <div className=" rounded-[15px] border p-[20px] lg:w-[462px] bg-white">
        <h1 className="font-[500] text-[20px] lg:text-[24px] lg:leading-[36px] leading-[34px]">
          {title}
        </h1>
        <h1 className="font-[400] lg:pt-[12px] text-[16px] lg:text-[18px] lg:leading-[30px] leading-[28px] text-[#455468]">
          {about}
        </h1>
      </div>
    </div>
  );
}

export default AboutCard;
