import { useEffect, useState } from "react";
import arrowLeft from "../../assets/icons/blog-detail-arrow-left.svg";
import avatarOne, {
  ReactComponent as Avatar,
} from "../../assets/icons/blog-detail-avatar.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/blog-detail-link-icon.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/blog-detail-linkedin-icon.svg";
import { ReactComponent as Twitter } from "../../assets/icons/blog-detail-x-icon.svg";
import blogMainImg from "../../assets/images/Modern Geometrical Computer Security Instagram Post _20240916_170651_0000.png";
import CustomIcon from "../../components/CustomIcon";
import { BlogPost } from "../Blog/All";
import { useNavigate } from "react-router-dom";
import copyLinkIcon from "../../assets/icons/blog-detail-desktop-link-angled.svg";
import arrowUpRight from "../../assets/icons/blog-details-up-right.svg";
import { ReactComponent as Times } from "../../assets/icons/times.svg";
import { useMenuContext } from "../../context";

export default function BlogDetailNine() {
  const navigate = useNavigate();
  const { closeDropdown } = useMenuContext();
  const linkValue = "https://www.vegeel.com/";
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = () => {
    navigate(-1);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(linkValue);
      setShowCopyMessage(true);
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div onClick={() => closeDropdown()}>
      <div className=" pt-[2rem] lg:pt-[6rem] px-[18px] lg:px-[5rem] flex justify-start lg:justify-center items-start lg:items-start flex-col">
        <div
          onClick={handleNavigate}
          className="gap-2 text-[1rem] text-[#060B59] flex justify-center items-center font-medium hero-blog-detail-go-back-border py-[8px] px-[13px] lg:px-[16px] rounded-[6px] cursor-pointer"
        >
          <span>
            <img src={arrowLeft} alt="icon" />
          </span>
          Back to blog
        </div>
        <div className="pt-[32px] lg:px-[3.5rem] ">
          <h1 className="text-[#060B59] text-[2.375rem] lg:text-[3.75rem] font-bold pb-[16px] lg:pb-[24px] leading-[60px]">
            HOW NON-COMPLIANCE WITH NDPA CAN RUIN YOUR BUSINESS
          </h1>
          <div className="lg:hidden">
            <div className="pt-[16px] flex justify-start items-center gap-2">
              <CustomIcon SvgIcon={Avatar} size={28} />
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <div className="flex justify-between items-center pt-[16px]">
              <h1 className="text-[#455468] text-[1rem]">
                Monday, September 16, 2024
              </h1>
              <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
                Framework
              </h2>
            </div>
          </div>
          <div className="hidden lg:flex lg:justify-start lg:items-center lg:gap-3">
            <div className="flex justify-start items-center gap-2">
              <div className="h-[32px] w-[32px]">
                <img src={avatarOne} alt="avater" className="w-full h-full" />
              </div>
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>
            <h1 className="text-[#455468] text-[1rem]">
              Monday, September 16, 2024
            </h1>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>

            <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
              Framework
            </h2>
          </div>
        </div>
        <div className="pt-[16px] w-full lg:px-[3.5rem]">
          <img
            src={blogMainImg}
            alt="blog-main"
            className="rounded-[15px] w-full h-full"
          />
        </div>

        <div className="lg:py-[48px] lg:flex lg:justify-between lg:items-start lg:px-[3.5rem]">
          <div className="lg:w-[28%]">
            <div className="pt-[32px] ">
              <h1 className="text-[#060B59] font-bold text-[1.125rem] lg:text-[1.25rem] pb-[8px] lg:pb-[16px]">
                Contents
              </h1>

              <div className="flex">
                <div className="relative">
                  <div className="absolute bg-[#E5EAFC] w-1 h-full rounded-[6px]">
                    <div
                      className={`absolute left-0 bg-blue-500 w-1 h-[50%] rounded-[6px]`}
                    ></div>
                  </div>
                </div>

                <div className="pl-[12px]">
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium  mb-[8px] lg:w-full">
                    HOW NON-COMPLIANCE WITH NDPA CAN RUIN YOUR BUSINESS
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    Benefits of NDPA Certification
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    Failure to comply with NDPA regulations can result in:
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    Who Needs NDPA Certification?
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    Best Practices for NDPA Compliance
                  </h1>
                </div>
              </div>
            </div>

            <div className="hidden lg:flex lg:pt-[64px] lg:flex-col lg:justify-start lg:items-start">
              <h1 className="text-[#060B59] text-[1.25rem] pb-[1rem]">
                Share article
              </h1>

              <h1 className="text-[#1B4DFF] text-[1rem] font-medium flex justify-start items-center gap-2 cursor-pointer">
                LinkedIn{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1 className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start items-center gap-2 cursor-pointer">
                Twitter{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1
                onClick={copyLink}
                className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start  items-center gap-2 cursor-pointer"
              >
                Copy link{" "}
                <span>
                  <img src={copyLinkIcon} alt="icon" />
                </span>
              </h1>
            </div>
          </div>

          <div className="pt-[32px] lg:px-[rem] lg:w-[68%]">
            <h1 className="text-[#111111] text-[2rem] lg:text-[2.25rem] font-bold leading-[36px] lg:leading-[50px] pb-[12px] lg:pb-[20px]">
              HOW NON-COMPLIANCE WITH NDPA CAN RUIN YOUR BUSINESS
            </h1>
            <p className="text-[#111111] text-[1rem] lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide">
              The Nigeria Data Protection Act (NDPA) is a comprehensive
              framework that ensures the secure collection, processing, and
              storage of personal data. To demonstrate your commitment to data
              protection and compliance, obtaining NDPA certification is
              essential.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  pt-[12px] lg:pt-[20px] tracking-wide">
              <strong className="block"> Benefits of NDPA Certification</strong>
              1. Demonstrates Compliance: NDPA certification shows that your
              organization adheres to Nigeria's data protection regulations,
              avoiding costly fines and penalties.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              2. Enhances Credibility: Certification builds trust with clients,
              customers, and partners, showcasing your dedication to data
              security.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              3. Protects Sensitive Data: NDPA certification ensures you have
              the necessary measures in place to safeguard sensitive information
              from breaches and cyber attacks.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              4. Boosts Business Opportunities: Certification can be a
              competitive advantage, opening up new business opportunities and
              partnerships.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  pb-[12px] lg:pb-[20px] tracking-wide">
              5. Ensures Continuous Improvement: The certification process
              encourages ongoing data protection training and awareness, driving
              continuous improvement.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide">
              <strong className="block">Who Needs NDPA Certification?</strong>
              1. Organizations handling personal data of Nigerian citizens
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              2. Businesses operating in Nigeria or targeting Nigerian customers
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              3. Companies seeking to demonstrate data protection compliance
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  pb-[12px] lg:pb-[20px] tracking-wide">
              4. Individuals looking to enhance their career prospects in data
              protection
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide">
              <strong className="block">
                {" "}
                Best Practices for NDPA Compliance
              </strong>
              1. Conduct Data Audit: Identify personal data collected,
              processed, and stored.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              2. Implement Data Protection Measures: Encrypt data, access
              controls, and incident response plans.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              3. Train Employees: Educate employees on data protection best
              practices.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide">
              4. Designate Data Protection Officer: Appoint a DPO to oversee
              data protection efforts.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  pb-[12px] lg:pb-[20px] tracking-wide">
              5. Regularly Review and Update: Continuously monitor and update
              data protection measures.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              All this process can be done easily by automation with Vegeel's
              Software.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              NDPA certification is a must-have for any organization handling
              personal data. It demonstrates compliance, enhances credibility,
              protects sensitive data, boosts business opportunities, and
              ensures continuous improvement. Failure to comply can result in
              severe consequences, including fines, legal action, reputational
              damage, business disruption, and data breaches.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] lg:pt-[20px] tracking-wide">
              Don't wait, get your NDPA certification today and safeguard your
              organization's future. Schedule a demo now:
              <a
                className="text-blue-600"
                target="_blank"
                rel="noreferrer"
                href="https://tidycal.com/36kgx91/vegeel-demo"
              >
                Vegeel demo
              </a>
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              For further inquiries, contact:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="mailto:hello@vegeel.com"
              >
                hello@vegeel.com
              </a>{" "}
              <br />
              Follow Us <br />
              Twitter:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://x.com/Vegeel_Tech"
              >
                Vegeel_Tech
              </a>{" "}
              <br />
              Linkedin:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.linkedin.com/company/vegeel/"
              >
                Vegeel
              </a>{" "}
              <br />
              Instagram:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.instagram.com/vegeelcompliance"
              >
                Vegeelcompliance
              </a>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="py-[48px] flex justify-center items-center gap-[48px] w-full lg:hidden">
          <h1 className="text-[1.125rem] text-[#060B59] font-medium">
            Share article
          </h1>
          <div className="flex justify-start items-center gap-[24px]">
            <CustomIcon SvgIcon={Twitter} size={24} />
            <CustomIcon SvgIcon={Linkedin} size={24} />
            <CustomIcon SvgIcon={LinkIcon} size={24} />
          </div>
        </div>
      </div>
      <div className="py-[48px] px-[18px] bg-[#EDF1FF] flex justify-center items-center lg:items-start flex-col lg:px-[8.5rem]">
        <h1 className="pb-[32px] text-[#060B59] font-bold text-[1.75rem]">
          Related resources
        </h1>
        <div className="lg:flex lg:justify-center lg:items-start lg:flex-col">
          <BlogPost
            blogImg={
              "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750,g_auto/v1726117562/b5695e9a7e8a0db8c11645da091f6b0e_nedjal.jpg"
            }
            blogHeader="Understanding ISO 27001: The Ultimate Guide to Cybersecurity Compliance"
            blogDate="Wednesday, September 11, 2024"
            blogCategory="Framework"
            blogDetail="In today's digital age, cybersecurity is a top concern for businesses of all sizes. With the increasing number of cyber threats and data breaches, companies need to take proactive measures to protect their sensitive information."
            blogDesktopPaddingTop="50"
            navigateToBlogPost="blog-details/07"
          />
        </div>
      </div>
      {showCopyMessage && (
        <div className="h-[10vh] w-[100vw] fixed top-0 bg-green-500 z-20 flex justify-between items-center p-[20px] px-[40px]">
          <h1 className="text-white font-medium text-[1rem]">
            ✔ Copied to clipboard
          </h1>
          <div onClick={() => setShowCopyMessage(false)}>
            <CustomIcon SvgIcon={Times} size={24} />
          </div>
        </div>
      )}
    </div>
  );
}
