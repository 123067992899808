import { ReactComponent as Iso27001 } from "../assets/images/Iso27001.svg";
import { ReactComponent as Iso20000 } from "../assets/images/iso20000.svg";
import { ReactComponent as Pcidss } from "../assets/images/Pcidss.svg";
import { ReactComponent as Ims } from "../assets/icons/ims.svg";
import { ReactComponent as Soc2 } from "../assets/images/soc-2.svg";
import { ReactComponent as Audit } from "../assets/images/audit.svg";
import { ReactComponent as Iso22301 } from "../assets/images/iso_22301.svg";
import { ReactComponent as People } from "../assets/icons/people.svg";
import { ReactComponent as Security } from "../assets/icons/security.svg";
import { ReactComponent as Blog } from "../assets/icons/blog.svg";
import { ReactComponent as Faq } from "../assets/icons/faq.svg";

interface PathListItem {
  label: string;
  img: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  desc: string;
  path: string;
}

interface NavLink {
  label: string;
  path: string;
  pathList?: PathListItem[] | null;
}

export const navlinks: NavLink[] = [
  {
    label: "Solutions",
    path: "/solutions",
    pathList: [
      {
        label: "ISO 27001",
        img: Iso27001,
        desc: "Manage ISO 27001 compliance and certification audits.",
        path: "/solutions/iso-27001",
      },
      // {
      //   label: "ISO 20000",
      //   img: Iso20000,
      //   desc: "Manage, monitor ISO 20000 compliance and certification audits.",
      //   path: "#",
      // },
      {
        label: "PCI DSS",
        img: Pcidss,
        desc: "Efficiently monitor compliance while mapping all PCI DSS controls.",
        path: "/solutions/pci-dss",
      },
      // {
      //   label: "IMS",
      //   img: Ims,
      //   desc: "Streamline your operations with our IMS for efficiency and compliance.",
      //   path: "#",
      // },
      // {
      //   label: "SOC 2",
      //   img: Soc2,
      //   desc: "Closely monitor all SOC 2 trust services criteria.",
      //   path: "#",
      // },
      // {
      //   label: "Audit Hub",
      //   img: Audit,
      //   desc: "Create an audit plan, evaluate audit readiness and gain certification.",
      //   path: "#",
      // },
      // {
      //   label: "ISO 22301",
      //   img: Iso22301,
      //   desc: "Manage requirements of ISO 22301 and monitor compliance.",
      //   path: "#",
      // },
    ],
  },
  {
    label: "Company",
    path: "#",
    pathList: [
      {
        label: "About & Careers",
        img: People,
        desc: "Get to know about Vegeel’s story, vision, commitment and career opportunities.",
        path: "/about",
      },
      {
        label: "Security",
        img: Security,
        desc: "Checkout how Vegeel’s robust security structure keeps you secured.",
        path: "/security",
      },
    ],
  },
  {
    label: "Resources",
    path: "/resources",
    pathList: [
      {
        label: "Blogs",
        desc: "Checkout our latest blogs, press release and updates on Vegeel.",
        img: Blog,
        path: "/blogs",
      },
      {
        label: "FAQS",
        desc: "Get answers to our Frequently Asked Questions (FAQS).",
        img: Faq,
        path: "/faqs",
      },
    ],
  },
  {
    label: "Become a partner",
    path: "/become-a-partner",
  },
];
