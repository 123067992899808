import React from "react";
import {
  Commitment,
  Copyright,
  NewsLetter,
  Others,
  Product,
} from "../FotComponents";
import veggellogo from "../../../assets/images/vegeel-logo.svg";

export default function DesktopFooter() {
  return (
    <footer>
      <div className="bg-white text-sm flex flex-row gap-[2.125rem] py-[2.125rem] px-[1.25rem] border-t border-[#EAECF0] w-[90%] mx-auto">
        <Commitment icon={veggellogo} styler={"w-[25rem]"} />
        <section className="flex justify-evenly w-[25rem]">
          <Product />
          <Others />
        </section>
        <NewsLetter
          buttonSize={"small"}
          styler="flex-row"
          text="Get notifications about available resources, announcements, and features."
        />
      </div>
      <div className="py-5 lg:flex lg:justify-center lg:items-center">
        <Copyright />
      </div>
    </footer>
  );
}
