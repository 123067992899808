/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import folderIcon from "../../assets/icons/blog-folder-open.svg";
import mail from "../../assets/icons/mail-01.svg";
import All from "./All";
import Framework from "./Framework";
import RiskAssessment from "./RiskAssessment";
import CyberSecurity from "./CyberSecurity";
import NewsAndEvent from "./NewsAndEvents";
import Updates from "./Updates";
import Pagination from "../../components/Pagination";
import { useMenuContext } from "../../context";
import axios from "axios";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";

interface TabsProps {
  tab: number;
  switchTab: (tab: number) => void;
}

function BlogTabs({ tab, switchTab }: TabsProps) {
  return (
    <section className="flex lg:justify-evenly justify-between items-center overflow-x-scroll horizontal-scroll lg:w-[70%]">
      <div
        className={`${
          tab === 1
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(1)}
      >
        All
      </div>
      {/* <div
        className={`${
          tab === 2
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(2)}
      >
        Framework
      </div>
      <div
        className={`${
          tab === 3
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(3)}
      >
        Risk assessment
      </div>

      <div
        className={`${
          tab === 4
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(4)}
      >
        Cybersecurity
      </div>

      <div
        className={`${
          tab === 5
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(5)}
      >
        News & Events
      </div>

      <div
        className={`${
          tab === 6
            ? " text-[#1B4DFF] font-medium border-b-2 border-b-[#1B4DFF]"
            : "text-[#8897AE]"
        } p-1 text-[1.25rem] min-w-fit cursor-pointer mx-3 lg:mx-0`}
        onClick={() => switchTab(6)}
      >
        Updates
      </div> */}
    </section>
  );
}

export default function Blog() {
  const [tab, setTab] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 1;
  const { closeDropdown } = useMenuContext();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const targetDate = new Date(2024, 6, 22);

  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft: {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    } = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const updateCountdown = () => {
      const timeLeft = calculateTimeLeft();

      setDays(timeLeft.days || 0);
      setHours(timeLeft.hours || 0);
      setMinutes(timeLeft.minutes || 0);
      setSeconds(timeLeft.seconds || 0);
    };

    const timer = setInterval(updateCountdown, 1000);

    // Update immediately on mount
    updateCountdown();

    return () => clearInterval(timer);
  }, [targetDate, calculateTimeLeft]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState<string>("");

  useEffect(() => {
    if (emailError) {
      const timer = setTimeout(() => {
        setEmailError("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [emailError]);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleNewsLetter = async () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://email-transporter.onrender.com/news-letter/send",
        { email }
      );
      if (response.status === 200) {
        setEmail("");
        setEmailError("");
        toast.success("Newsletter subscription successful!🎉🎇");
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error("Network error. Please check your internet connection.");
      } else if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (emailError) setEmailError("");
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div onClick={closeDropdown}>
      <section className="py-[48px] lg:flex lg:justify-center lg:items-center lg:flex-col px-[18px] lg:pt-[5rem]">
        <h1 className="text-[1.75rem] text-[#060B59] font-bold pb-[32px]">
          Explore Vegeel’s blog
        </h1>

        <BlogTabs tab={tab} switchTab={setTab} />
        {tab === 1 ? (
          <All />
        ) : tab === 2 ? (
          <Framework />
        ) : tab === 3 ? (
          <RiskAssessment />
        ) : tab === 4 ? (
          <CyberSecurity />
        ) : tab === 5 ? (
          <NewsAndEvent />
        ) : (
          <Updates />
        )}
        <div className="">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </section>

      <div className="bg-[#000033] pt-[3rem] lg:py-[7rem] flex justify-center items-center flex-col">
        <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[163px] lg:w-[209px] h-[40px] lg:h-[48px] rounded-full py-[10px] pr-[20px] pl-[15px]">
          <span className="pr-2">
            <img src={folderIcon} alt="icon" />
          </span>
          <h3 className="text-[0.75rem] lg:text-[0.875rem] text-[#93AAFD]">
            Vegeel Resources
          </h3>
        </div>

        <div className="px-5 text-center text-[#FFFFFF] pt-4 lg:pt-[20px] flex justify-center items-center flex-col">
          <h1 className="text-[3rem] lg:text-[5rem] font-medium leading-[56px] lg:leading-[82px] px-3 lg:tracking-tight">
            Explore Vegeel for <br className="hidden lg:flex " /> enhanced{" "}
            <span className="gradient-text bg-gradient-to-l-custom bg-clip-text text-transparent">
              compliance.
            </span>
          </h1>
          <h2 className="text-[1rem] lg:text-[1.25rem] pt-4 lg:pt-[20px] leading-[28px]">
            Learn about everything from product information to viewpoints of the
            core team. Our resource center <br className="hidden lg:flex " />{" "}
            provides insights to more about our products and solutions.
          </h2>
        </div>

        <div className="px-2 text-center lg:w-[43%] pt-4 flex justify-center items-center flex-col mb-[30px] lg:mb-0">
          <h2 className="text-[1rem] pt-4 leading-[24px] text-[#FFFFFF]">
            Be the first to get updates and announcements from Vegeel.
          </h2>
          <div className="w-full lg:pt-[12px] lg:flex lg:justify-between lg:items-center">
            <div className="pt-[8px] lg:pt-0 lg px-8 lg:px-0 w-full lg:w-[70%] relative">
              <input
                type="text"
                className="outline-none bg-[#ffffff] rounded-[6px] h-[48px] w-full pl-12"
                placeholder="Enter your email"
                onChange={handleEmailChange}
                value={email}
              />

              <div className="flex justify-start items-center gap-2 absolute top-5 lg:top-3 left-10 lg:left-3">
                <div className="">
                  <img src={mail} alt="icon" />
                </div>
                <h2 className="text-[#D7DFE9]">|</h2>
              </div>
            </div>

            <div className="pt-[16px] lg:pt-0 px-8 lg:px-0 w-full lg:w-[25%]">
              {/* <Button variant="contained" size="large" icon={false}>
                Subscribe
              </Button> */}

              {loading ? (
                <Button
                  disabled={loading}
                  variant="contained"
                  size="large"
                  icon={false}
                >
                  <ScaleLoader
                    color={"#ffffff"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </Button>
              ) : (
                <Button
                  onClick={handleNewsLetter}
                  size={"large"}
                  variant={"contained"}
                  icon
                  children={"Subscribe"}
                />
              )}
            </div>
          </div>
          <div className="w-full lg:flex lg:justify-start lg:items-center">
            {emailError && (
              <p className="text-red-500 text-sm mt-2">{emailError}</p>
            )}
          </div>
        </div>

        {/* <div className="w-[90%] lg:w-[85%] bg-[#D4D4D41A] my-[30px] lg:mt-[50px] p-[15px] lg:p-[30px] rounded-[15px] lg:flex lg:justify-between lg:items-start lg:flex-row-reverse">
          <div className="lg:w-[50%]">
            <img src={blogImg} alt="lunch-Img" className="w-full h-full" />
          </div>

          <div className="pt-[24px] lg:pt-0 lg:w-[40%] lg:flex lg:justify-start lg:items-start lg:flex-col">
            <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[143px] lg:w-[186px] h-[40px] lg:h-[48px] rounded-full">
              <span className="pr-2">
                <img src={blogstar} alt="star" />
              </span>
              <h3 className="text-[0.75rem] lg:text-[0.875rem] text-[#93AAFD]">
                Featured post
              </h3>
            </div>
            <h1 className="text-[1.75rem] lg:text-[2.25rem] font-bold blog-gradient-text pt-[24px] lg:pt-[28px] leading-[36px] lg:leading-[50px]">
              Vegeel’s official launch coming soon!!!
            </h1>

            <div className="flex items-center justify-center pt-[24px] lg:pt-[28px]">
              <div className="flex justify-start items-center gap-2 text-center text-white">
                <div className="flex flex-col items-center w-[57.04px] lg:w-[93.75px] h-[62.9px] lg:h-[102.78px] bg-[#D4D4D41A] gradient-border rounded-[5.28px] lg:rounded-[8.68px]">
                  <span className="text-[1.760625rem] lg:text-[2.89375rem] font-medium text-[#93AAFD]">
                    {days}
                  </span>
                  <span className="text-[0.528125rem] lg:text-[0.868125rem] font-medium text-[#93AAFD]">
                    days
                  </span>
                </div>

                <h1 className="text-[1.760625rem] lg:text-[2.89375rem] text-[#93AAFD] font-medium">
                  :
                </h1>
                <div className="flex flex-col items-center w-[57.04px] lg:w-[93.75px] h-[62.9px] lg:h-[102.78px] bg-[#D4D4D41A] gradient-border rounded-[5.28px] lg:rounded-[8.68px]">
                  <span className="text-[1.760625rem] lg:text-[2.89375rem] font-medium text-[#93AAFD]">
                    {hours}
                  </span>
                  <span className="text-[0.528125rem] lg:text-[0.868125rem] font-medium text-[#93AAFD]">
                    hours
                  </span>
                </div>
                <h1 className="text-[1.760625rem] lg:text-[2.89375rem] text-[#93AAFD] font-medium">
                  :
                </h1>
                <div className="flex flex-col items-center w-[57.04px] lg:w-[93.75px] h-[62.9px] lg:h-[102.78px] bg-[#D4D4D41A] gradient-border rounded-[5.28px] lg:rounded-[8.68px]">
                  <span className="text-[1.760625rem] lg:text-[2.89375rem] font-medium text-[#93AAFD]">
                    {minutes}
                  </span>
                  <span className="text-[0.528125rem] lg:text-[0.868125rem] font-medium text-[#93AAFD]">
                    minutes
                  </span>
                </div>
                <h1 className="text-[1.760625rem] lg:text-[2.89375rem] text-[#93AAFD] font-medium">
                  :
                </h1>
                <div className="flex flex-col items-center w-[57.04px] lg:w-[93.75px] h-[62.9px] lg:h-[102.78px] bg-[#D4D4D41A] gradient-border rounded-[5.28px] lg:rounded-[8.68px]">
                  <span className="text-[1.760625rem] lg:text-[2.89375rem] font-medium text-[#93AAFD]">
                    {seconds}
                  </span>
                  <span className="text-[0.528125rem] lg:text-[0.868125rem] font-medium text-[#93AAFD]">
                    seconds
                  </span>
                </div>
              </div>
            </div>

            <div className="pt-[24px] lg:pt-[32px] flex justify-center items-center lg:justify-start lg:w-[50%]">
              <button className="w-[80%] border border-[#102e99] py-[10px] px-[20px] rounded-[6px] flex justify-center items-center gap-2">
                <span className="text-[1rem] blog-gradient-text font-bold">
                  Read more
                </span>{" "}
                <span>
                  <img src={blogArrow} alt="icon" />
                </span>
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
