import React, { useState } from "react";
import faqPlus from "../../../assets/icons/faq-plus.svg";
import faqdash from "../../../assets/icons/faq-dash.svg";

export interface FaqItemProps {
  question: string;
  answer: string;
}

export const FaqItem = ({ question, answer }: FaqItemProps) => {
  const [showFaq, setShowFaq] = useState(false);

  const handleClick = () => {
    setShowFaq(!showFaq);
  };

  return (
    <div
      onClick={handleClick}
      className={`${
        showFaq ? "bg-[#042BB2]" : "bg-[#FAFAFA]"
      } rounded-[10px] lg:rounded-[15px] p-[15px] lg:p-[20px] mt-[32px] lg:w-[75%]`}
    >
      <div className="flex justify-between items-center">
        <h1
          className={`${
            showFaq ? "text-[#FFFFFF]" : "text-[#111111]"
          } text-[1.125rem] w-[80%] font-medium`}
        >
          {question}
        </h1>
        <div
          className={`w-[42px] h-[42px] rounded-full flex justify-center items-center ${
            showFaq ? "bg-[#FFFFFF]" : "bg-[#1B4DFF]"
          }`}
        >
          {showFaq ? (
            <img src={faqdash} alt="icon" />
          ) : (
            <img src={faqPlus} alt="icon" />
          )}
        </div>
      </div>
      <p
        className={`${
          showFaq
            ? "flex text-[#FFFFFF] pt-[12px] lg:leading-[30px] lg:w-[97%]"
            : "hidden"
        }`}
      >
        {answer}
      </p>
    </div>
  );
};

const Product = () => {
  const faqData = [
    {
      question: "What is Vegeel's compliance software?",
      answer:
        "Vegeel's compliance software is an advanced platform designed to streamline and simplify the compliance process for organizations. It leverages AI-driven automation to manage risks, conduct real-time monitoring, and provide comprehensive support for ISMS, helping you achieve and maintain compliance with industry standards such as ISO 27001 and PCI-DSS.",
    },
    {
      question: "What are the key features of Vegeel's compliance software?",
      answer:
        "Key features of Vegeel's compliance software include AI-driven risk management, comprehensive ISMS support, real-time monitoring, automated compliance evidence collection, and enhanced control management. These features work together to simplify compliance and enhance your organization's security posture.",
    },
    {
      question: "What makes Vegeel's compliance software unique?",
      answer:
        "Vegeel's compliance software is unique due to its AI-driven automation, comprehensive risk management, and real-time monitoring capabilities. These features work together to provide an efficient and user-friendly compliance solution that reduces manual effort and enhances security.",
    },
    {
      question: "How does Vegeel automate compliance evidence collection?",
      answer:
        "Vegeel's software automates compliance evidence collection by continuously monitoring your systems and processes. It gathers and organizes evidence in an auditor-approved manner, ensuring that you are always prepared for audits without the need for manual data collection.",
    },
    {
      question: "Is Vegeel suitable for organizations of all sizes?",
      answer:
        "Yes, Vegeel's compliance software is designed to be scalable and is suitable for organizations of all sizes. Whether you are a small business or a large enterprise, Vegeel can help streamline your compliance processes and enhance your security measures.",
    },
    {
      question: "What makes Vegeel different from other compliance software?",
      answer:
        "Vegeel stands out due to its comprehensive automation capabilities, user-friendly interface, support for multiple compliance frameworks, and robust security measures. Additionally, Vegeel provides exceptional customer support and customizable solutions to meet the unique needs of each organization.",
    },
    {
      question:
        "How can I request a demo or get more information about Vegeel?",
      answer:
        "To request a demo or get more information about Vegeel, you can visit our website at www.vegeel.com/request-a-demo or contact our sales team directly at hello@vegeel.com. We would be happy to answer any questions and provide a personalized demonstration of our software.",
    },
  ];

  return (
    <div className="pt-[32px] px-[15px] flex justify-center items-center flex-col w-full">
      {faqData.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default Product;
