import React, { useEffect, useRef, useState } from "react";
import partnershipIcon from "../../assets/icons/partnership-icon.svg";
import Button from "../../components/Button";
import partnershipCheckIcon from "../../assets/icons/partnership-check-icon.svg";
import { Select } from "antd";
import homeDashboardImgTwo from "../../assets/images/desktop-home-img.svg";
import homeRocketIcon from "../../assets/icons/home-rocket-icon.svg";
import homeDashboardImg from "../../assets/images/vegeel-dashboard-home-img.svg";
import { useMenuContext } from "../../context";
import axios from "axios";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";

interface DemoData {
  [key: string]: any;
}

const handleBookDemo = async (
  data: DemoData,
  setLoading: (loading: boolean) => void
) => {
  try {
    const response = await axios.post(
      "https://email-transporter.onrender.com/become-a-partner/send",
      data
    );

    if (response.status === 200) {
      if (response.status === 200) {
        setLoading(false);
        toast.success(
          "Thank you for your interest in becoming our partner. Our team will reach out to you soon."
        );
      }
    }
  } catch (error: any) {
    if (error.message === "Network Error") {
      toast.error("Network error. Please check your internet connection.");
    } else if (error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    setLoading(false);
  }
};

export default function BecomeAPartner() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [partnerType, setPartnerType] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<DemoData>({});
  const { closeDropdown } = useMenuContext();
  const partnershipSectionRef = useRef<HTMLDivElement>(null);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = (): boolean => {
    const newErrors: DemoData = {};

    if (!firstName) {
      newErrors.firstName = "First name is required.";
    }
    if (!lastName) {
      newErrors.lastName = "Last name is required.";
    }
    if (!companyName) {
      newErrors.companyName = "Company name is required.";
    }
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is not valid.";
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required.";
    }
    if (!partnerType) {
      newErrors.partnerType = "Partner type is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const becomeAPartner = async () => {
    if (!validateForm()) {
      setTimeout(() => {
        setErrors({});
      }, 3000);
      return;
    }
    setLoading(true);

    const partnerData = {
      firstName,
      lastName,
      companyName,
      email,
      phoneNumber,
      partnerType,
    };

    await handleBookDemo(partnerData, setLoading);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setter(e.target.value);
    if (errors[e.target.name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [e.target.name]: "",
      }));
    }
  };

  const handlePartnerTypeChange = (value: string) => {
    setPartnerType(value);
    if (errors.partnerType) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        partnerType: "",
      }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToPartnershipSection = () => {
    if (partnershipSectionRef.current) {
      partnershipSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div onClick={() => closeDropdown()}>
      <div className="bg-[#000033] pt-[3rem] pb-[4rem] lg:pt-[8rem] flex justify-center items-center flex-col">
        <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[165px] lg:w-[212px] h-[40px] lg:h-[48px] rounded-full py-[10px] pr-[20px] pl-[15px]">
          <span className="pr-2">
            <img src={partnershipIcon} alt="partnership" />
          </span>
          <h3 className="text-[0.75rem] lg:text-[0.875rem] text-[#93AAFD]">
            Become a partner
          </h3>
        </div>

        <div className="text-center text-[#FFFFFF] pt-4 flex justify-center items-center flex-col">
          <h1 className="text-[3rem] lg:text-[5rem] font-medium leading-[56px] lg:leading-[92px] px-3">
            Compliance excellence is <br className="hidden lg:flex" /> better{" "}
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              together.
            </span>
          </h1>
          <h2 className="text-[1rem] lg:text-[1.25rem] pt-4 lg:pt-5 leading-[28px] px-5">
            Collaborate with us to streamline processes, leverage cutting-edge
            technology, and deliver exceptional value{" "}
            <br className="hidden lg:flex" /> to your clients by coming our
            partner.
          </h2>
          <div className="w-[80%] lg:w-[30%] pt-5 lg:pt-6">
            <Button
              onClick={scrollToPartnershipSection}
              variant="contained"
              size="large"
              icon
              iconType="arrowWhite"
            >
              Join our partners network
            </Button>
          </div>
        </div>
      </div>
      <section
        ref={partnershipSectionRef}
        className="bg-[#EDF1FF] py-[48px] px-[10px] lg:flex lg:justify-center lg:items-center"
      >
        <div className="bg-[#FFFFFF] rounded-[15px] lg:rounded-[20px] p-[20px] lg:flex lg:justify-between lg:items-start lg:w-[90%] lg:py-[48px] lg:px-[54px]">
          <div className="lg:w-[45%]">
            <div className="w-[139px] lg:w-[205px] bg-[#FFFAEB] text-[#EA6F03] rounded-full py-[10px] px-[15px] lg:px-[30px] text-[0.75rem] lg:text-[1rem]  font-medium">
              Vegeel Partnership
            </div>

            <h1 className="text-[1.75rem] lg:text-[3rem] text-[#060B59] font-bold pt-[12px] lg:pt-[20px] lg:leading-[64px]">
              Become a Vegeel <br /> Partner.
            </h1>

            <p className="text-[#455468] text-[1rem] lg:text-[1.25rem] leading-[28px] lg:leading-[34px] pt-[12px] lg:pt-[20px]">
              We're excited to partner with you! Please fill in the form with
              your details and someone from our partnerships team will get in
              touch with you soon.
            </p>

            <h2 className="text-[#060B59] text-[1rem] lg:text-[1.25rem] font-bold pt-[32px] lg:pt-[48px]">
              Our Partnership Types
            </h2>

            <div className="lg:flex lg:justify-between lg:items-start">
              <div className="">
                <div className="flex justify-start items-center gap-3 lg:gap-4 pt-[12px]">
                  <img src={partnershipCheckIcon} alt="icon" />
                  <p className="text-[1rem] text-[#455468]">Channel Partner</p>
                </div>
                <div className="flex justify-start items-center gap-3 lg:gap-4 pt-[12px]">
                  <img src={partnershipCheckIcon} alt="icon" />
                  <p className="text-[1rem] text-[#455468]">Referral Partner</p>
                </div>
                <div className="flex justify-start items-center gap-3 lg:gap-4 pt-[12px]">
                  <img src={partnershipCheckIcon} alt="icon" />
                  <p className="text-[1rem] text-[#455468]">Audit Partner</p>
                </div>
              </div>
              <div className="">
                <div className="flex justify-start items-center gap-3 lg:gap-4 pt-[12px]">
                  <img src={partnershipCheckIcon} alt="icon" />
                  <p className="text-[1rem] text-[#455468]">Reseller Partner</p>
                </div>
                <div className="flex justify-start items-center gap-3 lg:gap-4 pt-[12px]">
                  <img src={partnershipCheckIcon} alt="icon" />
                  <p className="text-[1rem] text-[#455468]">
                    Technology Partner
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="border border-[#D7DFE9] rounded-[10px] py-[23px] lg:py-[32px] px-[15px] lg:px-[24px]  mt-[32px] lg:mt-0 lg:w-[50%]">
            <div className="lg:flex lg:justify-between lg:items-center">
              <div className="pb-3 lg:pb-6 lg:w-[47%]">
                <label
                  htmlFor="firstname"
                  className="text-[#455468] text-[1rem] block pb-1"
                >
                  First name <span className="text-[#1B4DFF]">*</span>
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => handleInputChange(e, setFirstName)}
                  className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                />
                {errors.firstName && (
                  <p className="text-red-500">{errors.firstName}</p>
                )}
              </div>

              <div className="pb-3 lg:pb-6 lg:w-[47%]">
                <label
                  htmlFor="lastname"
                  className="text-[#455468] text-[1rem] block pb-1"
                >
                  Last name <span className="text-[#1B4DFF]">*</span>
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => handleInputChange(e, setLastName)}
                  className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                />
                {errors.lastName && (
                  <p className="text-red-500">{errors.lastName}</p>
                )}
              </div>
            </div>

            <div className="pb-3 lg:pb-6">
              <label
                htmlFor="company-name"
                className="text-[#455468] text-[1rem] block pb-1"
              >
                Company name<span className="text-[#1B4DFF]">*</span>
              </label>
              <input
                type="text"
                name="company-name"
                id="company-name"
                placeholder="Enter company name"
                value={companyName}
                onChange={(e) => handleInputChange(e, setCompanyName)}
                className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
              />
              {errors.companyName && (
                <p className="text-red-500">{errors.companyName}</p>
              )}
            </div>

            <div className="pb-3 lg:pb-6">
              <label
                htmlFor="email"
                className="text-[#455468] text-[1rem] block pb-1"
              >
                Email <span className="text-[#1B4DFF]">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => handleInputChange(e, setEmail)}
                className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </div>

            <div className="pb-3 lg:pb-6 relative z-10">
              <label
                htmlFor="phone-no"
                className="text-[#455468] text-[1rem] block pb-1"
              >
                Phone no.<span className="text-[#1B4DFF]">*</span>
              </label>
              <input
                type="number"
                name="phone-no"
                id="phone-no"
                min={0}
                placeholder={"+234-901 495 7203"}
                value={phoneNumber}
                onChange={(e) => handleInputChange(e, setPhoneNumber)}
                className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE] no-spinners"
              />
              {errors.phoneNumber && (
                <p className="text-red-500">{errors.phoneNumber}</p>
              )}
            </div>

            <div className="pb-3 lg:pb-6">
              <label
                htmlFor="type-of-partnership"
                className="text-[#455468] text-[1rem] block pb-1"
              >
                Type of partnership<span className="text-[#1B4DFF]">*</span>
              </label>
              <Select
                className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full h-[46px] outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                placeholder="Select partnership type"
                variant="borderless"
                allowClear
                value={partnerType}
                onChange={handlePartnerTypeChange}
                options={[
                  {
                    value: "Channel Partner",
                    label: "Channel Partner",
                  },
                  {
                    value: "Referral Partner",
                    label: "Referral Partner",
                  },
                  {
                    value: "Audit Partner",
                    label: "Audit Partner",
                  },
                  {
                    value: "Reseller Partner",
                    label: "Reseller Partner",
                  },
                  {
                    value: "Technology Partner",
                    label: "Technology Partner",
                  },
                ]}
              />
              {errors.partnerType && (
                <p className="text-red-500">{errors.partnerType}</p>
              )}
            </div>

            <div className="pt-[32px]">
              {loading ? (
                <Button
                  disabled={loading}
                  variant="contained"
                  size="large"
                  icon={false}
                >
                  <ScaleLoader
                    color={"#ffffff"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  icon
                  iconType="arrowWhite"
                  onClick={becomeAPartner}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[48px] bg-[#040B4E] lg:hidden">
        <div className="flex justify-center items-center flex-col">
          <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[300px] lg:370px h-[44px] lg:h-[48px] rounded-full py-[12px] px-[15px]">
            <span className="pr-2">
              <img src={homeRocketIcon} alt="rocket" />
            </span>
            <h3 className="text-[0.75rem] text-[#93AAFD]">
              Begin your journey to compliance efficiency
            </h3>
          </div>
          <h2 className="text-[3rem] lg:text-[3.5rem] font-bold text-[#FFFFFF] text-center px-5 leading-[56px] lg:leading-[64px] pt-3">
            Elevate your <br className="lg:hidden" />
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              security
            </span>{" "}
            and <br />
            <span className="gradient-text bg-gradient-to-l-custom bg-clip-text text-transparent">
              compliance
            </span>{" "}
            posture with <br className="lg:hidden" /> Vegeel
          </h2>
          <p className="text-[#FAFAFA] text-[1rem] leading-[28px] text-center pt-4">
            Join the ranks of forward-thinking <br className="lg:hidden" />{" "}
            organizations that trust Vegeel to fortify <br /> their security and
            compliance efforts.
          </p>
          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 px-7 w-full lg:w-[40%]">
            <Button variant="contained" size="large" icon>
              Request demo
            </Button>
            <Button variant="plain" size="large" icon iconType="arrow">
              Contact sales
            </Button>
          </div>

          <div className="pt-14 w-full h-full lg:hidden">
            <img
              src={homeDashboardImg}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
          <div className="pt-14 w-full h-full hidden lg:flex lg:px-[4.5rem]">
            <img
              src={homeDashboardImgTwo}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
