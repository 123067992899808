import React from "react";
import { FaqItem } from "../Product";

const Framework = () => {
  const faqData = [
    {
      question: "What compliance frameworks does Vegeel support?",
      answer:
        "Vegeel supports a variety of compliance frameworks, including ISO 27001, PCI-DSS, ISO 20000, ISO 22301, SOC 2, IMS and more. Our platform is designed to help organizations meet the specific requirements of these frameworks efficiently and effectively.",
    },
    {
      question: "How does Vegeel's software help with ISO 27001 compliance?",
      answer:
        "Vegeel's software assists with ISO 27001 compliance by automatically detecting and classifying risks, recommending controls, and defining the ISMS scope. It also conducts automated checks to monitor controls in real-time, identifies gaps, and implements corrective actions, ensuring your organization continuously meets ISO 27001 standards.",
    },
    {
      question: "Can Vegeel assist with PCI-DSS compliance?",
      answer:
        "Vegeel is designed to help organizations achieve and maintain PCI-DSS compliance. Our software automatically identifies and classifies risks, recommends appropriate controls, and provides continuous monitoring to ensure compliance with PCI-DSS standards.",
    },
    {
      question: "Can Vegeel be customized to fit specific compliance needs?",
      answer:
        "Yes, Vegeel can be customized to meet the specific compliance needs of your organization. Our platform is flexible and can be tailored to support various industry standards and regulatory requirements, ensuring a comprehensive compliance solution.",
    },
    {
      question:
        "Does Vegeel provide templates and guidelines for compliance frameworks?",
      answer:
        "Yes, Vegeel provides templates and guidelines for various compliance frameworks. These resources help organizations implement necessary controls and processes efficiently, reducing the time and effort required to achieve compliance.",
    },
    {
      question: "Can Vegeel generate compliance reports for audits?",
      answer:
        "Yes, Vegeel can generate comprehensive compliance reports that are suitable for audits. These reports provide detailed insights into your compliance status, evidence of control effectiveness, and documentation of corrective actions taken, making it easier to demonstrate compliance to auditors.",
    },
    {
      question: "How does Vegeel handle changes in compliance regulations?",
      answer:
        "Vegeel stays up-to-date with changes in compliance regulations and updates our platform accordingly. This ensures that your organization always has access to the latest tools and features needed to meet current compliance requirements.",
    },
  ];

  return (
    <div className="pt-[32px] px-[15px] flex justify-center items-center flex-col w-full">
      {faqData.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default Framework;
