import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BookADemo from "../components/BookADemoModal";
import ContactSales from "../components/ContactSalesModal";
import { useModalContext } from "../context";

export default function Layout() {
  const { isContactSalesModalOpen, isBookADemoModalOpen } = useModalContext();
  return (
    <div className="!font-sundry tracking-[-0.3px]">
      <Header />
      <div className="mt-[50px]">
        <Outlet />
      </div>
      <Footer />
      {isBookADemoModalOpen && (
        <div className="fixed top-14 lg:top-16 flex justify-center items-center w-full h-full z-50 ">
          <BookADemo />
        </div>
      )}
      {isContactSalesModalOpen && (
        <div className="fixed top-14 lg:top-3 flex justify-center items-center w-full  h-full z-50 ">
          <ContactSales />
        </div>
      )}
    </div>
  );
}
