import React from "react";
import cubeIcon from "../../../assets/icons/cube-icon.svg";
import vegeelFrameWOrk from "../../../assets/images/frameWork-img.svg";
import blueRocketICon from "../../../assets/icons/blue-rocket-icon.svg";
import slantBashBoard from "../../../assets/images/slantDashBoard.svg";
import whiteRocket from "../../../assets/icons/white-rocket.svg";
import bigScreenFrame from "../../../assets/images/bigSreenFrame.svg";
import whiteCheckBox from "../../../assets/icons/white&blue-Checkbox.svg";
import veegelDashBoard from "../../../assets/images/vedeelDashBoard.svg";
import Button from "../../Button";
import whiteShild from "../../../assets/icons/whiteShield.svg";
import Userprofile from "../../../assets/icons/users-profiles-02.svg";
import destopImage from "../../../assets/images/desktop-image.svg";
import greenTickIcon from "../../../assets/icons/green-tick-icon.svg";
import xmarkFrom from "../../../assets/icons/red-x-mark.svg";
import mobilePhoneImage from "../../../assets/images/mobile-phone-image.svg";
import { LightBLueCard } from "../Light-Blue-Card";
import { Card } from "../card";
import openBlueFileIcon from "../../../assets/icons/blue-open-file.svg";
import thumbsUp from "../../../assets/icons/thumb-up-blue.svg";
import data from "../../../assets/icons/data-blue.svg";
import lightBulb from "../../../assets/icons/lightbulb-02.svg";
import blueRate from "../../../assets/icons/blueRate.svg";
import { useMenuContext, useModalContext } from "../../../context";
import shieldIcon from "../../../assets/icons/white-shield-icon.svg";
import monitorIcon from "../../../assets/icons/monitor-icon.svg";
import networkIcom from "../../../assets/icons/network-icon.svg";
import homeRocketIcon from "../../../assets/icons/home-rocket-icon.svg";
import homeDashboardImg from "../../../assets/images/vegeel-dashboard-home-img.svg";
import homeDashboardImgTwo from "../../../assets/images/desktop-home-img.svg";

export default function PCSiDSS() {
  const { closeDropdown } = useMenuContext();
  const { openBookADemoModal, openContactSalesModal } = useModalContext();

  return (
    <div onClick={() => closeDropdown()}>
      <div className="bg-[#000033] pt-[3rem] lg:pt-[8rem] lg:pb-[2rem] flex justify-center items-center lg:items-center flex-col">
        <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[331px] lg:w-[394px] h-[40px] lg:h-[48px] rounded-full py-[10px] pr-[20px] pl-[15px]">
          <span className="pr-2">
            <img src={cubeIcon} alt="icon" />
          </span>
          <h3 className="text-[0.75rem] lg:text-[0.875rem] text-[#93AAFD]">
            PCI DSS compliance made simple and efficient
          </h3>
        </div>

        <div className="px-2 text-center text-[#FFFFFF] pt-4 lg:pt-[20px] flex justify-center items-center flex-col">
          <h1 className="text-[3rem] lg:text-[5rem] font-medium leading-[56px] lg:leading-[82px] px-3 lg:tracking-tight lg:px-20">
            Attain{" "}
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              PCI DSS <br className="lg:hidden" /> compliance
            </span>{" "}
            <br className="hidden lg:flex" /> and sustain it{" "}
            <br className="lg:hidden" /> effortlessly.
          </h1>

          <h2 className="text-[1rem] lg:text-[1.25rem] pt-4 leading-[28px] lg:pt-[20px] lg:pb-5">
            Adopt Vegeel for your ISMS needs, with a fully integrated pipeline
            of ISO 27001 controls and automated{" "}
            <br className="hidden lg:flex" /> verifications. Vegeel streamlines
            the ISMS process by centralizing risks, controls, and checks, making
            ISO <br className="hidden lg:flex" /> 27001 assessments incredibly
            simple.
          </h2>

          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 lg:pt-7 px-7 w-full lg:w-[55%]">
            <Button
              onClick={openBookADemoModal}
              variant="contained"
              size="large"
              icon={false}
            >
              Request demo
            </Button>
            <Button
              onClick={openContactSalesModal}
              variant="plain"
              size="large"
              icon
              iconType="arrow"
            >
              Contact sales
            </Button>
          </div>
          <div className="pt-8 lg:pt-12 lg:px-9 w-full">
            <img src={bigScreenFrame} alt="" className="w-full h-full" />
          </div>
        </div>

        <div className="pb-7 pt-4 lg:pt-[48px] w-full">
          <div className="h-[3px] w-full bg-gradient-to-r from-[#16174d0e] via-[#2d2b54] to-[#1516450c]"></div>
          <div className="flex lg:justify-center py-10">
            <div className="flex flex-col gap-[2rem] lg:gap-[5rem] items-start lg:flex-row lg:items-center lg:justify-between">
              <div className="flex items-center gap-3 justify-center pl-[2rem] lg:pl-0">
                <div>
                  <img
                    src={whiteRocket}
                    alt="rocketIcon"
                    className="w-[24px]  lg:w-[1.875rem] "
                  />
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400] lg:hidden">
                  PCI-DSS program designed to <br />
                  meet auditor standards
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400] lg:text-[1.125rem]  hidden lg:block">
                  PCI-DSS program designed to <br />
                  meet auditor standards
                </div>
              </div>

              <div className="flex items-center gap-3 justify-center pl-[2rem] lg:pl-0">
                <div>
                  <img
                    src={whiteShild}
                    alt="network icon"
                    className="lg:w-[1.875rem]  w-[24px] h-[24px]"
                  />
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400] lg:text-[1.125rem]  hidden lg:block">
                  Verified vendors for PCI <br /> vulnerability scanning.
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400]  lg:hidden">
                  Verified vendors for PCI <br /> vulnerability scanning.
                </div>
              </div>

              <div className="flex items-center gap-3 justify-center pt-2 pl-[2rem] lg:pl-0">
                <div>
                  <img
                    src={Userprofile}
                    alt="monitor icon"
                    className="lg:w-[1.875rem]  w-[24px] "
                  />
                </div>
                <div className="text-[#ffff] text-[0.875rem] lg:text-[1.125rem] font-[400] hidden lg:block">
                  Network of qualified auditors for <br /> QSA and ROC
                  assessments.
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400] lg:hidden">
                  Network of qualified auditors for <br />
                  QSA and ROC assessments.
                </div>
              </div>
            </div>
          </div>

          <div
            className="h-[3px] w-full bg-gradient-to-r from-[#2d2b54] via-[#16174d0e]"
            style={{
              backgroundImage:
                "linear-gradient(to right, #16174d0e 0%, #2d2b54 30%, #16174d0e 80%)",
            }}
          ></div>
        </div>
      </div>

      <section className="flex lg:justify-center lg:items-center flex-col gap-[2rem] pt-[1rem] pb-[3rem] lg:gap-[3rem]">
        <div className="flex flex-col gap-[2rem] lg:flex-row-reverse lg:items-center lg:w-[90%] lg:justify-between">
          <div className="flex justify-center lg:w-[45%]">
            <img
              src={destopImage}
              alt="iso certification"
              className="lg:w-[34.25rem] lg:h-[34.25rem]"
            />
          </div>

          <div className="px-4 flex flex-col gap-[1rem] lg:w-[50%]">
            <p className="text-[#060B59] text-[1.72rem] font-[700] lg:text-[3rem]">
              End compliance stress <br className="hidden lg:flex" /> with
              Vegeel's seamless <br className="hidden lg:flex" /> PCI-DSS
              solution
            </p>

            <p className="leading-[1.75rem] text-[1rem] text-[#455468]">
              End compliance fatigue with Vegeel's PCI-DSS solution. Enjoy
              automated risk assessments, real-time monitoring, and easy
              evidence collection for a seamless compliance experience.
            </p>

            <div>
              <Button
                onClick={openBookADemoModal}
                variant="contained"
                icon={true}
                iconType="arrowWhite"
                size="small"
              >
                Request demo
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col gap-[2rem] lg:gap-[48px] px-[0.625rem] lg:pb-[4rem]">
        <div className="flex justify-center items-center pt-5">
          <p className="text-[#EA6F03] font-[500] text-[0.75rem] lg:text-[1rem] rounded-[1.5625rem] px-[15px] py-[10px] bg-[#FFFAEB] w-[13.125rem] lg:w-[17.125rem] text-center lg:py-[10px] lg:px-[30px]">
            Vegeel’s PCI-DSS advantage
          </p>
        </div>

        <p className="text-[#060B59] text-[1.72rem] lg:text-[3rem] font-[700] text-center">
          The Vegeel way vs Others
        </p>

        <p className="leading-[1.75rem] text-[1rem] lg:text-[1.25rem] text-[#455468] text-center pb-3">
          The Vegeel platform offers a more efficient way to implementing
          PCI-DSS compliance with easy approach that{" "}
          <br className="hidden lg:flex" /> puts your organization’s security
          posture in check.
        </p>

        <div className="flex-col w-[95%] flex gap-[32px] lg:flex-row lg:flex lg:w-[80%] mx-auto lg:justify-between">
          <div className="bg-[#1B4DFF] p-[20px] gap-[18px] rounded-[15px] flex flex-col  lg:w-1/2 lg:p-[40px] lg:gap-[24px] lg:rounded-[15px]">
            <div className="bg-[#060B59] p-[10px] rounde-[10px] w-[90%] mx-auto  text-[white] text-[1rem] font-[500] text-center rounded-[10px]">
              Vegeel’s PCI-DSS advantage
            </div>
            <div className="bg-[#D4D4D44D] border-[1px] border-[white] p-[15px] flex flex-col gap-[15px] rounded-[10px] lg:gap-[24px] lg:p-[30px]  ">
              <div className="rounded-[10px] p-[15px] flex items-center justify-start gap-[12px] bg-[white]  lg:py-[20px">
                <div>
                  <img src={greenTickIcon} alt="Green-Tick-Icon" />
                </div>
                <div className="text-[#455468] font-[500] text-[1rem]  lg:text-[20px]">
                  Real time monitoring
                </div>
              </div>
              <div className="rounded-[10px] p-[15px] flex items-center justify-start gap-[12px] bg-[white] lg:py-[20px]">
                <div>
                  <img src={greenTickIcon} alt="Green-Tick-Icon" />
                </div>
                <div className="text-[#455468] font-[500] text-[1rem] lg:text-[20px]">
                  Automated evidence <br /> collection
                </div>
              </div>
              <div className="rounded-[10px] p-[15px] flex items-center justify-start gap-[12px] bg-[white]  lg:py-[20px]">
                <div>
                  <img src={greenTickIcon} alt="Green-Tick-Icon" />
                </div>
                <div className="text-[#455468] font-[500] text-[1rem] lg:text-[20px]">
                  Implementation guide
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#E5EAFC] p-[20px] gap-[18px] rounded-[15px]  flex flex-col lg:w-1/2 lg:p-[40px] lg:gap-[24px] lg:rounded-[15px]">
            <div className="bg-[#1B4DFF] p-[10px] rounde-[10px] w-[90%] mx-auto text-[white] text-[1rem] font-[500] text-center rounded-[10px] ">
              Others
            </div>
            <div className="bg-[#6396CC] border-[1px] border-[white] p-[15px]  flex flex-col gap-[15px] rounded-[10px] lg:gap-[24px] lg:p-[30px]  ">
              <div className="rounded-[10px] p-[15px] flex items-center justify-start gap-[12px] bg-[white] lg:py-[20px]">
                <div>
                  <img src={xmarkFrom} alt="Green-Tick-Icon" />
                </div>
                <div className="text-[#455468] font-[500] text-[1rem] lg:text-[20px]">
                  Delayed monitoring
                </div>
              </div>
              <div className="rounded-[10px] p-[15px] flex items-center justify-start gap-[12px] bg-[white]  lg:py-[20px]">
                <div>
                  <img src={xmarkFrom} alt="Green-Tick-Icon" />
                </div>
                <div className="text-[#455468] font-[500] text-[1rem] lg:text-[20px]">
                  Manual evidence collection
                </div>
              </div>
              <div className="rounded-[10px] p-[15px] flex items-center justify-start gap-[12px] bg-[white]  lg:py-[20px]">
                <div>
                  <img src={xmarkFrom} alt="Green-Tick-Icon" />
                </div>
                <div className="text-[#455468] font-[500] text-[1rem] lg:text-[20px]">
                  No Implementation <br /> support
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[3rem] lg:py-0">
          <div className="flex flex-col gap-[2rem] lg:flex-row-reverse lg:items-center lg:w-[80%] lg:justify-between lg:mx-auto">
            <div className="flex justify-center lg:w-[45%]">
              <img
                src={mobilePhoneImage}
                alt="iso certification"
                className="lg:w-[34.25rem] lg:h-[34.25rem]"
              />
            </div>

            <div className="px-4 flex flex-col gap-[1rem] lg:w-[55%]">
              <p className="text-[#060B59] text-[1.72rem] font-[700] lg:text-[3rem]">
                Simplify your PCI-DSS compliance journey
              </p>

              <p className="leading-[1.75rem] text-[1rem] text-[#455468] font-[400]">
                Navigating PCI-DSS requirements can be overwhelming. Vegeel
                helps you streamline compliance by clarifying and implementing
                necessary security measures across your people, processes, and
                technology. Feeling confused about PCI-DSS compliance? Speak
                with us today to see how Vegeel can make it simple and
                stress-free.
              </p>
              <div className="">
                <Button
                  onClick={openContactSalesModal}
                  variant="contained"
                  icon={true}
                  iconType="arrowWhite"
                  size="small"
                >
                  Contact sales
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex lg:justify-center lg:items-center flex-col gap-[2rem] py-[3rem] pb-16 px-4 bg-[#EDF1FF]">
        <div className="bg-[#1B4DFF] rounded-[0.938rem] py-[1.875rem] lg:pt-[64px] px-[1.25rem] lg:px-[84px] flex justify-center items-center flex-col w-[97%] lg:w-[95%] lg:rounded-[1.875rem]">
          <div className="flex justify-center">
            <p className="text-[#EA6F03] lg:w-[206px] text-center font-medium text-[0.75rem] lg:text-[1rem] rounded-[1.5625rem] px-[0.938rem] py-[0.625rem] bg-[#FFFAEB] ">
              Vegeel’s added-value
            </p>
          </div>

          <h1 className="font-bold text-[1.75rem] lg:text-[3rem] text-center text-[#FFFFFF] pt-[18px] lg:pt-[20px]">
            Attain effortless PCI <br className="lg:hidden" /> DSS compliance
          </h1>

          <p className="text-[1rem] lg:text-[1.25rem] text-center leading-[1.75rem] lg:leading-[2.125rem] text-white lg:w-[880px] mx-auto pt-[18px] lg:pt-[20px]">
            With Vegeel, attaining PCI-DSS compliance is effortless. Our
            platform simplifies the entire process, from automated risk
            assessments to real-time monitoring and evidence collection.
          </p>

          <div className="flex flex-col gap-[2rem] lg:flex-row lg:gap-[45px] pt-[28px] lg:pt-[48px] ">
            <LightBLueCard
              Lcheader="Effortless evidence"
              Lcheader2="collection"
              header="Effortless evidence"
              header2="collection"
              icon={openBlueFileIcon}
              subHead="Vegeel automates evidence collection by providing real-time updates, a centralized repository, and audit-ready documentation, ensuring seamless compliance management."
              height="404"
            />
            <LightBLueCard
              Lcheader="Guidelines for"
              Lcheader2=" implementation"
              header="Guidelines for"
              header2="implementation"
              icon={lightBulb}
              subHead="Vegeel offers comprehensive implementation guidelines and suggests what evidence to upload for every requirement, ensuring you meet the compliance standards."
              height="404"
            />
            <LightBLueCard
              Lcheader="Real-time monitoring"
              Lcheader2="  "
              header="Real-time monitoring"
              icon={blueRate}
              subHead="Vegeel's real-time monitoring feature ensures continuous PCI-DSS compliance by providing continuous oversight of requirements, instant insights and alerts."
              height="404"
            />
          </div>
        </div>
      </section>

      <section className="w-full bg-[white] pt-[1.7rem] lg:py-[64px] pb-[48px] flex flex-col gap-[2rem]">
        <div className="flex justify-center items-center">
          <p className="text-[#EA6F03] font-medium text-[0.75rem] lg:text-[1rem] rounded-[1.5625rem] px-[0.938rem] py-[0.625rem] bg-[#FFFAEB] text-center ">
            Vegeel’s PCI-DSS implementation
          </p>
        </div>

        <h1 className="text-[#060B59] text-[1.72rem] font-bold lg:text-[3rem] text-center">
          Our unique approach to compliance
        </h1>

        <p className="leading-[1.75rem] lg:leading-[34px] text-[1rem] lg:text-[1.25rem] text-[#455468] text-center">
          Discover how Vegeel's innovative strategies set us apart, offering
          tailored compliance solutions <br className="hidden lg:flex" /> that
          streamline processes and enhance security.
        </p>

        <div className=" flex flex-col gap-[2rem] lg:hidden">
          <Card
            header="Evidence collection"
            majorIcon={openBlueFileIcon}
            subHead1="VEGEEL PROCESS"
            subHead2="OUTCOME"
            subtext1="Use Vegeel’s documentation and policy templates to prepare your documentations tailored to your organization’s need. Or upload your documents and have them in one place. "
            list1="Ready to use policies and templates"
            list2="Continuous document review and tracker"
          />
          <Card
            header="Implement"
            majorIcon={data}
            subHead1="VEGEEL PROCESS"
            subHead2="OUTCOME"
            subtext1="Use Vegeel implementation guide and suggested evidence to ensure your organization meets the compliance standard.  "
            list1="Easy walkthrough to compliance."
            list2="100% compliance standard."
          />
          <Card
            header="Review"
            majorIcon={thumbsUp}
            subHead1="VEGEEL IMPLEMENTATION"
            subHead2="VEGEEL ADVANTAGE"
            subtext1="Map only correct evidence required for each requirement using Vegeel’s suggested evidence and implementation guide. Review the evidence and proceed."
            list1="Expert audit guidance"
            list2="Streamlined certification process"
          />
        </div>

        <div className="hidden lg:block">
          <div className="w-[93%] h-[45.625rem]  mx-auto ">
            <div className=" w-full h-[4.375rem] flex items-center rounded-t-[1.875rem] mx-auto">
              <div className="w-[30%]  h-full bg-[#EAECF0] flex flex-col justify-center  items-center rounded-tl-[1.875rem] font-[500] text-[#060B59] text-[1.5rem]">
                <div>
                  <p> Our Approach</p>
                </div>
              </div>

              <div className="w-[40%] h-full bg-[#E5EAFC] flex justify-center items-center  font-[500] text-[#060B59] text-[1.5rem]">
                Vegeel process
              </div>

              <div className="w-[30%] h-full bg-[#1B4DFF] flex justify-center items-center rounded-tr-[1.875rem] font-[500] text-white text-[1.5rem]">
                Outcome
              </div>
            </div>

            <div className="flex w-full">
              <div className="flex flex-col w-[30%]  py-[1.563rem] px-[1rem] gap-[1rem] bg-[#FAFAFA] rounded-bl-[1.875rem]">
                <div className="flex items-center gap-3 justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] mx-auto border-b-[1px] border-[#455468]">
                  <div className="w-[1.875rem] h-[1.875rem] flex items-center gap-4">
                    <img src={openBlueFileIcon} alt="file icon" />
                  </div>
                  <div className="text-[#111111] text-[1rem] lg:text-[1.25rem] font-[500]">
                    {" "}
                    Evidence Collection
                  </div>
                </div>

                <div className="flex items-center gap-3 justify-start px-[0.938rem] pb-[1.75rem] w-full h-[9.188rem] mx-auto border-b-[1px] border-[#455468]">
                  <div className="w-[1.875rem] h-[1.875rem] flex items-center gap-4">
                    <img src={data} alt="file icon" />
                  </div>
                  <div className="text-[#111111] text-[1rem] lg:text-[1.25rem] font-[500]">
                    <p>Implement</p>
                  </div>
                </div>

                <div className="flex items-center gap-3 justify-start px-[0.938rem] pb-[1rem]  w-full h-[9.188rem] mx-auto ">
                  <div className="w-[1.875rem] h-[1.875rem] flex items-center gap-4">
                    <img src={thumbsUp} alt="star icon" />
                  </div>
                  <div className="text-[#111111] text-[1rem] lg:text-[1.25rem] font-[500]">
                    {" "}
                    <p>Review</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-[40%] py-[1.563rem] px-[1rem] gap-[1rem] bg-[#EDF1FF]">
                <div className="flex items-center  justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] mx-auto border-b-[1px] border-[#455468]">
                  <p className="text-[#111111] font-[500] leading-[1.875rem] text-sm lg:text-[1.125rem]">
                    Use Vegeel’s documentation and policy templates to prepare
                    your documentations tailored to your organization’s need. Or
                    upload your documents and have them in one place.
                  </p>
                </div>
                <div className="flex items-center  justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] mx-auto border-b-[1px] border-[#455468]">
                  <p className="text-[#111111] font-[500] leading-[1.875rem] text-sm lg:text-[1.25rem] ">
                    Use Vegeel implementation guide and suggested evidence to
                    ensure your organization meets the compliance standard.
                  </p>
                </div>

                <div className="flex items-center  justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] mx-auto">
                  <p className="text-[#111111] font-[500] leading-[1.875rem]  text-sm lg:text-[1.25rem] ">
                    Map only correct evidence required for each requirement
                    using Vegeel’s suggested evidence and implementation guide.
                    Review the evidence and proceed.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-[30%] bg-[#0527aa] py-[1.563rem]  px-[1rem] gap-[16px] rounded-br-[1.875rem]">
                <div className="flex items-start  justify-start pb-[1.75rem] w-full object-contain mx-auto h-[9.188rem] border-b-[1px] border-[#ffff]">
                  <div className="flex flex-col justify-center items-start  px-[0.938rem]  xl:gap-3 pb-4 ">
                    <div className="flex items-center gap-4 justify-start h-[60px] w-full">
                      <div className="flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5 "
                        />
                      </div>
                      <p className="text-[18px] tracking-wide text-[#ffff] text-md font-[500] leading-[30px] w-[90%]">
                        Ready to use policies and templates
                      </p>
                    </div>

                    <div className="flex items-center gap-4 justify-start">
                      <div className="flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5 "
                        />
                      </div>
                      <p className="text-[1.125rem] tracking-wide text-[#ffff] text-md font-[500] leading-[30px] w-[90%]">
                        Continuous document review and tracker
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center  justify-start  pb-[1.75rem]  w-full h-[9.188rem] mx-auto border-b-[1px] border-[#ffff]">
                  <div className="flex flex-col justify-center items-start pt-6 px-[0.938rem] pb-4  xl:gap-3 ">
                    <div className="flex items-center gap-4 justify-start h-[60px]  w-full">
                      <div className=" flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5 "
                        />
                      </div>
                      <p className="text-[#ffff] text-[1.125rem] font-[500] tracking-wide leading-[30px] w-[90%]">
                        Easy walkthrough to compliance.
                      </p>
                    </div>

                    <div className="flex items-center gap-4 justify-start">
                      <div className=" flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5 "
                        />
                      </div>
                      <p className="text-[1.125rem] tracking-wide text-[#ffff]  font-[500] leading-[30px] w-[90%]">
                        100% compliance standard.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center  justify-start   pb-[1.75rem]  w-full h-[9.188rem] mx-auto ">
                  <div className="flex flex-col justify-center items-start  px-[0.938rem]  xl:gap-3 ">
                    <div className="flex items-center gap-4 justify-start h-[60px]  w-full">
                      <div className="flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5 "
                        />
                      </div>
                      <p className="text-[1.125rem] text-[#ffff] font-[500] leading-[30px] tracking-wide w-[90%]">
                        Expert audit guidance
                      </p>
                    </div>
                    <div className="flex items-center gap-4 justify-start ">
                      <div className="flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5 "
                        />
                      </div>
                      <p className="text-[1.125rem] text-[#ffff] font-[500] leading-[30px] w-[90%]">
                        Streamlined certification process
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[48px] bg-[#040B4E]">
        <div className="flex justify-center items-center flex-col">
          <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[300px] lg:370px h-[44px] lg:h-[48px] rounded-full py-[12px] px-[15px]">
            <span className="pr-2">
              <img src={homeRocketIcon} alt="rocket" />
            </span>
            <h3 className="text-[0.75rem] text-[#93AAFD]">
              Begin your journey to compliance efficiency
            </h3>
          </div>
          <h2 className="text-[3rem] lg:text-[3.5rem] font-bold text-[#FFFFFF] text-center px-5 leading-[56px] lg:leading-[64px] pt-3">
            Elevate your <br className="lg:hidden" />
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              security
            </span>{" "}
            and <br />
            <span className="gradient-text bg-gradient-to-l-custom bg-clip-text text-transparent">
              compliance
            </span>{" "}
            posture with <br className="lg:hidden" /> Vegeel
          </h2>
          <p className="text-[#FAFAFA] text-[1rem] leading-[28px] text-center pt-4">
            Join the ranks of forward-thinking <br className="lg:hidden" />{" "}
            organizations that trust Vegeel to fortify <br /> their security and
            compliance efforts.
          </p>
          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 px-7 w-full lg:w-[40%]">
            <Button
              onClick={openBookADemoModal}
              variant="contained"
              size="large"
              icon
            >
              Request demo
            </Button>
            <Button
              onClick={openContactSalesModal}
              variant="plain"
              size="large"
              icon
              iconType="arrow"
            >
              Contact sales
            </Button>
          </div>

          <div className="pt-14 w-full h-full lg:hidden">
            <img
              src={homeDashboardImg}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
          <div className="pt-14 w-full h-full hidden lg:flex lg:px-[4.5rem]">
            <img
              src={homeDashboardImgTwo}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
