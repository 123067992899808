// CustomIcon.tsx
import React from "react";

interface CustomIconProps {
  SvgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  size?: number;
}

const CustomIcon: React.FC<CustomIconProps> = ({ SvgIcon, size = 12 }) => {
  return (
    <div style={{ width: size, height: size }}>
      <SvgIcon width="100%" height="100%" />
    </div>
  );
};

export default CustomIcon;
