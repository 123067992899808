import React, { useState, useEffect } from "react";
import Button from "../Button";
import prodoctIso27001 from "../../assets/images/product-iso-27001-logo.svg";
import productPci from "../../assets/images/product-pci-logo.svg";
import productSoc2 from "../../assets/images/product-soc-2-logo.svg";
import productIms from "../../assets/images/product-ims-logo.svg";
import productIso200000 from "../../assets/images/product-iso-20000-logo.svg";
import productIso22301 from "../../assets/images/product-iso-22301-logo.svg";
import complianceStack from "../../assets/images/compliance-stack-img.svg";
import { ReactComponent as Times } from "../../assets/icons/times.svg";
import CustomIcon from "../CustomIcon";
import { useModalContext } from "../../context";
import axios from "axios";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";

interface DemoData {
  [key: string]: any;
}

const handleBookDemo = async (
  data: DemoData,
  setLoading: (loading: boolean) => void,
  closeBookADemoModal: any
) => {
  try {
    const response = await axios.post(
      "https://email-transporter.onrender.com/book-a-demo/send",
      data
    );

    if (response.status === 200) {
      setLoading(false);
      closeBookADemoModal();
      window.open("https://tidycal.com/36kgx91/vegeel-demo", "_blank");
    }
  } catch (error: any) {
    if (error.message === "Network Error") {
      toast.error("Network error. Please check your internet connection.");
    } else if (error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    setLoading(false);
  }
};

const BookADemo = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { closeBookADemoModal } = useModalContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrors({});
    }, 3000);

    return () => clearTimeout(timer);
  }, [errors]);

  const validate = () => {
    const newErrors: any = {};

    if (!firstName) newErrors.firstName = "First name is required.";
    if (!lastName) newErrors.lastName = "Last name is required.";
    if (!companyName) newErrors.companyName = "Company name is required.";
    if (!email) newErrors.email = "Email is required.";
    if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Email is invalid.";
    if (product.length === 0)
      newErrors.product = "At least one product must be selected.";

    return newErrors;
  };

  const bookDemo = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    const demoData = {
      firstName,
      lastName,
      companyName,
      email,
      products: product.join(", "),
    };

    await handleBookDemo(demoData, setLoading, closeBookADemoModal);
  };

  const handleCheckboxChange = (productName: string, isChecked: boolean) => {
    if (isChecked) {
      setProduct([...product, productName]);
    } else {
      setProduct(product.filter((item) => item !== productName));
    }
  };

  const handleInputChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    field: string
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors[field];
        return newErrors;
      });
    };
  };

  return (
    <div className="w-full h-full lg:flex lg:justify-start lg:items-start lg:w-[1000px] lg:h-[700px] overflow-auto">
      <div className="h-[449px] lg:w-[450px] lg:h-[600px] bg-[#042BB2] p-[20px]  flex justify-center lg:justify-center items-start flex-col lg:rounded-l-[15px]">
        <div>
          <div className="flex justify-between items-center">
            <div className="w-[127px] h-[40px] rounded-[25px] bg-[#FFFAEB] py-[10px] px-[15px] flex justify-center items-center">
              <h1 className="text-[#EA6F03] text-[0.75rem] font-medium">
                Schedule a demo
              </h1>
            </div>
            <div onClick={closeBookADemoModal} className="cursor-pointer">
              <CustomIcon SvgIcon={Times} size={24} />
            </div>
          </div>
          <h1 className="pt-[12px] text-[1.75rem] font-bold leading-[36px] text-[#FFFFFF] ">
            Schedule a personalized demo with Vegeel.
          </h1>
          <p className="text-[1rem] leading-[28px] text-[#FFFFFF] pt-[12px]">
            With a streamlined workflow and dedicated expert guidance, Vegeel
            automates the entire compliance process, and increase yours audit
            readiness. Complete the form and let’s explore how Vegeel can fit
            your exact needs.
          </p>
        </div>
        <div className="pt-[32px]">
          <h1 className="text-[#FFFFFF] text-[1rem]">Our compliance stack</h1>
          <div className="w-[190px] h-[45px] pt-[8px]">
            <img src={complianceStack} alt="compliance-stack" />
          </div>
        </div>
      </div>

      <div className="h-[960px] lg:w-[550px] lg:h-[600px] p-[20px] lg:py-[10px] lg:px-[20px] bg-[#FFFFFF] lg:rounded-r-[15px]">
        <div>
          <h1 className="text-[#060B59] text-[1.125rem] font-medium">
            Book a demo
          </h1>
          <p className="pt-[8px] lg:pt-[6px] text-[#455468] text-[0.875rem] pb-[16px] lg:pb-2">
            Fill in these details to schedule a personalized demo.
          </p>
        </div>

        <div>
          <div className="lg:flex lg:justify-between lg:items-center">
            <div className="pb-[24px] lg:pb-3 lg:w-[48%]">
              <label
                htmlFor="firstname"
                className="text-[#455468] text-[1rem] block pb-1"
              >
                First name <span className="text-[#1B4DFF]">*</span>
              </label>
              <input
                type="text"
                name="first_name"
                id="firstname"
                placeholder="Enter first name"
                className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                onChange={handleInputChange(setFirstName, "firstName")}
              />
              {errors.firstName && (
                <p className="text-red-500 text-xs pt-1">{errors.firstName}</p>
              )}
            </div>

            <div className="pb-[24px] lg:pb-3 lg:w-[48%]">
              <label
                htmlFor="lastname"
                className="text-[#455468] text-[1rem] block pb-1"
              >
                Last name <span className="text-[#1B4DFF]">*</span>
              </label>
              <input
                type="text"
                name="last_name"
                id="lastname"
                placeholder="Enter last name"
                className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
                onChange={handleInputChange(setLastName, "lastName")}
              />
              {errors.lastName && (
                <p className="text-red-500 text-xs pt-1">{errors.lastName}</p>
              )}
            </div>
          </div>

          <div className="pb-[24px] lg:pb-3">
            <label
              htmlFor="company-name"
              className="text-[#455468] text-[1rem] block pb-1"
            >
              Company name <span className="text-[#1B4DFF]">*</span>
            </label>
            <input
              type="text"
              name="company_name"
              id="company-name"
              placeholder="Enter company name"
              className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
              onChange={handleInputChange(setCompanyName, "companyName")}
            />
            {errors.companyName && (
              <p className="text-red-500 text-xs pt-1">{errors.companyName}</p>
            )}
          </div>
          <div className="pb-[24px] lg:pb-3">
            <label
              htmlFor="email"
              className="text-[#455468] text-[1rem] block pb-1"
            >
              Email <span className="text-[#1B4DFF]">*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter email address"
              className="border border-[#AFBACA] bg-[#FFFFFF] rounded-[7px] w-full py-3 px-2 outline-none placeholder:text-[0.875rem] placeholder:text-[#8897AE]"
              onChange={handleInputChange(setEmail, "email")}
            />
            {errors.email && (
              <p className="text-red-500 text-xs pt-1">{errors.email}</p>
            )}
          </div>

          <h2 className="text-[#455468] text-[1rem]">
            What product are you interested in?{" "}
            <span className="text-[#1B4DFF]">*</span>
          </h2>
          {errors.product && (
            <p className="text-red-500 text-xs pt-1">{errors.product}</p>
          )}
          <div className="lg:flex lg:justify-between lg:items-center lg:w-[83%]">
            <div>
              <label className="flex justify-start items-center gap-4 pt-5 lg:pt-4">
                <input
                  type="checkbox"
                  style={{
                    width: 16,
                    height: 16,
                    border: "1px solid #D7DFE9",
                    borderRadius: 10,
                  }}
                  name={"Iso 27001"}
                  onChange={(e) =>
                    handleCheckboxChange("ISO 27001", e.target.checked)
                  }
                />
                <span>
                  <img src={prodoctIso27001} alt="Iso 27001" />
                </span>
                <span className="text-[#455468] text-[1rem]">ISO 27001</span>
              </label>

              <label className="flex justify-start items-center gap-4 pt-5 lg:pt-4">
                <input
                  type="checkbox"
                  style={{
                    width: 16,
                    height: 16,
                    border: "1px solid #D7DFE9",
                    borderRadius: 10,
                  }}
                  name={"PCI DSS"}
                  onChange={(e) =>
                    handleCheckboxChange("PCI DSS", e.target.checked)
                  }
                />
                <span>
                  <img src={productPci} alt="PCI DSS" />
                </span>
                <span className="text-[#455468] text-[1rem]">PCI DSS</span>
              </label>

              <label className="flex justify-start items-center gap-4 pt-5 lg:pt-4">
                <input
                  type="checkbox"
                  style={{
                    width: 16,
                    height: 16,
                    border: "1px solid #D7DFE9",
                    borderRadius: 10,
                  }}
                  name={"SOC 2"}
                  onChange={(e) =>
                    handleCheckboxChange("SOC 2", e.target.checked)
                  }
                />
                <span>
                  <img src={productSoc2} alt="SOC 2" />
                </span>
                <span className="text-[#455468] text-[1rem]">SOC 2</span>
              </label>
            </div>

            <div>
              <label className="flex justify-start items-center gap-4 pt-5 lg:pt-4">
                <input
                  type="checkbox"
                  style={{
                    width: 16,
                    height: 16,
                    border: "1px solid #D7DFE9",
                    borderRadius: 10,
                  }}
                  name={"IMS"}
                  onChange={(e) =>
                    handleCheckboxChange("IMS", e.target.checked)
                  }
                />
                <span>
                  <img src={productIms} alt="IMS" />
                </span>
                <span className="text-[#455468] text-[1rem]">IMS</span>
              </label>

              <label className="flex justify-start items-center gap-4 pt-5 lg:pt-4">
                <input
                  type="checkbox"
                  style={{
                    width: 16,
                    height: 16,
                    border: "1px solid #D7DFE9",
                    borderRadius: 10,
                  }}
                  name={"ISO 20000"}
                  onChange={(e) =>
                    handleCheckboxChange("ISO 20000", e.target.checked)
                  }
                />
                <span>
                  <img src={productIso200000} alt="ISO 20000" />
                </span>
                <span className="text-[#455468] text-[1rem]">ISO 20000</span>
              </label>

              <label className="flex justify-start items-center gap-4 pt-5 lg:pt-4">
                <input
                  type="checkbox"
                  style={{
                    width: 16,
                    height: 16,
                    border: "1px solid #D7DFE9",
                    borderRadius: 10,
                  }}
                  name={"ISO 22301"}
                  onChange={(e) =>
                    handleCheckboxChange("ISO 22301", e.target.checked)
                  }
                />
                <span>
                  <img src={productIso22301} alt="ISO 22301" />
                </span>
                <span className="text-[#455468] text-[1rem]">ISO 22301</span>
              </label>
            </div>
          </div>

          <div className="pt-10 lg:pt-8">
            {loading ? (
              <Button
                disabled={loading}
                onClick={bookDemo}
                variant="contained"
                size="large"
                icon={false}
              >
                <ScaleLoader
                  color={"#ffffff"}
                  loading={loading}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Button>
            ) : (
              <Button
                disabled={loading}
                onClick={bookDemo}
                variant="contained"
                size="large"
                icon
                iconType="arrowWhite"
              >
                Schedule your demo
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookADemo;
