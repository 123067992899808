import React, { useState } from "react";
import Button from "../../Button";
import { Link, useNavigate } from "react-router-dom";
import { useMenuContext } from "../../../context";
import logo from "../../../assets/images/vegeel-logo.svg";
import menubar from "../../../assets/icons/menubar.svg";
import { navlinks } from "../../../constants";
import mobileIcon from "../../../assets/images/vegeel-favicon.svg";
import chevrondown from "../../../assets/icons/chevron-down.svg";
import chevronup from "../../../assets/icons/chevronup.svg";
import close from "../../../assets/icons/close.svg";
import CustomIcon from "../../CustomIcon";

export default function MobileHeader() {
  const { closeMenu } = useMenuContext();
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState<string | null>(null);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const { isMenuOpen, openMenu } = useMenuContext();

  const handleNavigate = (path: string, label: string) => {
    navigate(path);
    closeMenu();
    setActiveMenu(label);
  };

  const toggleDropdown = (label: string) => {
    if (showDropdown === label) {
      setShowDropdown(null);
    } else {
      setShowDropdown(label);
    }
  };

  return (
    <div className="h-full flex justify-between items-center px-[20px] py-[6px] relative">
      <Link to={"/"}>
        <img
          src={logo}
          alt="Vegeel Logo"
          title="Vegeel Logo"
          className="w-[117px]"
        />
      </Link>

      <div className="">
        <img src={menubar} alt="menu" className="" onClick={openMenu} />
      </div>

      {isMenuOpen && (
        <div className="absolute top-0 left-0 w-full h-dvh overflow-y-auto z-20 bg-white px-[20px] pb-6">
          <div className="h-[56px] flex justify-between items-center border-b border-[#D7DFE9]">
            <Link to={"/"}>
              <img src={mobileIcon} alt="vegeel icon" />
            </Link>
            <img src={close} alt="close" title="close" onClick={closeMenu} />
          </div>
          <div className="py-[32px] h-[calc(100%-56px)]">
            <nav className="flex flex-col justify-center items-center gap-4 text-dark_color font-medium leading-[28px]">
              {navlinks.map((link) => (
                <div key={link.label} className="w-full">
                  <button
                    onClick={() =>
                      link.pathList
                        ? toggleDropdown(link.label)
                        : handleNavigate(link.path, link.label)
                    }
                    className={`h-[48px] w-full flex justify-between items-center   text-center ${
                      activeMenu === link.label ? "bg-gray-200" : ""
                    }`}
                  >
                    <span
                      className={`${
                        showDropdown === link.label
                          ? "text-main_color"
                          : "text-[#111111]"
                      } font-medium leading-[28px]`}
                    >
                      {link.label}
                    </span>
                    {link.pathList && (
                      <img
                        src={
                          showDropdown === link.label ? chevronup : chevrondown
                        }
                        alt="dropdown"
                      />
                    )}
                  </button>
                  {link.pathList && showDropdown === link.label && (
                    <ul className="flex flex-col justify-center w-full items-center gap-4 text-dark_color font-medium leading-[28px]">
                      {link.pathList.map((path, i) => (
                        <li
                          key={i}
                          onClick={() => handleNavigate(path.path, path.label)}
                          className={`w-full py-[10px] flex justify-between items-center rounded-[6px] gap-4 ${
                            activeMenu === path.label ? "bg-gray-200" : ""
                          }`}
                        >
                          <CustomIcon SvgIcon={path.img} size={48} />

                          <div className="flex flex-col gap-[2px] w-[80%]">
                            <span className="text-[#111111] font-medium leading-[28px]">
                              {path.label}
                            </span>
                            <span className="text-[#455468] font-normal leading-[24px]">
                              {path.desc}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </nav>
            <div className="mt-5">
              <Button icon={false} variant="contained" size="large">
                Request a demo
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
