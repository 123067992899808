import React from "react";

export interface CommitmentProps {
  icon: string;
  text: string;
  title: string;
}

function index({ icon, text, title }: CommitmentProps) {
  return (
    <div>
      <div className="w-[313px] lg:w-[500px]">
        <div className="flex justify-center items-center flex-col">
          <div className="rounded-[24px] w-[48px] bg-[#F2F5FF] h-[48px] flex justify-center items-center">
            <img src={icon} alt="veegeel icon" title="veegel icon" />
          </div>
          <div className="pt-[16px] lg:text-[24px] lg:leading-[36px]">
            <h1>{title}</h1>
          </div>
          <div className="flex justify-center gap-[8px]">
            <h1 className="text-center text-[16px] leading-[28px] font-[400] text-dark_color lg:text-[18px] lg:leading-[30px]">
              {text}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
