import cubeIcon from "../../assets/icons/cube-icon.svg";
import Button from "../../components/Button";
import shieldIcon from "../../assets/icons/white-shield-icon.svg";
import monitorIcon from "../../assets/icons/monitor-icon.svg";
import networkIcom from "../../assets/icons/network-icon.svg";
import isoCertification from "../../assets/images/ISO Certification-pana 1.svg";
import blueFileIcon from "../../assets/icons/blue-file-icon.svg";
import blueShieldIcon from "../../assets/icons/blue-shield-icon.svg";
import blueStarICon from "../../assets/icons/blue-star-icon.svg";
import blueFileSearchIcon from "../../assets/icons/blue-file-seach-icon.svg";
import controlsImage from "../../assets/images/controls-image.svg";
import documentTemplateImage from "../../assets/images/document-template-image.svg";
import taskMangementImage from "../../assets/images/task-management-image.svg";
import blueFolderIcon from "../../assets/icons/blue-open-file.svg";
import blueChartIcon from "../../assets/icons/blue-line-chart.svg";
import blueMarkIcon from "../../assets/icons/blue-success-icon.svg";
import blueBookIcon from "../../assets/icons/blue-book-Icon.svg";
import blueWrenchIcon from "../../assets/icons/blue-wrench.svg";
import { BlueCard } from "../../components/solution-components/Blue-Card";
import { LightBLueCard } from "../../components/solution-components/Light-Blue-Card";
import { Card } from "../../components/solution-components/card";
import bigScreenFrame from "../../assets/images/bigSreenFrame.svg";
import whiteCheckBox from "../../assets/icons/white&blue-Checkbox.svg";
import bigScreenBLuefileICon from "../../assets/icons/blueBigFIle.svg";
import bigScreenSearchFile from "../../assets/icons/blue-big-file-sexrch.svg";
import bigScreenStar from "../../assets/icons/big-blue-star.svg";
import bigScreenShield from "../../assets/icons/big-shield-blue.svg";
import { useMenuContext, useModalContext } from "../../context";
import policyDocumentationAndTempmlates from "../../assets/images/iso-about-img.svg";
import dedicatedImg from "../../assets/images/dedicated-img.svg";
import grcImg from "../../assets/images/grc-management-img.svg";
import { motion } from "framer-motion";
import homeRocketIcon from "../../assets/icons/home-rocket-icon.svg";
import homeDashboardImg from "../../assets/images/vegeel-dashboard-home-img.svg";
import homeDashboardImgTwo from "../../assets/images/desktop-home-img.svg";
import { useEffect, useState } from "react";

export default function Solutions() {
  const { closeDropdown } = useMenuContext();
  const { openBookADemoModal, openContactSalesModal } = useModalContext();
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div onClick={() => closeDropdown()}>
      <div className="bg-[#000033] pt-[3rem] lg:pt-[8rem] lg:pb-[2rem]  flex justify-center items-center flex-col">
        <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[331px] h-[40px] rounded-full py-[10px] pr-[20px] pl-[15px]">
          <span className="pr-2">
            <img src={cubeIcon} alt="icon" />
          </span>
          <h3 className="text-[0.75rem] text-[#93AAFD]">
            ISO 27001 compliance made simple and efficient
          </h3>
        </div>

        <div className="px-2 lg:px-20 text-center text-[#FFFFFF] pt-4 lg:pt-[20px] flex justify-center items-center flex-col">
          <h1 className="text-[3rem] lg:text-[5rem] font-medium leading-[56px] lg:leading-[82px] px-3 lg:tracking-tight">
            Implement <br className="lg:hidden" /> your{" "}
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              ISMS{" "}
            </span>
            <br className="hidden lg:flex" /> without sweat.
          </h1>
          <h2 className="text-[1rem] pt-4 leading-[28px] lg:hidden">
            Adopt Vegeel for your ISMS needs, with a fully integrated pipeline
            of ISO 27001 controls and automated verifications. Vegeel
            streamlines the ISMS process by centralizing risks, controls, and
            checks, making ISO 27001 assessments incredibly simple.
          </h2>
          <h2 className="text-[1.25rem] leading-[28px] pt-[20px] hidden pb-5 lg:flex">
            Adopt Vegeel for your ISMS needs, with a fully integrated pipeline
            of ISO 27001 controls and automated <br /> verifications. Vegeel
            streamlines the ISMS process by centralizing risks, controls, and
            checks, making ISO <br /> 27001 assessments incredibly simple.
          </h2>
          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 lg:pt-7 px-7 w-full lg:w-[55%]">
            <Button
              onClick={openBookADemoModal}
              variant="contained"
              size="large"
              icon={false}
            >
              Request demo
            </Button>
            <Button
              onClick={openContactSalesModal}
              variant="plain"
              size="large"
              icon
              iconType="arrow"
            >
              Contact sales
            </Button>
          </div>
          <div className="pt-8 lg:pt-12 lg:px-9 w-full">
            <img src={bigScreenFrame} alt="" className="w-full h-full" />
          </div>
        </div>
        <div className="pb-7 pt-4 lg:pt-[50px] w-full">
          <div className="h-[3px] w-full bg-gradient-to-r from-[#16174d0e] via-[#2d2b54] to-[#1516450c]"></div>
          <div className="flex justify-center  py-10">
            <div className="flex flex-col gap-[1rem]  items-start lg:flex-row lg:items-center  lg:justify-between lg:w-[80%] ">
              <div className="flex items-center gap-3 justify-center">
                <div>
                  <img
                    src={shieldIcon}
                    alt="shieldIcon"
                    className="lg:w-[1.875rem] h-[1.875rem]"
                  />
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400] lg:hidden">
                  Stacked policies and customizeda <br />
                  templates.
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400] lg:text-[1.125rem]  hidden lg:block">
                  Stacked policies and <br /> customizeda templates.
                </div>
              </div>

              <div className="flex items-center gap-3 justify-center">
                <div>
                  <img
                    src={networkIcom}
                    alt="network icon"
                    className="lg:w-[1.875rem] h-[1.875rem]"
                  />
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400] lg:text-[1.125rem]  hidden lg:block">
                  One-stop risk and control <br /> visibility
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400]  lg:hidden">
                  One-stop risk and control visibility
                </div>
              </div>

              <div className="flex items-center gap-3 justify-center pt-2">
                <div>
                  <img
                    src={monitorIcon}
                    alt="monitor icon"
                    className="lg:w-[1.875rem] h-[1.875rem]"
                  />
                </div>
                <div className="text-[#ffff] text-[0.875rem] lg:text-[1.125rem] font-[400] hidden lg:block">
                  Continuous control <br /> monitoring
                </div>
                <div className="text-[#ffff] text-[0.875rem] font-[400] lg:hidden">
                  Continuous control monitoring
                </div>
              </div>
            </div>
          </div>

          <div
            className="h-[3px] w-full bg-gradient-to-r from-[#2d2b54] via-[#16174d0e]"
            style={{
              backgroundImage:
                "linear-gradient(to right, #16174d0e 0%, #2d2b54 30%, #16174d0e 80%)",
            }}
          ></div>
        </div>
      </div>

      <section className="py-[48px] lg:py-[64px]">
        <div className="flex flex-col gap-[2rem] lg:flex-row-reverse lg:items-center lg:w-[80%] lg:justify-between lg:mx-auto">
          <div className="flex justify-center lg:w-1/2">
            <img
              src={isoCertification}
              alt="iso certification"
              className="w-full h-full"
            />
          </div>

          <div className="px-4 flex flex-col gap-[1rem] lg:gap-[20px] lg:w-1/2">
            <div>
              <p className="text-[#EA6F03] font-medium text-[0.75rem] lg:text-[1rem] rounded-[1.5625rem] px-[15px] py-[10px] bg-[#FFFAEB] w-[13.125rem] lg:w-[299px] h-[40px] lg:h-[48px] lg:flex lg:justify-center lg:items-center">
                AI-driven ISO 27001 compliance
              </p>
            </div>

            <h1 className="text-[#060B59] text-[1.72rem] font-bold lg:text-[3rem] lg:leading-[64px]">
              Get ready for ISO 27001 certification with Vegeel
            </h1>

            <p className="leading-[1.75rem] text-[1rem] text-[#455468] font-[400]">
              Deploy Vegeel to construct a cohesive pipeline of ISO 27001
              controls and automated verifications, fortifying your ISMS. Vegeel
              seamlessly gathers compliance evidence with auditor-approved
              methods, propelling your ISO 27001 audit and certification journey
              without interruptions or excessive expenses.
            </p>

            <div>
              <Button
                variant="contained"
                onClick={openBookADemoModal}
                icon={true}
                iconType="arrowWhite"
                size="small"
              >
                Request demo
              </Button>
            </div>
          </div>
        </div>

        <div className="pt-[48px] flex flex-col gap-[2rem] lg:hidden">
          <Card
            header="Documentation"
            majorIcon={blueFileIcon}
            subHead1="VEGEEL IMPLEMENTATION"
            subHead2="VEGEEL ADVANTAGE"
            subtext1="Connect to Vegeel’s policy documentations and editable templates to help develop your organizations policy documents and other necessary evidence effortlessly all in one place. This keeps you on track to compliance."
            list1="Ready to use policies and templates"
            list2="Efficient evidence management"
          />
          <Card
            header="Real time Monitoring"
            majorIcon={blueFileSearchIcon}
            subHead1="VEGEEL IMPLEMENTATION"
            subHead2="VEGEEL ADVANTAGE"
            subtext1="Evaluate your ISMS to determine the effectiveness of policies, processes, and controls in line with ISO 27001. Conduct automated checks to monitor controls in real-time, identify gaps, and implement corrective actions."
            list1="Continuous compliance monitoring"
            list2="Efficiency and accuracy"
          />
          <Card
            header="Accreditation Audit"
            majorIcon={blueStarICon}
            subHead1="VEGEEL IMPLEMENTATION"
            subHead2="VEGEEL ADVANTAGE"
            subtext1="Work with an accredited Vegeel partner auditor for certification. Leverage Vegeel's ISMS and platform evidence to illustrate framework-compliant security practices and confirm compliance."
            list1="Expert audit guidance"
            list2="Streamlined certification process"
          />
          <Card
            header="Compliance Review"
            majorIcon={blueShieldIcon}
            subHead1="VEGEEL IMPLEMENTATION"
            subHead2="VEGEEL ADVANTAGE"
            subtext1="Oversee controls on Vegeel dashboard. Continuously refine policies and processes as you expand, with Vegeel providing automatic mapping and tracking of all improvements."
            list1="Proactive control management"
            list2="Improvement tracking"
          />
        </div>

        <div className="hidden lg:flex pt-[48px]">
          <div className="w-[80%] h-[45.625rem] mx-auto">
            <div className=" w-full h-[4.375rem] flex items-center rounded-t-[1.875rem] mx-auto">
              <div className="w-[25%]  h-full bg-[#EAECF0] flex flex-col justify-center  items-center rounded-tl-[1.875rem] font-[500] text-[#060B59] text-[1.5rem]">
                <div>
                  <p> Features</p>
                </div>
              </div>

              <div className="w-[51%] h-full bg-[#E5EAFC] flex justify-center items-center  font-[500] text-[#060B59] text-[1.5rem]">
                Vegeel implementation
              </div>

              <div className="w-[26%] h-full bg-[#1B4DFF] flex justify-center items-center rounded-tr-[1.875rem] font-[500] text-white text-[1.5rem]">
                Vegeel advantage
              </div>
            </div>

            <div className="flex w-full">
              <div className="flex flex-col w-[25%]  py-[1.563rem] px-[1rem] gap-[1rem] bg-[#FAFAFA] rounded-bl-[1.875rem]">
                <div className="flex items-start gap-3 justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] border-b-[1px] border-[#455468]">
                  <div className="flex justify-start items-center gap-4">
                    <div className="w-[30px] h-[30px]">
                      <img src={bigScreenBLuefileICon} alt="file icon" />
                    </div>
                    <p className="text-[#111111] leading-[34px] text-[1.25rem] font-[500]">
                      {" "}
                      Documentation
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3 justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] border-b-[1px] border-[#455468]">
                  <div className="flex justify-start items-center gap-4">
                    <div className="w-[30px] h-[30px]">
                      <img src={bigScreenSearchFile} alt="file icon" />
                    </div>

                    <p className="text-[#111111] leading-[34px] text-[1.25rem] font-[500]">
                      Real time <br /> Monitoring
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3 justify-start px-[0.938rem] pb-[1rem]  w-full h-[9.188rem] border-b-[1px] border-[#455468]">
                  <div className="flex justify-start items-center gap-4">
                    <div className="w-[30px] h-[30px]">
                      <img src={bigScreenStar} alt="star icon" />
                    </div>

                    <p className="text-[#111111] leading-[34px] text-[1.25rem] font-[500]">
                      Accreditation <br /> Audit
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3 justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem]">
                  <div className="flex justify-start items-center gap-4">
                    <div className="w-[30px] h-[30px]">
                      <img src={bigScreenShield} alt="shield icon" />
                    </div>
                    <p className="text-[#111111] leading-[34px] text-[1.25rem] font-[500]">
                      Compliance <br /> Review
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-[51%]  py-[1.563rem] px-[1rem] gap-[1rem] bg-[#EDF1FF]">
                <div className="flex items-center  justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] mx-auto border-b-[1px] border-[#455468]">
                  <p className="text-[#111111] font-[500] text-[1.125rem] leading-[30px]">
                    Connect to Vegeel’s policy documentations and editable
                    templates to help develop your organizations policy
                    documents and other necessary evidence effortlessly all in
                    one place. This keeps you on track to compliance.
                  </p>
                </div>
                <div className="flex items-center  justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] mx-auto border-b-[1px] border-[#455468]">
                  <p className="text-[#111111] font-[500] text-[1.125rem] leading-[30px]">
                    Evaluate your ISMS to determine the effectiveness of
                    policies, processes, and controls in line with ISO 27001.
                    Conduct automated checks to monitor controls in real-time,
                    identify gaps, and implement corrective actions.
                  </p>
                </div>
                <div className="flex items-center  justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] mx-auto border-b-[1px] border-[#455468]">
                  <p className="text-[#111111] font-[500] text-[1.125rem] leading-[30px]">
                    Work with an accredited Vegeel partner auditor for
                    certification. Leverage Vegeel's ISMS and platform evidence
                    to illustrate framework-compliant security practices and
                    confirm compliance.
                  </p>
                </div>
                <div className="flex items-center  justify-start px-[0.938rem] pb-[1.75rem]  w-full h-[9.188rem] mx-auto">
                  <p className="text-[#111111] font-[500] text-[1.125rem] leading-[30px]">
                    Oversee controls on Vegeel dashboard. Continuously refine
                    policies and processes as you expand, with Vegeel providing
                    automatic mapping and tracking of all improvements.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-[26%] bg-[#0527aa] py-[1.563rem] px-[1rem] gap-[16px] rounded-br-[1.875rem]">
                <div className="flex items-start justify-center h-[9.188rem] pb-[1.75rem]  w-full  border-b-[1px] border-[#ffff]">
                  <div className="flex flex-col justify-center gap-[1rem]">
                    <div className="flex items-center gap-3 justify-start">
                      <div className="flex items-center justify-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-[20px]"
                        />
                      </div>

                      <p className="text-[#ffff] tracking-wide font-medium text-[1.125rem] leaing-[30px] w-[90%]">
                        Ready to use policies and templates
                      </p>
                    </div>

                    <div className="flex items-center gap-3 justify-start">
                      <div className="flex justify-center items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-[20px]"
                        />
                      </div>

                      <p className="text-[#ffff] tracking-wide font-medium text-[1.125rem] leaing-[30px] w-[90%]">
                        Efficient evidence management
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-start justify-center h-[9.188rem] pb-[1.75rem]  w-full  border-b-[1px] border-[#ffff]">
                  <div className="flex flex-col justify-center gap-[1rem]">
                    <div className="flex items-center gap-3 justify-start">
                      <div className="flex items-center justify-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-[20px]"
                        />
                      </div>

                      <p className="text-[#ffff] tracking-wide font-medium text-[1.125rem] leaing-[30px] w-[90%]">
                        Continuous compliance monitoring
                      </p>
                    </div>
                    <div className="flex items-center gap-3 justify-start">
                      <div className="flex items-center justify-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-[20px]"
                        />
                      </div>

                      <p className="text-[#ffff] tracking-wide font-medium text-[1.125rem] leaing-[30px] w-[90%]">
                        Efficient and accuracy
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-start justify-center h-[9.188rem] pb-[1.75rem]  w-full  border-b-[1px] border-[#ffff]">
                  <div className="flex flex-col justify-center gap-[1rem]">
                    <div className="flex items-center gap-3 justify-start">
                      <div className="flex items-center justify-start w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-[20px]"
                        />
                      </div>

                      <p className="text-[#ffff] tracking-wide font-medium text-[1.125rem] leaing-[30px] w-[90%]">
                        Expert audit guidance
                      </p>
                    </div>
                    <div className="flex items-center gap-3  justify-start">
                      <div className="flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5"
                        />
                      </div>

                      <p className="text-[#ffff] tracking-wide font-medium text-[1.125rem] leaing-[30px] w-[90%]">
                        Streamlined certification process
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-start justify-center h-[9.188rem]">
                  <div className="flex flex-col justify-center gap-[1rem]">
                    <div className="flex items-center gap-3 justify-start">
                      <div className="flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5"
                        />
                      </div>

                      <p className="text-[#ffff] tracking-wide font-medium text-[1.125rem] leaing-[30px] w-[90%]">
                        Proactive control management
                      </p>
                    </div>
                    <div className="flex items-center gap-3 justify-start">
                      <div className="flex items-center w-[10%]">
                        <img
                          src={whiteCheckBox}
                          alt="shieldIcon"
                          className="w-5"
                        />
                      </div>

                      <p className="text-[#ffff] tracking-wide font-medium text-[1.125rem] leaing-[30px] w-[90%]">
                        Improvement tracking
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-[38px] lg:py-[64px]">
        <div className="px-5 pt-4 flex justify-center items-center flex-col">
          <h1 className="text-[#060B59] text-[1.72rem] lg:text-[3rem] font-[700] text-center pb-[16px] lg:pb-[20px]">
            ISO 27001 with Vegeel vs <br className="lg:hidden" /> Others
          </h1>
          <p className="text-[1rem] text-center lg:text-[1.25rem] text-[#455468] lg:leading-[34px]">
            Our platform stands out as one of the best security compliance
            software because of it’s simple yet{" "}
            <br className="hidden lg:flex" /> efficient features.
          </p>
        </div>

        <div className="px-5 py-[48px] hidden lg:flex">
          <div className="relative h-[80vh] w-full flex justify-center items-center flex-col">
            <motion.div
              initial={{
                x: 0,
                y: 0,
              }}
              animate={{
                y: hovered1 ? -183 : 0,
              }}
              transition={{ type: "tween", stiffness: 300, delay: 0.3 }}
              className="absolute top-[0px] w-[858px] z-10"
              onHoverStart={() => setHovered1(true)}
            >
              <div className="w-[100%] bg-[#1B4DFF] rounded-l-[15px] rounded-r-[15px] p-[20px] flex justify-between items-start cursor-pointer">
                <div className="w-[45%]">
                  <h1 className="text-[2.1rem] text-[#FFFFFF] font-bold leading-[50px] pb-[12px]">
                    Policy documentation <br /> and templates
                  </h1>
                  <p className="text-[1.125rem] text-[#FFFFFF]">
                    Vegeel stands out from other security compliance platforms
                    by offering{" "}
                    <span className="font-medium">
                      {" "}
                      wide range of editable documentation templates and
                      policies to suit your organization.
                    </span>{" "}
                  </p>
                </div>
                <div className="w-[50%]">
                  <img
                    src={policyDocumentationAndTempmlates}
                    alt="img"
                    className="w-full h-full"
                  />
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{
                x: 0,
                y: 0,
              }}
              animate={{
                y: hovered2 ? -193 : 0,
              }}
              transition={{ type: "tween", stiffness: 300, delay: 0.3 }}
              className="absolute top-[95px] w-[992px] z-10"
              onHoverStart={() => setHovered2(true)}
            >
              <div className="w-[100%] bg-[#042BB2] rounded-l-[15px] rounded-r-[15px] p-[20px] flex justify-between items-start cursor-pointer">
                <div className="w-[45%]">
                  <h1 className="text-[2.1rem] text-[#FFFFFF] font-bold leading-[50px] pb-[12px]">
                    GRC management <br /> tool
                  </h1>
                  <p className="text-[1.125rem] text-[#FFFFFF]">
                    Vegeel is the best compliance automation platform that
                    allows company to
                    <span className="font-medium">
                      {" "}
                      build their dream GRC team, assign task, add personnel,
                      monitor and track progress of team members.
                    </span>{" "}
                  </p>
                </div>
                <div className="w-[50%]">
                  <img src={grcImg} alt="img" className="w-full h-full" />
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{
                x: 0,
                y: 0,
              }}
              animate={{
                y: hovered3 ? -210 : 0,
              }}
              transition={{ type: "tween", stiffness: 300, delay: 0.3 }}
              className="absolute top-[195px] w-[1068px] z-10"
              onHoverStart={() => setHovered3(true)}
              onHoverEnd={() => {
                setHovered1(false);
                setHovered2(false);
                setHovered3(false);
              }}
            >
              <div className="w-[100%] bg-[#041D73] rounded-l-[15px] rounded-r-[15px] p-[20px] flex justify-between items-start cursor-pointer">
                <div className="w-[45%]">
                  <h1 className="text-[2.1rem] text-[#FFFFFF] font-bold leading-[50px] pb-[12px]">
                    Dedicated expert <br /> support
                  </h1>
                  <p className="text-[1.125rem] text-[#FFFFFF]">
                    Vegeel understands different contexts and uniqueness of
                    organizations and
                    <span className="font-medium">
                      {" "}
                      provides dedicated 24/7 expert support,
                    </span>{" "}
                    to ensure the success of your audit and make sure you met
                    compliance standards.
                  </p>
                </div>
                <div className="w-[48%]">
                  <img src={dedicatedImg} alt="img" className="w-full h-full" />
                </div>
              </div>
            </motion.div>

            <div className="w-full bg-[#ffffff] z-10 absolute top-[300px] py-[45px] px-[110px]">
              <div className="border border-[#D7DFE9] rounded-[15px] py-[30px] px-[70px] flex justify-around items-center">
                <div className="w-[20%]">
                  <div className="flex justify-center items-center gap-2">
                    <div className="">
                      <img src={blueFolderIcon} alt=" folder icon" />
                    </div>
                    <p className="text-[#060B59] font-bold text-[2.25rem]">
                      {"> 150"}
                    </p>
                  </div>
                  <p className="text-[#111111] text-[1rem] text-center leading-[28px] pt-[8px]">
                    Customized policies <br /> and templates
                  </p>
                </div>
                <div className="w-[20%]">
                  <div className="flex justify-center items-center gap-2">
                    <div className="">
                      <img src={blueChartIcon} alt=" folder icon" />
                    </div>
                    <p className="text-[#060B59] font-bold text-[2.25rem]">
                      {"> 95%"}
                    </p>
                  </div>
                  <p className="text-[#111111] text-[1rem] text-center leading-[28px] pt-[8px]">
                    Efficient security <br /> compliance
                  </p>
                </div>
                <div className="w-[20%]">
                  <div className="flex justify-center items-center gap-[12px]">
                    <div className="">
                      <img src={blueMarkIcon} alt=" folder icon" />
                    </div>
                    <p className="text-[#060B59] font-bold text-[2.25rem]">
                      {"100%"}
                    </p>
                  </div>
                  <p className="text-[#111111] text-[1rem] text-center leading-[28px] pt-[8px]">
                    Audit readiness <br /> and success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:hidden py-[28px] flex flex-col gap-[2rem] w-[95%]  m-auto pb-[3rem]">
          <BlueCard
            header="Policy documentation and "
            subheader="templates"
            image={documentTemplateImage}
            text="Vegeel stands out from other security compliance platforms by offering wide range of"
            spanText="editable documentation templates and policies"
            endText="to suit your organization."
            bg={"bg-[#1B4DFF]"}
          />
          <BlueCard
            header="GRC management tool"
            image={taskMangementImage}
            text="Vegeel is the best compliance automation platform that allows company to"
            spanText="build their dream GRC team, assign task, add personnel, monitor and track progress of team members."
            bg={"bg-[#042BB2]"}
          />
          <BlueCard
            header="Dedicated expert support"
            image={controlsImage}
            text="Vegeel understands different contexts and uniqueness of organizations and provides"
            spanText="dedicated 24/7 expert support,"
            bg={"bg-[#041D73]"}
            endText="to ensure the success of your audit and make sure you met compliance standards."
          />

          <div className=" flex flex-col items-center gap-[1.125rem] bg-[#FFFFFF] border-[1px] border-[#D7DFE9] rounded-[0.938rem] p-[1.35rem]">
            <div className="flex flex-col gap-[2rem] w-full">
              <div className="flex flex-col gap-3">
                <div className="flex justify-center items-center  gap-3">
                  <div>
                    <img src={blueFolderIcon} alt=" folder icon" />
                  </div>
                  <div>
                    <p className="text-[#060B59] font-[700] text-[1.75rem]">
                      {`> 150`}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[#111111] text-[1rem] text-center ">
                    Customized policies and templates
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex justify-center items-center  gap-3">
                  <div>
                    <img src={blueChartIcon} alt=" chart icon" />
                  </div>
                  <div>
                    <p className="text-[#060B59] font-[700] text-[1.75rem]">
                      {`> 95%`}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[#111111] text-[1rem] text-center ">
                    Efficient security compliance
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex justify-center items-center  gap-3">
                  <div>
                    <img src={blueMarkIcon} alt=" mark icon" />
                  </div>
                  <div>
                    <p className="text-[#060B59] font-[700] text-[1.75rem]">
                      {`> 100%`}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[#111111] text-[1rem] text-center ">
                    Audit readiness and success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex justify-center items-center flex-col gap-[2rem]  py-[3rem] lg:pb-16 px-4 bg-[#EDF1FF]">
        <div className="bg-[#1B4DFF] rounded-[0.938rem] py-[1.875rem] lg:pt-[64px] px-[1.25rem] lg:px-[84px] flex justify-center items-center flex-col gap-[1.313rem] w-[97%] lg:rounded-[1.875rem] lg:gap-[20px]">
          <p className="text-[#EA6F03] lg:w-[206px] text-center font-medium text-[0.75rem] lg:text-[1rem] rounded-[1.5625rem] px-[0.938rem] py-[0.625rem] bg-[#FFFAEB] ">
            Vegeel’s advantage
          </p>
          <div className="lg:pb-[48px]">
            <h1 className="font-bold text-[1.75rem] lg:text-[3rem] text-center text-[#FFFFFF] pb-[20px]">
              Achieve seamless <br className="lg:hidden" />
              ISMS compliance
            </h1>

            <p className="text-[1rem] lg:text-[1.25rem] text-center leading-[1.75rem] lg:leading-[2.125rem] text-white pb-[28px] lg:pb-[48px]">
              Veegeel offers unparalleled advantages in achieving ISMS
              compliance. With precise risk <br className="hidden lg:flex" />{" "}
              detection, automated control recommendations, and comprehensive
              monitoring, Vegeel ensures <br className="hidden lg:flex" /> your
              organization meets ISO 27001 standards effortlessly and
              efficiently.
            </p>

            <div className="flex flex-col gap-[2rem] lg:flex-row lg:gap-[45px] ">
              <LightBLueCard
                Lcheader="Access to policy"
                Lcheader2="document"
                header="Access to policy document"
                icon={blueFileIcon}
                subHead="Vegeel's platform offers ready-to-use policy and documentation templates, fully customizable to meet your organization's specific needs and ISMS scope."
                height="374"
              />
              <LightBLueCard
                Lcheader="Auditor-friendly"
                Lcheader2=" ISMS"
                header="Auditor-friendly ISMS"
                icon={blueBookIcon}
                subHead="Vegeel guarantees coverage of Annex A controls, allowing for customization and addition of controls to fit your organization's specific needs and ISMS scope."
                height="374"
              />
              <LightBLueCard
                Lcheader="Step by step"
                Lcheader2="implementation guide"
                header="Step by step"
                header2="implementation guide"
                icon={blueWrenchIcon}
                subHead="Vegeel offers comprehensive implementation guidelines for every control, ensuring you understand the necessary actions to achieve compliance."
                height="374"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[48px] bg-[#040B4E]">
        <div className="flex justify-center items-center flex-col">
          <div className="flex justify-center items-center bg-[#93AAFD1A] border border-[#FFFFFF4D] w-[300px] lg:370px h-[44px] lg:h-[48px] rounded-full py-[12px] px-[15px]">
            <span className="pr-2">
              <img src={homeRocketIcon} alt="rocket" />
            </span>
            <h3 className="text-[0.75rem] text-[#93AAFD]">
              Begin your journey to compliance efficiency
            </h3>
          </div>
          <h2 className="text-[3rem] lg:text-[3.5rem] font-bold text-[#FFFFFF] text-center px-5 leading-[56px] lg:leading-[64px] pt-3">
            Elevate your <br className="lg:hidden" />
            <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent">
              security
            </span>{" "}
            and <br />
            <span className="gradient-text bg-gradient-to-l-custom bg-clip-text text-transparent">
              compliance
            </span>{" "}
            posture with <br className="lg:hidden" /> Vegeel
          </h2>
          <p className="text-[#FAFAFA] text-[1rem] leading-[28px] text-center pt-4">
            Join the ranks of forward-thinking <br className="lg:hidden" />{" "}
            organizations that trust Vegeel to fortify <br /> their security and
            compliance efforts.
          </p>
          <div className="flex justify-center items-center gap-4 lg:gap-6 flex-col lg:flex-row pt-5 px-7 w-full lg:w-[40%]">
            <Button
              onClick={openBookADemoModal}
              variant="contained"
              size="large"
              icon
            >
              Request demo
            </Button>
            <Button
              onClick={openContactSalesModal}
              variant="plain"
              size="large"
              icon
              iconType="arrow"
            >
              Contact sales
            </Button>
          </div>

          <div className="pt-14 w-full h-full lg:hidden">
            <img
              src={homeDashboardImg}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
          <div className="pt-14 w-full h-full hidden lg:flex lg:px-[4.5rem]">
            <img
              src={homeDashboardImgTwo}
              alt="dashboard"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
