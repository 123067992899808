import React from "react";

export default function Datasecurity() {
  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <h1 className="font-[700] text-[28px] text-dark_color">
          Data Security
        </h1>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Security Measures
        </h2>
        <p className="text-dark_color text-[16px]">
          We employ a comprehensive range of security measures to protect your
          personal information fromunauthorized access, disclosure, alteration,
          and destruction. These measures are regularly reviewed and updated to
          address emerging threats and vulnerabilities. Here are some of the key
          securitypractices we follow:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Encryption</p>
            <ul className="list-disc">
              <li>
                Data in Transit: We use industry-standard encryption protocols,
                such as Transport Layer Security [TLS], to protect data
                transmitted between your device and our servers. This ensures
                that any information you share with us is encrypted and cannot
                be intercepted by unauthorized parties.
              </li>
              <li>
                Data at Rest: Sensitive data stored on our servers are stored in
                pdf/doc file in our platform. Similarly, all confidential
                documents like policies, plans, and procedures are stored on our
                platform and is encrypted using robust encryption algorithms.
                This adds an extra layer of protection in the unlikely event
                that our storage systems are compromised.
              </li>
            </ul>
          </li>
          <li>
            <p>Access Controls</p>
            <ul className="list-disc">
              <li>
                Role-Based Access: We implement role-based access controls to
                ensure that only authorized personnel have access to your
                personal information. Access is granted based on job
                responsibilities and is restricted to the minimum necessary to
                perform their duties.
              </li>
              <li>
                Multi-Factor Authentication [MFA]: We require multi-factor
                authentication for accessing our internal systems and sensitive
                data repositories. MFA adds an additional layer of security by
                requiring multiple forms of verification.
              </li>
            </ul>
          </li>
          <li>
            <p>Regular Security Audits and Assessments</p>
            <ul className="list-disc">
              <li>
                Internal Audits: We conduct regular internal audits to evaluate
                our security practices and ensure compliance with our security
                policies and procedures. These audits help identify potential
                weaknesses and areas for improvement.
              </li>
              <li>
                Third-Party Assessments: We engage independent security firms to
                perform regular penetration testing and security assessments.
                These external evaluations provide an objective view of our
                security posture and help us stay ahead of potential threats.
              </li>
            </ul>
          </li>
          <li>
            <p>Firewalls and Intrusion Detection Systems</p>
            <ul className="list-disc">
              <li>
                Network Security: Our network is protected by advanced firewalls
                that monitor and control incoming and outgoing traffic based on
                predetermined security rules. These firewalls help prevent
                unauthorized access to our systems.
              </li>
              <li>
                Intrusion Detection and Prevention: We deploy intrusion
                detection and prevention systems [IDPS] to monitor network
                traffic for suspicious activity. These systems can detect and
                respond to potential threats in real time.
              </li>
            </ul>
          </li>
          <li>
            <p>Data Minimization and Retention</p>
            <ul className="list-disc">
              <li>
                Minimization: We collect and process only the personal
                information necessary for the purposes outlined in our Privacy
                Policy. By minimizing the amount of data we handle, we reduce
                the risk of exposure.
              </li>
              <li>
                Retention: Personal information is retained only for as long as
                necessary to fulfill the purposes for which it was collected,
                comply with legal obligations, or protect our legal interests.
                Data that is no longer needed is securely deleted or anonymized.
              </li>
            </ul>
          </li>
          <li>
            <p>Employee Training and Awareness</p>
            <ul className="list-disc">
              <li>
                Security Training: All employees undergo regular security
                training to stay informed about best practices and emerging
                threats. Training covers topics such as data protection,
                phishing prevention, and secure handling of sensitive
                information.
              </li>
              <li>
                Awareness Programs: We run ongoing security awareness programs
                to reinforce the importance of data security and ensure that all
                employees remain vigilant.
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="font-bold text-dark_color text-[20px] ">
          No Guarantee of Security
        </h2>
        <p className="text-dark_color text-[16px]">
          While we take extensive measures to protect your personal information,
          it is important tounderstand that no security system is infallible.
          There are inherent risks associated with thetransmission and storage
          of data, and we cannot guarantee the absolute security of your
          information.Here are some factors to consider:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Evolving Threat Landscape</p>
            <ul className="list-disc">
              <li>
                Cyber Threats: The cyber threat landscape is constantly
                evolving, with new and sophisticated attack methods emerging
                regularly. Despite our best efforts, there is always a
                possibility that our security measures could be circumvented by
                advanced threats.
              </li>
              <li>
                Zero-Day Vulnerabilities: Zero-day vulnerabilities are security
                flaws that are unknown to the software vendor and can be
                exploited by attackers before a fix is available. These
                vulnerabilities pose significant risks, as they are difficult to
                defend against.
              </li>
            </ul>
          </li>
          <li>
            <p>Human Error</p>
            <ul className="list-disc">
              <li>
                User Behavior: The security of your personal information also
                depends on your actions. Using weak passwords, falling victim to
                phishing scams, or sharing sensitive information can compromise
                your data security. We encourage you to follow best practices
                for online security and remain vigilant.
              </li>
              <li>
                Internal Mistakes: While we strive to maintain a high standard
                of security, human error on the part of our employees can
                sometimes lead to unintentional breaches. We have procedures in
                place to quickly address and mitigate the impact of such
                incidents.
              </li>
            </ul>
          </li>
          <li>
            <p>Third-Party Risks</p>
            <ul className="list-disc">
              <li>
                Service Providers: We work with third-party service providers to
                deliver certain services and support our operations. While we
                require these providers to adhere to strict security standards,
                we cannot guarantee their security practices in every instance.
              </li>
              <li>
                Integration Partners: If you choose to integrate our services
                with third-party applications or platforms, the security of your
                data may be influenced by the security practices of those third
                parties.
              </li>
            </ul>
          </li>
          <li>
            <p>Legal and Regulatory Requirements</p>
            <ul>
              <li>
                Disclosure Obligations: In certain circumstances, we may be
                legally obligated to disclose your personal information to
                comply with regulatory requirements or legal processes. While we
                take steps to ensure such disclosures are made securely, they
                inherently involve sharing your data.
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="font-bold text-dark_color text-[20px] ">
          International Data Transfers
        </h2>
        <p className="text-dark_color text-[16px]">
          At Vegeel Technology Limited, we are committed to ensuring the
          protection of your personal information, even when it is transferred
          across international borders. Here we practices regarding
          international data transfers, including the mechanisms we use to
          safeguard your data and the importance of obtaining user consent, in
          compliance with the Nigeria Data Protection Regulation [NDPR] and
          other applicable laws. When we transfer your data to third countries,
          we take steps to ensure that appropriate safeguards are in place to
          protect your information in accordance with this Privacy Policy and
          NDPR requirements. We ensure data protection through the
          implementation of AWS's robust security measures, adherence to
          international data protection standards to comply with data transfer
          regulations.
        </p>
        <h3 className="font-bold text-dark_color text-[20px] ">
          Transfer Mechanisms
        </h3>
        <p className="text-dark_color text-[16px]">
          Transferring personal data internationally is sometimes necessary to
          provide our services, maintain our operations, and support our global
          customer base. We implement robust transfer mechanisms to ensure that
          your personal information is protected regardless of where it is
          processed. Here are thekey mechanisms we use:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Adequacy Decisions</p>
            <ul className="list-disc">
              <li>
                Definition: An adequacy decision is a determination by the
                relevant regulatory authority that a foreign country, territory,
                or sector provides a level of data protection that is
                essentially equivalent to that of the NDPR.
              </li>
              <li>
                Application: When transferring personal data to countries with
                adequacy decisions, we rely on this determination to ensure that
                your information receives an appropriate level of protection.
              </li>
            </ul>
          </li>
          <li>
            <p>Standard Contractual Clauses [SCCs]</p>
            <ul className="list-disc">
              <li>
                Purpose: secs are standardized contractual terms approved by
                regulatory authorities that ensure adequate safeguards for
                personal data transferred outside of Nigeria.
              </li>
              <li>
                Implementation: We incorporate secs into our contracts with
                third parties and affiliates located in countries that do not
                have adequacy decisions. This legally binding commitment ensures
                that your data is protected according to NDPR standards.
              </li>
            </ul>
          </li>
          <li>
            <p>Binding Corporate Rules [BCRs]</p>
            <ul className="list-disc">
              <li>
                Definition: BCRs are internal policies adopted by multinational
                companies to ensure that personal data transferred within the
                corporate group, including to entities outside Nigeria, is
                protected.
              </li>
              <li>
                Adoption: We adopt BCRs to regulate the transfer of personal
                data between our global offices and affiliates. These rules are
                approved by regulatory authorities and provide a high level of
                protection for your data.
              </li>
            </ul>
          </li>
          <li>
            <p>Privacy Shield Framework</p>
            <ul className="list-disc">
              <li>
                Relevance: While the Privacy Shield Framework was a mechanism
                used for data transfers between the EU and the U.S., it can
                serve as an example of how we align with international
                standards.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
}
