import { useEffect, useState } from "react";
import arrowLeft from "../../assets/icons/blog-detail-arrow-left.svg";
import avatarOne, {
  ReactComponent as Avatar,
} from "../../assets/icons/blog-detail-avatar.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/blog-detail-link-icon.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/blog-detail-linkedin-icon.svg";
import { ReactComponent as Twitter } from "../../assets/icons/blog-detail-x-icon.svg";
import blogMainImg from "../../assets/images/blogOnIso.jpg";
import CustomIcon from "../../components/CustomIcon";
import { BlogPost } from "../Blog/All";
import { useNavigate } from "react-router-dom";
import copyLinkIcon from "../../assets/icons/blog-detail-desktop-link-angled.svg";
import arrowUpRight from "../../assets/icons/blog-details-up-right.svg";
import { ReactComponent as Times } from "../../assets/icons/times.svg";
import { useMenuContext } from "../../context";

export default function BlogDetailEight() {
  const navigate = useNavigate();
  const { closeDropdown } = useMenuContext();
  const linkValue = "https://www.vegeel.com/";
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = () => {
    navigate(-1);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(linkValue);
      setShowCopyMessage(true);
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div onClick={() => closeDropdown()}>
      <div className=" pt-[2rem] lg:pt-[6rem] px-[18px] lg:px-[5rem] flex justify-start lg:justify-center items-start lg:items-start flex-col">
        <div
          onClick={handleNavigate}
          className="gap-2 text-[1rem] text-[#060B59] flex justify-center items-center font-medium hero-blog-detail-go-back-border py-[8px] px-[13px] lg:px-[16px] rounded-[6px] cursor-pointer"
        >
          <span>
            <img src={arrowLeft} alt="icon" />
          </span>
          Back to blog
        </div>
        <div className="pt-[32px] lg:px-[3.5rem] ">
          <h1 className="text-[#060B59] text-[2.375rem] lg:text-[3.75rem] font-bold pb-[16px] lg:pb-[24px] leading-[60px]">
            Understanding ISO 27001: The Ultimate Guide to Cybersecurity
            Compliance
          </h1>
          <div className="lg:hidden">
            <div className="pt-[16px] flex justify-start items-center gap-2">
              <CustomIcon SvgIcon={Avatar} size={28} />
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <div className="flex justify-between items-center pt-[16px]">
              <h1 className="text-[#455468] text-[1rem]">
                Wednesday, August 11, 2024
              </h1>
              <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
                Framework
              </h2>
            </div>
          </div>
          <div className="hidden lg:flex lg:justify-start lg:items-center lg:gap-3">
            <div className="flex justify-start items-center gap-2">
              <div className="h-[32px] w-[32px]">
                <img src={avatarOne} alt="avater" className="w-full h-full" />
              </div>
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>
            <h1 className="text-[#455468] text-[1rem]">
              Wednesday, September 04, 2024
            </h1>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>

            <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
              Framework
            </h2>
          </div>
        </div>
        <div className="pt-[16px] w-full lg:px-[3.5rem]">
          <img
            src={blogMainImg}
            alt="blog-main"
            className="rounded-[15px] w-full h-full"
          />
        </div>

        <div className="lg:py-[48px] lg:flex lg:justify-between lg:items-start lg:px-[3.5rem]">
          <div className="lg:w-[28%]">
            <div className="pt-[32px] ">
              <h1 className="text-[#060B59] font-bold text-[1.125rem] lg:text-[1.25rem] pb-[8px] lg:pb-[16px]">
                Contents
              </h1>

              <div className="flex">
                <div className="relative">
                  <div className="absolute bg-[#E5EAFC] w-1 h-full rounded-[6px]">
                    <div
                      className={`absolute left-0 bg-blue-500 w-1 h-[50%] rounded-[6px]`}
                    ></div>
                  </div>
                </div>

                <div className="pl-[12px]">
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium  mb-[8px] lg:w-full">
                    Understanding ISO 27001: The Ultimate Guide to Cybersecurity
                    Compliance
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    What is ISO 27001?
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    Why is ISO 27001 Important?
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    Why is ISO 27001 Important?
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    How Does ISO 27001 Work?
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    What are the Key Components of ISO 27001?
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    Risks of Not Having ISO 27001
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium mb-[8px]">
                    CONCLUSION
                  </h1>
                </div>
              </div>
            </div>

            <div className="hidden lg:flex lg:pt-[64px] lg:flex-col lg:justify-start lg:items-start">
              <h1 className="text-[#060B59] text-[1.25rem] pb-[1rem]">
                Share article
              </h1>

              <h1 className="text-[#1B4DFF] text-[1rem] font-medium flex justify-start items-center gap-2 cursor-pointer">
                LinkedIn{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1 className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start items-center gap-2 cursor-pointer">
                Twitter{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1
                onClick={copyLink}
                className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start  items-center gap-2 cursor-pointer"
              >
                Copy link{" "}
                <span>
                  <img src={copyLinkIcon} alt="icon" />
                </span>
              </h1>
            </div>
          </div>

          <div className="pt-[32px] lg:px-[rem] lg:w-[68%]">
            <h1 className="text-[#111111] text-[2rem] lg:text-[2.25rem] font-bold leading-[36px] lg:leading-[50px] pb-[12px] lg:pb-[20px]">
              Understanding ISO 27001: The Ultimate Guide to Cybersecurity
              Compliance
            </h1>
            <p className="text-[#111111] text-[1rem] lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide">
              In today's digital age, cybersecurity is a top concern for
              businesses of all sizes. With the increasing number of cyber
              threats and data breaches, companies need to take proactive
              measures to protect their sensitive information. That's where ISO
              27001 comes in – a global standard for managing and protecting
              sensitive information.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              <strong className="block"> What is ISO 27001?</strong>
              ISO 27001 is an international standard published by the
              International Organization for Standardization (ISO). It provides
              a framework for establishing, implementing, maintaining, and
              continually improving an Information Security Management System
              (ISMS). The standard helps organizations protect their information
              assets, such as financial data, personal data, and intellectual
              property, from cyber threats.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              <strong className="block"> Why is ISO 27001 Important?</strong>
              In today's digital world, cybersecurity threats are increasing.
              Hackers are becoming more sophisticated, and data breaches are
              becoming more common. ISO 27001 helps you protect your business
              from:
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              1. Data breaches
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              2. Cyber attacks
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              3. Reputation damage
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              4. Financial losses
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              <strong className="block"> Benefits of ISO 27001</strong>
              1. Improved Security: Protect your data and reputation by
              implementing robust security controls.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              2. Compliance: Meet regulatory requirements and industry
              standards, reducing the risk of fines and penalties.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              3. Customer Trust: Show customers you take data security
              seriously, building trust and loyalty.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              4. Competitive Advantage: Stand out from competitors with robust
              cybersecurity, attracting new customers and business partners.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              5. Business Continuity: Ensure uninterrupted operations, reducing
              the risk of downtime and financial losses.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              <strong className="block"> How Does ISO 27001 Work? </strong>
              ISO 27001 is based on a risk management approach, which involves:
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              1. Risk Assessment: Identify potential security risks and threats.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              2. Risk Treatment: Implement controls to mitigate or manage
              identified risks.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              3. Monitoring and Review: Continuously monitor and review the
              effectiveness of controls.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              <strong className="block">
                What are the Key Components of ISO 27001?{" "}
              </strong>
              1. Context of the Organization: Understand the organization's
              internal and external context.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              2. Leadership: Demonstrate leadership commitment to information
              security.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              3. Planning: Establish information security objectives and
              processes.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              4. Support: Provide resources and support for information
              security.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              5. Operation: Implement and operate the ISMS.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              6. Performance Evaluation: Monitor and measure the effectiveness
              of the ISMS.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              7. Improvement: Continuously improve the ISMS.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              <strong className="block">Risks of Not Having ISO 27001</strong>
              1. Data Breaches: Lose sensitive information and face financial
              losses.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              2. Regulatory Fines: Non-compliance can lead to heavy penalties.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              3. Reputation Damage: Lose customer trust and damage your brand.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              4. Business Disruption: Cyber attacks can halt operations.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              5. Financial Losses: Recovering from a breach can be costly.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] tracking-wide">
              <strong className="block"> CONCLUSION</strong>
              ISO 27001 is a crucial framework for protecting your business from
              cyber threats. By implementing it, you'll improve security, meet
              compliance requirements, and gain a competitive edge. Don't risk
              your business, adopt ISO 27001 today.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-[12px] lg:pt-[20px] tracking-wide">
              Get your ISO 27001 Certification Today Schedule a demo now:
              <a
                className="text-blue-600"
                target="_blank"
                rel="noreferrer"
                href="https://tidycal.com/36kgx91/vegeel-demo"
              >
                Vegeel demo
              </a>
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              For further inquiries, contact:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="mailto:hello@vegeel.com"
              >
                hello@vegeel.com
              </a>{" "}
              <br />
              Follow Us <br />
              Twitter:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://x.com/Vegeel_Tech"
              >
                Vegeel_Tech
              </a>{" "}
              <br />
              Linkedin:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.linkedin.com/company/vegeel/"
              >
                Vegeel
              </a>{" "}
              <br />
              Instagram:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.instagram.com/vegeelcompliance"
              >
                Vegeelcompliance
              </a>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="py-[48px] flex justify-center items-center gap-[48px] w-full lg:hidden">
          <h1 className="text-[1.125rem] text-[#060B59] font-medium">
            Share article
          </h1>
          <div className="flex justify-start items-center gap-[24px]">
            <CustomIcon SvgIcon={Twitter} size={24} />
            <CustomIcon SvgIcon={Linkedin} size={24} />
            <CustomIcon SvgIcon={LinkIcon} size={24} />
          </div>
        </div>
      </div>
      <div className="py-[48px] px-[18px] bg-[#EDF1FF] flex justify-center items-center lg:items-start flex-col lg:px-[8.5rem]">
        <h1 className="pb-[32px] text-[#060B59] font-bold text-[1.75rem]">
          Related resources
        </h1>
        <div className="lg:flex lg:justify-center lg:items-start lg:flex-col">
          <BlogPost
            blogImg={
              "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750,g_auto/v1725665646/efcaf423936bc0b94e9c904c16a17f50_jjiw7w.jpg"
            }
            blogHeader="Protect Your Business with PCI DSS: Top 5 Benefits of PCI DSS"
            blogDate="Wednesday, September 04, 2024"
            blogCategory="Framework"
            blogDetail="If your business accepts credit card payments, you need to know about PCI DSS (Payment Card Industry Data Security Standard)."
            blogDesktopPaddingTop="50"
            navigateToBlogPost="blog-details/06"
          />
        </div>
      </div>
      {showCopyMessage && (
        <div className="h-[10vh] w-[100vw] fixed top-0 bg-green-500 z-20 flex justify-between items-center p-[20px] px-[40px]">
          <h1 className="text-white font-medium text-[1rem]">
            ✔ Copied to clipboard
          </h1>
          <div onClick={() => setShowCopyMessage(false)}>
            <CustomIcon SvgIcon={Times} size={24} />
          </div>
        </div>
      )}
    </div>
  );
}
