import Cookiestracking from "./Cookiestracking";
import Datasecurity from "./Datasecurity";
import Legalreq from "./Legalreq";
import Shareinfo from "./Shareinfo";
import Choicesright from "./choicesright";
import Infowecollect from "./infowecollect";
import Introducton from "./introducton";
import Purpose from "./purpose";

export default function PrivacyPolicy() {
  return (
    <article className="text-[#455468] lg:w-[70%] lg:py-[48px]">
      <header className="py-5 flex flex-col gap-3 lg:gap-[20px] items-center">
        <h1 className="lg:text-[60px] text-4xl leading-[3.125rem] tracking-[-1%] text-[#060B59] font-bold">
          Privacy Policy
        </h1>
        <p className="flex gap-2 text-xl leading-[2.125rem] tracking-[-0.3%]">
          <span className="text-[#455468] font-normal">Last Updated: </span>
          <span className="font-medium text-[#111111]">June 1, 2024</span>
        </p>
      </header>

      <div className="flex flex-col px-3  gap-[20px] leading-7 tracking-[0.3%] lg:pt-[48px]">
        <Introducton />
        <Purpose />
        <Infowecollect />
        <Shareinfo />
        <Legalreq />
        <Choicesright />
        <Cookiestracking />
        <Datasecurity />
      </div>
    </article>
  );
}
