import React from "react";

interface BlueCardProp {
  image: string;
  header: string;
  subheader?: string;
  text: string;
  spanText: string;
  endText?: string;
  bg: string;
}

export const BlueCard: React.FC<BlueCardProp> = ({
  image,
  header,
  subheader,
  text,
  spanText,
  endText,
  bg,
}) => {
  return (
    <div
      className={`p-[1.25rem] flex flex-col gap-[0.75rem] rounded-[0.938rem] ${bg}`}
    >
      <div className="flex justify-center w-full">
        <img src={image} alt="document template" className="w-full" />
      </div>
      <div>
        <p className=" font-[700] text-[1.25rem] text-white">
          {header}
          {subheader}
        </p>
      </div>

      <div className="font-[400] text-[1rem] leading-[1.75rem]  text-white">
        <p className=" leading-[1.75rem]">
          {text} <span className=" text-[1rem] font-semibold">{spanText}</span>{" "}
          {endText}
        </p>
      </div>
    </div>
  );
};
