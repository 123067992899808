import React, { useEffect, useRef } from "react";
import img from "../../assets/images/veegeel-about.svg";
import img1 from "../../assets/images/veegeel-about-story.svg";
import img2 from "../../assets/images/veegeel-commitment.svg";
import img3 from "../../assets/images/veegeel-careers.svg";
import Button from "../../components/Button";
import AboutCard from "../../components/AboutCard";
import img4 from "../../assets/images/veegeel-empty.svg";
import img5 from "../../assets/icons/user.svg";
import img6 from "../../assets/icons/bag.svg";
import { useMenuContext } from "../../context";

export default function About() {
  const { closeDropdown } = useMenuContext();
  const CareerSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToPartnershipSection = () => {
    if (CareerSectionRef.current) {
      CareerSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div onClick={() => closeDropdown()}>
      <div className="flex justify-center items-center flex-col bg-[#000033] py-[48px] lg:pt-[8rem]">
        <div className="py-[12px] pl-[30px] pr-[35px] border border-[#ffffff4d] rounded-[100px] text-[#93AAFD1A] bg-[#93AAFD1A]">
          <h1 className="flex gap-[12px]">
            <span>
              <img src={img5} alt="veegeel user" title="veegeel user" />
            </span>
            <span className="text-[14px] leadning-[24px] font-[400] text-[#93AAFD]">
              About Vegeel
            </span>
          </h1>
        </div>

        <div className="w-[343px] md:w-full h-[376px] flex flex-col gap-[24px] lg:w-full lg:px-[60px]">
          <div className="pt-[16px]">
            <h1 className="text-[48px] lg:text-[78px] leading-[56px] lg:leading-[92px] font-bold text-center text-white">
              Our Vision is to be the beacon <br className="hidden lg:flex" />{" "}
              of{" "}
              <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent font-[700]">
                security
              </span>{" "}
              and{" "}
              <span className="gradient-text bg-gradient-to-r-custom bg-clip-text text-transparent font-[700]">
                compliance
              </span>
            </h1>
          </div>
          <div className="flex justify-center">
            <h1 className="font-[400] text-[16px] lg:text-[20px] lg:leading-[34px] leading-[28px] text-center w-[95%] lg:w-[80%] text-white">
              Vegeel commits to safeguarding our customers data at all time. We
              automate compliance tasks, control monitoring, and audit
              management, making your processes seamless and efficient.
            </h1>
          </div>
        </div>
        <div className="pt-[48px] lg:pt-[0px] pb-[48px] px-4 lg:px-0 w-full h-full flex justify-center items-center">
          <img
            src={img}
            alt="Vegeel about"
            title="Vegeel about"
            className="w-full h-full lg:w-[1155px] lg:h-[571px]"
          />
        </div>
      </div>
      <div className="px-[16px] bg-white  flex justify-center items-center md:gap-[53px] flex-wrap pt-[48px]">
        <div className="w-[538px]">
          <h1 className="font-[700] text-[28px] lg:text-[48px] lg:leading-[64px] leading-[36px] text-[#060B59]">
            Our Story
          </h1>
          <p className="font-[400] text-[16px] lg:text-[20px] leading-[28px] lg:leading-[34px] pt-[12px] text-dark_color">
            At Vegeel, we believe that achieving and maintaining security
            compliance should be effortless, efficient, and effective. Our
            journey began with a simple yet powerful vision: to transform the
            way organizations manage their Information Security Management
            Systems (ISMS) and compliance requirements.
            <br />
            <br />
            Our mission is to empower organizations to navigate the complex
            landscape of security compliance with confidence and ease. By
            leveraging cutting-edge technology, we provide innovative solutions
            that simplify compliance processes, reduce manual efforts, and
            ensure robust security measures.
          </p>
        </div>
        <div className="py-[24px]">
          <img
            src={img1}
            alt="Vegeel about story"
            title="Vegeel about story"
            className="w-[505px] lg:h-[452px]"
          />
        </div>
      </div>
      <div className="px-[16px] bg-white pt-[32px] flex justify-center items-center md:gap-[53px] flex-wrap">
        <div className="w-[538px]">
          <h1 className="text-[#060b59] font-[700] text-[28px] lg:text-[48px] leading-[36px] lg:leading-[64px] pb-[12px]">
            Our Commitment
          </h1>
          <p className="font-[400] text-[16px] lg:text-[20px] lg:leading-[34px] leading-[28px] text-dark_color">
            We are committed to helping businesses across Africa build and
            maintain trust with their users, customers, partners, and prospects
            through our cutting-edge security and compliance automation
            solution.
            <br />
            <br />
            Our platform is tailored to cater to the unique compliance needs of
            African companies, ensuring seamless adherence to local regulations.
          </p>
        </div>
        <div className="py-[40.16px]">
          <img
            src={img2}
            alt="Vegeel commitment"
            title="Vegeel commitment"
            className="lg:w-[505px] lg:h-[452px]"
          />
        </div>
      </div>
      <div className="px-[16px] bg-[#EDF1FF]">
        <div className="py-[48px] flex flex-col justify-center items-center">
          <div className="py-[10px] px-[15px] rounded-[25px] bg-[#FFFAEB] text-center">
            <h1 className="text-[#EA6F03] flex justify-center items-center gap-[12px]">
              <span className="">
                <img src={img6} alt="veegeel bag" title="veegeel bag" />
              </span>{" "}
              <span className="font-[500] text-[12px] leading-[20px]">
                Careers in Veegeel
              </span>
            </h1>
          </div>

          <div className="pt-[12px]">
            <h1 className="text-[28px] lg:text-[48px] lg:font-[700] lg:leading-[64px] font-[700] leading-[36px] text-center text-[#060B59]">
              Join our team of Professionals
            </h1>
            <div className="flex justify-center">
              <h1 className="text-[16px] py-[12px] leading-[28px] text-[#455468] text-center lg:text-[1.25rem] lg:leading-[34px] lg:font-[400]">
                Are you ready to embark on a rewarding journey and make a
                meaningful impact? Join our team of{" "}
                <br className="hidden lg:flex" /> passionate professionals let’s
                build something extraordinary.
              </h1>
            </div>
          </div>

          <div className="pt-[24px] w-[80%] h-full lg:w-[1108.48px]">
            <img
              src={img3}
              alt="Vegeel professionals"
              title="Vegeel professionals"
              className="w-full h-full"
            />
          </div>

          <div className="mt-[24px] lg:mt-[32px] rounded-[6px] border p-[20px] bg-white lg:flex gap-[68px] lg:w-[90%]">
            <div className="lg:w-[80%]">
              <h1 className="font-[500] text-[20px] lg:text-[24px] lg:leading-[36px] lg:font-[500] leading-[34px]">
                Find your dream job.
              </h1>
              <h1 className="text-[#455468] text-[16px] leading-[28px] lg:pt-[12px]">
                Explore exciting career opportunities with us, unlock your full
                potential, and be part of our dynamic team.
              </h1>
            </div>
            <div className="pt-[24px] lg:w-[20%]">
              <Button
                onClick={scrollToPartnershipSection}
                icon={false}
                children={"View positions"}
                variant={"contained"}
                size="large"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-[48px]">
        <h1 className="text-[28px] lg:text-[48px] lg:leading-[64px] leading-[36px] text-center font-[700] text-[#060B59]">
          Why join Vegeel?
        </h1>
        <div className="py-[32px] px-[16px] flex justify-center items-center gap-[24px] flex-col lg:flex-row lg:flex-wrap lg:gap-[48px]">
          <AboutCard
            title="Innovative & collaborative culture"
            about="At Vegeel, we encourage creativity, embrace diversity, and empower our employees to contribute their ideas to make a real impact."
          />
          <AboutCard
            title="Opportunity to shape the future"
            about="At Vegeel, you'll have the opportunity to be at the forefront of change and contribute to something meaningful."
          />
          <AboutCard
            title="Great colleagues"
            about="Joining our team means becoming part of a collaborative community of professionals who inspire and challenge one another to grow"
          />
          <AboutCard
            title="Competitive compensation "
            about="We value the hard work and dedication of our employees and strive to offer competitive compensation and benefits packages."
          />
        </div>
      </div>

      <div ref={CareerSectionRef} className="text-center">
        <h1 className="text-[28px] leading-[36px] font-[700] text-[#060B59] lg:text-[48px] lg:leading-[64px]">
          Available positions
        </h1>
        <div className="flex justify-center ">
          <h1 className="text-[16px] leading-[28px] text-center font-[400] w-[95%] lg:w-[60%] lg:text-[20px] lg:leading-[34px]">
            Browse the available openings below. If you have any questions or
            require special assistance, please reach out to{" "}
            <span className="text-[#1B4DFF]">careers@vegeel.com.</span>
          </h1>
        </div>
      </div>
      <div className="px-[16px]">
        <div className="flex justify-center">
          <img src={img4} alt="Veegeel empty" title="veegeel empty" />
        </div>
        <div className="">
          <h1 className="font-[500] lg:text-[24px] lg:leading-[36px] text-[20px] leading-[34px] text-center">
            Sorry no job openings at the moment.
          </h1>
          <div className=" flex justify-center items-center">
            <h1 className="font-[400] text-[16px] lg:py-[20px] leading-[28px] text-center lg:text-[18px] lg:leading-[30px]">
              You can send your resume to{" "}
              <span className="text-[#1B4DFF]">careers@vegeel.com.</span> to be
              considered for new openings in the future.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
