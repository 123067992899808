import React, { useEffect, useState } from "react";
import arrowLeft from "../../assets/icons/blog-detail-arrow-left.svg";
import CustomIcon from "../../components/CustomIcon";
import { ReactComponent as Avatar } from "../../assets/icons/blog-detail-avatar.svg";
import avatarOne from "../../assets/icons/blog-detail-avatar.svg";
import { ReactComponent as Twitter } from "../../assets/icons/blog-detail-x-icon.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/blog-detail-link-icon.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/blog-detail-linkedin-icon.svg";
import blogMainImg from "../../assets/images/non-compliance.jpg";
import blogMainTwoImg from "../../assets/images/non-compliance-risk.jpg.png";
import blogMainThreeImg from "../../assets/images/non-compliance-challenges.png";
import { useNavigate } from "react-router-dom";
import arrowUpRight from "../../assets/icons/blog-details-up-right.svg";
import copyLinkIcon from "../../assets/icons/blog-detail-desktop-link-angled.svg";
import { useMenuContext } from "../../context";
import { BlogPost } from "../Blog/All";
import blogPostImgOne from "../../assets/images/blog-post-one.svg";
import { ReactComponent as Times } from "../../assets/icons/times.svg";

export default function BlogDetailsTwo() {
  const navigate = useNavigate();
  const { closeDropdown } = useMenuContext();
  const linkValue = "https://www.vegeel.com/";
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = () => {
    navigate(-1);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(linkValue);
      setShowCopyMessage(true);
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div onClick={() => closeDropdown()}>
      <div className=" pt-[2rem] lg:pt-[6rem] px-[18px] lg:px-[5rem] flex justify-start lg:justify-center items-start lg:items-start flex-col">
        <div
          onClick={handleNavigate}
          className="gap-2 text-[1rem] text-[#060B59] flex justify-center items-center font-medium hero-blog-detail-go-back-border py-[8px] px-[13px] lg:px-[16px] rounded-[6px] cursor-pointer"
        >
          <span>
            <img src={arrowLeft} alt="icon" />
          </span>
          Back to blog
        </div>
        <div className="pt-[32px] lg:px-[3.5rem] ">
          <h1 className="text-[#060B59] text-[2.375rem] lg:text-[3.5rem] font-bold pb-[16px] lg:pb-[24px] leading-[60px]">
            How noncompliance could bring your company to it knees
          </h1>
          <div className="lg:hidden">
            <div className="pt-[16px] flex justify-start items-center gap-2">
              <CustomIcon SvgIcon={Avatar} size={28} />
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <div className="flex justify-between items-center pt-[16px]">
              <h1 className="text-[#455468] text-[1rem]">
                Friday, July 25, 2024
              </h1>
              <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
                Framework
              </h2>
            </div>
          </div>
          <div className="hidden lg:flex lg:justify-start lg:items-center lg:gap-3">
            <div className="flex justify-start items-center gap-2">
              <div className="h-[32px] w-[32px]">
                <img src={avatarOne} alt="avater" className="w-full h-full" />
              </div>
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>
            <h1 className="text-[#455468] text-[1rem]">
              Monday, July 22, 2024
            </h1>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>

            <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
              Framework
            </h2>
          </div>
        </div>
        <div className="pt-[16px] w-full lg:px-[3.5rem]">
          <img
            src={blogMainImg}
            alt="blog-main"
            className="rounded-[15px] w-full h-full"
          />
        </div>

        <div className="lg:py-[48px] lg:flex lg:justify-between lg:items-start lg:px-[3.5rem]">
          <div className="lg:w-[28%]">
            <div className="pt-[32px] ">
              <h1 className="text-[#060B59] font-bold text-[1.125rem] lg:text-[1.25rem] pb-[8px] lg:pb-[16px]">
                Contents
              </h1>

              <div className="flex">
                <div className="relative">
                  <div className="absolute bg-[#E5EAFC] w-1 h-full rounded-[6px]">
                    <div
                      className={`absolute left-0 bg-blue-500 w-1 h-[20%] rounded-[6px]`}
                    ></div>
                  </div>
                </div>

                <div className="pl-[12px]">
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium lg:w-full">
                    The Real Risks of Ignoring Compliance Standards
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium py-[8px]">
                    Unpacking the Challenges Companies Face and Why It's Harder
                    Than It Seems
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium">
                    How Our Compliance Solutions Mitigate Risks and Ensure Peace
                    of Mind
                  </h1>
                </div>
              </div>
            </div>

            <div className="hidden lg:flex lg:pt-[64px] lg:flex-col lg:justify-start lg:items-start">
              <h1 className="text-[#060B59] text-[1.25rem] pb-[1rem]">
                Share article
              </h1>

              <h1 className="text-[#1B4DFF] text-[1rem] font-medium flex justify-start items-center gap-2 cursor-pointer">
                LinkedIn{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1 className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start items-center gap-2 cursor-pointer">
                Twitter{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1
                onClick={copyLink}
                className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start  items-center gap-2 cursor-pointer"
              >
                Copy link{" "}
                <span>
                  <img src={copyLinkIcon} alt="icon" />
                </span>
              </h1>
            </div>
          </div>

          <div className="pt-[32px] lg:px-[rem] lg:w-[68%]">
            <p className="text-[#111111] text-[1rem] lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide">
              Welcome to the vital world of financial compliance, where
              adherence to regulations is not just a best practice but a
              critical safeguard against business catastrophe. In this article,
              we will dissect the silent threats posed by non-compliance,
              unveiling how ignoring financial standards can dismantle even the
              most successful enterprises. We’ll explore the intricate web of
              regulatory requirements, the severe consequences of neglect, and
              provide a roadmap for fortifying your business against these
              hidden perils. Join us as we navigate this complex landscape to
              ensure your company's resilience and long-term success.
            </p>
            <h1 className="text-[#111111] text-[2rem] lg:text-[2.25rem] font-bold leading-[36px] lg:leading-[50px] py-[12px] lg:py-[20px]">
              The Real Risks of Ignoring Compliance Standards
            </h1>
            <div className="pt-[16px] w-full">
              <img
                src={blogMainTwoImg}
                alt="blog-Img"
                className="rounded-[15px] w-full h-full"
              />
            </div>
            <h1 className="text-[#111111] text-[1.5rem] lg:text-[1.75rem] font-bold leading-[36px] lg:leading-[50px] pt-[12px] lg:pt-[12px] lg:pb-[5px]">
              Risk
            </h1>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              Financial standards ensure businesses operate within legal and
              ethical boundaries. Ignoring these standards can lead to severe
              legal repercussions, including hefty fines and penalties.
              Non-compliance can severely damage a company's reputation, eroding
              customer trust and investor confidence. In a digital age where
              information spreads rapidly, a single compliance breach can
              tarnish a brand's image irreparably.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide pb-[12px]">
              Ignoring financial standards can disrupt business operations,
              leading to inefficiencies and operational failures. Compliance
              ensures a structured, efficient approach to financial management,
              fostering stability and growth.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide pb-[12px]">
              Non-compliance opens the door to financial fraud and embezzlement,
              as weak regulatory oversight creates opportunities for malicious
              activities. Adhering to financial standards strengthens internal
              controls and minimizes risks
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide pb-[12px]">
              In the competitive business landscape, compliance with financial
              standards is a strategic advantage. Companies that adhere to these
              standards are more likely to attract investors, patners, and
              customers who value integrity and reliability
            </p>

            <h1 className="text-[#111111] text-[1.5rem] lg:text-[1.75rem] font-bold leading-[36px] lg:leading-[50px] lg:pt-[12px] lg:pb-[5px]">
              Compliance
            </h1>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              Picture financial standards as a robust security system protecting
              a treasure. Without this system, the treasure is vulnerable to
              thieves. Non-compliance weakens the defenses, making the business
              susceptible to fraud and financial losses.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide pb-[12px]">
              Compliance refers to the act of conforming with laws, regulations,
              standards, and guidelines set by governing bodies, industry
              organizations, or internal policies within a company. It involves
              ensuring that a business, its employees, and its operations meet
              these established requirements to avoid legal penalties, ensure
              ethical conduct, and maintain operational efficiency. Compliance
              is crucial for risk management, maintaining a good reputation, and
              fostering trust among customers, partners, and stakeholders.
            </p>

            <h1 className="text-[#111111] text-[2rem] lg:text-[2.25rem] font-bold leading-[36px] lg:leading-[50px] py-[12px] lg:py-[20px]">
              Unpacking the Challenges Companies Face and Why It's Harder Than
              It Seems
            </h1>
            <div className="pt-[16px] w-full">
              <img
                src={blogMainThreeImg}
                alt="blog-Img"
                className="rounded-[15px] w-full h-full"
              />
            </div>
            <h1 className="text-[#111111] text-[1.5rem] lg:text-[1.75rem] font-bold leading-[36px] lg:leading-[50px] pt-[12px] lg:pt-[12px] lg:pb-[5px]">
              Challenges
            </h1>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              Achieving compliance refers to a company’s ability to adhere to
              laws, regulations, and industry standards designed to ensure
              business integrity and security. However, many companies struggle
              with compliance due to a range of complex challenges.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              <span className="font-semibold">Regulatory Complexity</span> is
              like navigating a labyrinthine maze. With numerous regulations
              such as ISO 27001, PCI DSS, and SOC 2, each with its own set of
              requirements, companies often find it difficult to keep up. The
              landscape is constantly evolving, with new standards and
              amendments being introduced regularly, making it a daunting task
              to remain compliant.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              <span className="font-semibold">Technological Challenges</span>{" "}
              can be compared to using outdated tools to perform cutting-edge
              tasks. As technology advances, so do the threats. Companies must
              continually update their IT infrastructure and cybersecurity
              measures to protect sensitive data. However, integrating new
              technologies with existing systems can be complex and costly.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              <span className="font-semibold">Cultural Resistance</span> within
              organizations is akin to steering a massive ship against the tide.
              Employees may resist changes to established workflows and
              practices. Cultivating a culture of compliance requires ongoing
              training and awareness programs, which can be challenging to
              implement and sustain.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              <span className="font-semibold">Data Management</span> Issues are
              similar to managing a vast library with disorganized records. With
              the explosion of data in the digital age, companies must ensure
              that their data handling practices meet compliance standards. Poor
              data management can lead to accidental breaches and
              non-compliance.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              <span className="font-semibold">Unclear Accountability</span> can
              result in a diffusion of responsibility, akin to too many cooks
              spoiling the broth. Without clearly defined roles and
              accountability, compliance initiatives can become fragmented and
              ineffective.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              The path to compliance is fraught with these and other challenges,
              but understanding and addressing them is crucial for companies to
              safeguard their operations, reputation, and bottom line.
            </p>

            <h1 className="text-[#111111] text-[2rem] lg:text-[2.25rem] font-bold leading-[36px] lg:leading-[50px] py-[12px] lg:py-[20px]">
              How Our Compliance Solutions Mitigate Risks and Ensure Peace of
              Mind
            </h1>

            <h1 className="text-[#111111] text-[1.5rem] lg:text-[1.75rem] font-bold leading-[36px] lg:leading-[50px] pt-[12px] lg:pt-[12px] lg:pb-[5px]">
              Solutions
            </h1>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              While i show you the risk it would be of great loss if i don’t
              find a way to solve that problem for you and that is why VEGEEL is
              your go to compliance and cybersecurity partner, here we
              specialize in managing critical frameworks like SOC 2, PCI DSS,
              and ISO 27001, and more, ensuring your business stays secure and
              compliant in an ever-evolving digital landscape. Not only do we
              guide you through the compliance journey, but we also provide
              these essential frameworks tailored to your specific needs.
            </p>

            <h1 className="text-[#111111] text-[1.35rem] lg:text-[1.5rem] font-bold leading-[36px] lg:leading-[50px] pt-[12px] lg:pt-[12px]">
              Why Vegeel ?
            </h1>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              Because we’re not just about ticking boxes—we’re about unlocking
              your business’s full potential through robust, hassle-free
              compliance. Say goodbye to sleepless nights and hello to peace of
              mind.
            </p>

            <h1 className="text-[#111111] text-[1.35rem] lg:text-[1.5rem] font-bold leading-[36px] lg:leading-[50px] pt-[12px] lg:pt-[12px]">
              What We Do
            </h1>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              Imagine your compliance journey becoming as easy as ABC with
              Vegeel. Our step-by-step solutions seamlessly integrate into your
              system, addressing all the challenges you face
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pt-4 pb-[12px] tracking-wide">
              Vegeel provides you with an advanced automation to streamline and
              simplify compliance procedures, ensuring organizations maintain
              regulatory standards efficiently and effectively. End to End
              compliance processes [Get your framework certification from vegeel
              as fast as 4 to 6 weeks with no complications].
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              Vegeel offers guidance into what policy documents to upload and
              gives same templates for use. Our AI-Driven tool gives 100%
              insight to how to implement a control successfully and provides
              real-time monitoring of compliance status.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              At Vegeel, we understand the unique needs of startups and small
              businesses, which is why we've made our compliance solutions
              exceptionally budget-friendly. We believe that everyone should
              have access to top-tier compliance services, ensuring you stay
              compliant at all times without breaking the bank.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide">
              Tracks document expiration with ease and stay up to date,
              automated reminders to responsible parties to review and approve
              evidence, upload and set document properties on the go, also gives
              room for automated task assignment, real-time task tracking and
              management.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide border-b-[2px] border-[#000]">
              Vegeel provides a GRC Team Support that will help build and
              maintain your dedicated GRC team. Also we provide Expert guidance,
              training and resources from Vegeel
            </p>
            <p className="text-[#111111] text-[1rem] lg:text-[1.125rem] leading-[28px] lg:leading-[30px] pb-[12px] tracking-wide pt-5">
              In conclusion, navigating the complex world of compliance can be
              challenging, but it doesn't have to be overwhelming. With Vegeel's
              comprehensive solutions, you can simplify the compliance process,
              ensuring your business stays secure, efficient, and fully
              compliant with industry standards. Let Vegeel be your trusted
              partner in overcoming compliance challenges, so you can focus on
              what you do best—growing your business. Reach out today at{" "}
              <span>
                <a
                  href="http://www.vegeel.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600"
                >
                  http://www.vegeel.com
                </a>
              </span>
              , or schedule a demo{" "}
              <span>
                <a
                  href="https://tidycal.com/36kgx91/vegeel-demo"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600"
                >
                  Vegeel Demo
                </a>
              </span>{" "}
              and discover how we can help you achieve seamless compliance and
              pave the way for a successful future.
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              Book an instant demo:{" "}
              <a
                className="text-blue-600"
                target="_blank"
                rel="noreferrer"
                href="https://tidycal.com/36kgx91/vegeel-demo"
              >
                Vegeel demo
              </a>
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              For further inquiries, contact:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="mailto:hello@vegeel.com"
              >
                hello@vegeel.com
              </a>{" "}
              <br />
              Follow Us <br />
              Twitter:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://x.com/Vegeel_Tech"
              >
                Vegeel_Tech
              </a>{" "}
              <br />
              Linkedin:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.linkedin.com/company/vegeel/"
              >
                Vegeel
              </a>{" "}
              <br />
              Instagram:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.instagram.com/vegeelcompliance"
              >
                Vegeelcompliance
              </a>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="py-[48px] flex justify-center items-center gap-[48px] w-full lg:hidden">
          <h1 className="text-[1.125rem] text-[#060B59] font-medium">
            Share article
          </h1>
          <div className="flex justify-start items-center gap-[24px]">
            <CustomIcon SvgIcon={Twitter} size={24} />
            <CustomIcon SvgIcon={Linkedin} size={24} />
            <CustomIcon SvgIcon={LinkIcon} size={24} />
          </div>
        </div>
      </div>
      <div className="py-[48px] px-[18px] bg-[#EDF1FF] flex justify-center items-center lg:items-start flex-col lg:px-[8.5rem]">
        <h1 className="pb-[32px] text-[#060B59] font-bold text-[1.75rem]">
          Related resources
        </h1>
        <div className="lg:flex lg:justify-center lg:items-start lg:flex-col">
          <BlogPost
            blogImg={blogPostImgOne}
            blogHeader="How to implement a GRC Strategy"
            blogDate="Monday, March 4, 2024"
            blogCategory="Framework"
            blogDetail="Governance, risk, and compliance (GRC) refers to an organization’s
              strategy for managing risks, maintaining compliance, and abiding
              by the company’s guiding principles."
            blogDesktopPaddingTop="96"
            navigateToBlogPost="/blogs/blog-details"
          />
        </div>
      </div>

      {showCopyMessage && (
        <div className="h-[10vh] w-[100vw] fixed top-0 bg-green-500 z-20 flex justify-between items-center p-[20px] px-[40px]">
          <h1 className="text-white font-medium text-[1rem]">
            ✔ Copied to clipboard
          </h1>
          <div onClick={() => setShowCopyMessage(false)}>
            <CustomIcon SvgIcon={Times} size={24} />
          </div>
        </div>
      )}
    </div>
  );
}
