import React from "react";

export default function Introducton() {
  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <h1 className="font-[700] text-[28px] text-dark_color leading-7 tracking-[0.3%]">
          Introduction
        </h1>
        <p className="text-dark_color text-[16px]">
          At Vegeel Technology Limited, we are committed to protecting the
          privacy and security of our clients' and website visitors' personal
          information. This Privacy Policy outlines the types of information we
          collect, how we use it, and the measures we take to ensure it is
          safeguarded. By using our services, you agree to the terms of this
          policy.
        </p>
        <p className="text-dark_color text-[16px]">
          This Privacy Policy was last updated on June 1, 2024. We may update it
          from time to time to reflect changes in our practices or to comply
          with legal requirements. We will notify you of any significant changes
          by posting a notice on our website or by sending you an email.
        </p>
      </div>
    </div>
  );
}
