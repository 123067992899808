import React from "react";

export default function Shareinfo() {
  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <h1 className="font-[700] text-[28px] text-dark_color">
          Sharing of Information
        </h1>
        <p className="text-dark_color text-[16px]">
          We take the confidentiality and security of your personal information
          seriously. This section details how we share information, with a
          specific focus on third-party sharing and legal requirements, in
          compliance with the Nigeria Data Protection Regulation [NDPR] and
          other relevant laws.
        </p>
        <h2 className="font-bold text-dark_color text-[20px] ">
          Third-Party Sharing
        </h2>
        <p className="text-dark_color text-[16px]">
          We may share your personal information with third parties under
          specific circumstances. Each instance of sharing is governed by strict
          agreements to ensure your data remains secure and is used only for
          legitimate purposes. The primary reasons for sharing your information
          with third parties include:
        </p>
        <ol className="text-dark_color text-[16px] list-decimal flex flex-col gap-[20px]">
          <li>
            <p>Service Providers</p>
            <ul className="list-disc">
              <li>
                <span className="font-bold text-dark_color"> Purpose:</span> We
                engage third-party service providers to perform various
                functions that support our business operations. These functions
                include, but are not limited to, IT and infrastructure support,
                payment processing, customer support, marketing, and analytics.
              </li>
              <li>
                <span className="font-bold text-dark_color"> Data Shared:</span>{" "}
                The types of data shared with service providers depend on the
                services they perform. This can include personal identification
                information, contact details, payment information, and usage
                data.
              </li>
              <li>
                <span className="font-bold text-dark_color">
                  Data Protection:{" "}
                </span>{" "}
                All service providers are contractually obligated to handle your
                data securely, use it only for the purposes specified by us, and
                comply with applicable data protection laws, including the NDPR.
              </li>
            </ul>
          </li>
          <li>
            <p>Business Partners</p>
            <ul className="list-disc">
              <li>
                <span className="font-bold text-dark_color">Purpose: </span> We
                may share your information with trusted business partners who
                provide products or services that complement our offerings, such
                as security training programs or additional cybersecurity tools.
              </li>
              <li>
                <span className="font-bold text-dark_color">Data Shared:</span>{" "}
                This typically includes contact information and preferences that
                enable our partners to offer relevant products and services.
              </li>
              <li>
                <span className="font-bold text-dark_color">
                  Data Protection:{" "}
                </span>{" "}
                Business partners must adhere to our data protection standards
                and are prohibited from using your data for purposes outside the
                agreed-upon scope.
              </li>
            </ul>
          </li>
          <li>
            <p>Affiliates and Subsidiaries</p>
            <ul className="list-disc">
              <li>
                <span className="font-bold text-dark_color">Purpose: </span> We
                may share information with our affiliates and subsidiaries to
                streamline operations and provide cohesive services across
                different entities within our corporate group.
              </li>
              <li>
                <span className="font-bold text-dark_color">Data Shared: </span>{" "}
                Information shared can include personal identification details,
                account information, and transaction history.
              </li>
              <li>
                <span className="font-bold text-dark_color">
                  Data Protection:{" "}
                </span>{" "}
                Affiliates and subsidiaries are required to follow the same
                stringent data protection policies and practices that we uphold.
              </li>
            </ul>
          </li>
          <li>
            <p>Marketing and Advertising Partners</p>
            <ul className="list-disc">
              <li>
                <span className="font-bold text-dark_color"> Purpose:</span>{" "}
                With your consent, we may share information with marketing and
                advertising partners to enhance our marketing efforts, deliver
                targeted advertisements, and measure the effectiveness of our
                campaigns.
              </li>
              <li>
                <span className="font-bold text-dark_color">Data Shared:</span>{" "}
                This may include your contact information, preferences, and
                behavioral data collected from your interactions with our
                services.
              </li>
              <li>
                <span className="font-bold text-dark_color">
                  {" "}
                  Data Protection:{" "}
                </span>
                Partners are required to process your information in accordance
                with our instructions and applicable data protection laws.
              </li>
            </ul>
          </li>
          <li>
            <p>Analytics Providers</p>
            <ul className="list-disc">
              <li>
                <span className="font-bold text-dark_color">Purpose: </span> We
                use third-party analytics providers to gain insights into how
                our services are used and to improve our offerings.
              </li>
              <li>
                <span className="font-bold text-dark_color">Data Shared:</span>{" "}
                This typically includes anonymized or aggregated data that does
                not directly identify you.
              </li>
              <li>
                <span className="font-bold text-dark_color">
                  Data Protection:{" "}
                </span>
                These providers are bound by contracts to use the data solely
                for analytics purposes and to maintain data confidentiality.
              </li>
            </ul>
          </li>
          <li>
            <p>Professional Advisors</p>
            <ul className="list-disc">
              <li>
                <span className="font-bold text-dark_color">Purpose: </span> We
                may share information with professional advisors, such as
                lawyers, auditors, and insurers, when necessary to obtain advice
                or protect our legal interests.
              </li>
              <li>
                <span className="font-bold text-dark_color">Data Shared:</span>{" "}
                This may include any information relevant to the advice or
                service being provided.
              </li>
              <li>
                <span className="font-bold text-dark_color">
                  Data Protection:
                </span>{" "}
                Advisors are bound by confidentiality agreements and legal
                obligations to protect your data.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
}
