import React from "react";
import { FaqItem } from "../Product";

const Support = () => {
  const faqData = [
    {
      question: "What kind of support does Vegeel offer to its users?",
      answer:
        "Vegeel offers comprehensive support to its users, including detailed documentation, training resources, and a dedicated customer support team. Our experts are available to assist you with any questions or issues you may encounter, ensuring you get the most out of our software.",
    },
    {
      question:
        "Is there a knowledge base or documentation available for Vegeel users?",
      answer:
        "Yes, Vegeel provides a comprehensive knowledge base and detailed documentation for all users. Our online resources include user guides, FAQs, tutorials, and troubleshooting tips to help you navigate and utilize our software effectively.",
    },
    {
      question:
        "Does Vegeel offer dedicated account managers or technical support?",
      answer:
        "Yes, Vegeel offers dedicated sales representative, customer success manager and technical support for clients. These dedicated resources provide personalized assistance and ensure that your specific needs are met promptly and effectively.",
    },
    {
      question:
        "What should I do if I encounter a technical issue with Vegeel's software?",
      answer:
        "If you encounter a technical issue with Vegeel's software, you can reach out to our support team via email support@vegeel.com, or phone +2349014957203. Provide as much detail as possible about the issue, and our team will work to resolve it promptly. You can also refer to our knowledge base for troubleshooting tips and solutions.",
    },
    {
      question: "What is the response time for support requests?",
      answer:
        "Vegeel aims to respond to all support requests promptly within 1 hour, depending on the severity and complexity of the issue. Our goal is to provide timely and effective support to minimize any disruption to your operations.",
    },
  ];

  return (
    <div className="pt-[32px] px-[15px] flex justify-center items-center flex-col w-full">
      {faqData.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default Support;
