import { useNavigate } from "react-router-dom";
import arrowRight from "../../../assets/icons/blog-arrow-right.svg";
import blogPostImgOne from "../../../assets/images/blog-post-one.svg";
import blogPostImgTwo from "../../../assets/images/non-compliance.jpg";

interface BlogPostProps {
  blogImg: string;
  blogHeader: string;
  blogDetail: string;
  blogDate: string;
  blogCategory: string;
  blogDesktopPaddingTop: string;
  navigateToBlogPost: string;
}

export const BlogPost = ({
  blogImg,
  blogHeader,
  blogDetail,
  blogDate,
  blogCategory,
  blogDesktopPaddingTop,
  navigateToBlogPost,
}: BlogPostProps) => {
  const mobileBlogDetail = blogDetail.substring(0, 137) + "...";
  const DesktopBlogDetail = blogDetail.substring(0, 150) + "...";
  const navigate = useNavigate();

  return (
    <>
      <div className="py-[12px] lg:flex lg:justify-between lg:items-start lg:flex-row-reverse">
        <div className="lg:w-[45%]">
          <img
            src={blogImg}
            alt="blog-post"
            className="rounded-[15px] w-full h-full"
          />
        </div>
        <div className="lg:w-[50%]">
          <div className="pt-[16px] lg:pt-0">
            <h1 className="text-[1.25rem] lg:text-[2rem] text-[#111111] font-medium pb-[8px] lg:pb-[12px] leading-[36px]">
              {blogHeader}
            </h1>
            <p className="text-[0.99rem] text-[#455468] lg:hidden">
              {mobileBlogDetail}
            </p>
            <p className="hidden text-[1.125rem] text-[#455468] lg:flex">
              {DesktopBlogDetail}
            </p>
          </div>
          <div
            className={`pt-[32px] lg:pt-[${blogDesktopPaddingTop}px] lg:flex lg:justify-between lg:items-center lg:flex-row-reverse`}
          >
            <div className="flex justify-between lg:justify-center lg:gap-4 items-center lg:w-[70%]">
              <h1 className="text-[#455468] text-[1rem]">{blogDate}</h1>
              <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
                {blogCategory}
              </h2>
            </div>

            <h1
              onClick={() => navigate(navigateToBlogPost)}
              className="flex justify-start items-center gap-2 pt-[32px] lg:pt-0 text-[#1B4DFF] text-[1rem] font-medium lg:w-[25%] cursor-pointer"
            >
              Read more{" "}
              <span>
                <img src={arrowRight} alt="icon" />
              </span>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

const All = () => {
  return (
    <div className="py-[48px] flex justify-start lg:justify-center items-center flex-col gap-[28px] lg:w-[85%]">
      <BlogPost
        blogImg={
          "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750,g_auto/v1727028508/Modern_Geometrical_Computer_Security_Instagram_Post__20240916_170651_0000_uggocw.png"
        }
        blogHeader="HOW NON-COMPLIANCE WITH NDPA CAN RUIN YOUR BUSINESS"
        blogDate="Monday, September 16, 2024"
        blogCategory="Framework"
        blogDetail="The Nigeria Data Protection Act (NDPA) is a comprehensive framework that ensures the secure collection, processing, and storage of personal data. To demonstrate your commitment to data protection and compliance, obtaining NDPA certification is essential."
        blogDesktopPaddingTop="50"
        navigateToBlogPost="blog-details/08"
      />
      <BlogPost
        blogImg={
          "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750,g_auto/v1726117562/b5695e9a7e8a0db8c11645da091f6b0e_nedjal.jpg"
        }
        blogHeader="Understanding ISO 27001: The Ultimate Guide to Cybersecurity Compliance"
        blogDate="Wednesday, September 11, 2024"
        blogCategory="Framework"
        blogDetail="In today's digital age, cybersecurity is a top concern for businesses of all sizes. With the increasing number of cyber threats and data breaches, companies need to take proactive measures to protect their sensitive information."
        blogDesktopPaddingTop="50"
        navigateToBlogPost="blog-details/07"
      />
      <BlogPost
        blogImg={
          "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750,g_auto/v1725665646/efcaf423936bc0b94e9c904c16a17f50_jjiw7w.jpg"
        }
        blogHeader="Protect Your Business with PCI DSS: Top 5 Benefits of PCI DSS"
        blogDate="Wednesday, September 04, 2024"
        blogCategory="Framework"
        blogDetail="If your business accepts credit card payments, you need to know about PCI DSS (Payment Card Industry Data Security Standard)."
        blogDesktopPaddingTop="50"
        navigateToBlogPost="blog-details/06"
      />
      <BlogPost
        blogImg={
          "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750/v1724045783/Revolutionize_GRC_Management_gjexke.webp"
        }
        blogHeader="Revolutionize GRC Management with AI-Powered Insights streamline compliance, reduce risk, and boost efficiency with VEGEEL"
        blogDate="Thursday, August 15, 2024"
        blogCategory="Framework"
        blogDetail="Are you tired of manual GRC processes holding your business back? Do you struggle to stay ahead of evolving regulations and risks?"
        blogDesktopPaddingTop="50"
        navigateToBlogPost="blog-details/05"
      />
      <BlogPost
        blogImg={
          "https://res.cloudinary.com/dfruoqaze/image/upload/c_crop,w_1125,h_750,g_auto/v1723628769/Hidden_compliance_cost_res9qx.jpg"
        }
        blogHeader="How manual processes are bleeding your business dry"
        blogDate="Monday, August 12, 2024"
        blogCategory="Framework"
        blogDetail="Are you still relying on manual processes for compliance? You're not  alone, but here's the harsh truth you most probably don't want to hear.
            Manual compliance processes are quietly draining your resources, stifling growth, and putting your business at risk."
        blogDesktopPaddingTop="50"
        navigateToBlogPost="blog-details/04"
      />
      <BlogPost
        blogImg={
          "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750,g_auto/v1723188350/Ultimate_guide_Vegeel_rdlgj1.jpg"
        }
        blogHeader="The ultimate guide to automating cybersecurity compliance"
        blogDate="Thursday, August 09, 2024"
        blogCategory="Framework"
        blogDetail="Stay Secure, Save Time and resources!!!! In today's digital landscape, cybersecurity compliance is no longer a nice-to-have, but a must-have. Yet, managing compliance manually can be quite stressful, prone to errors and inefficiencies. That's where automation comes in, a game changer for businesses seeking to fortify their defenses while freeing up resources."
        blogDesktopPaddingTop="50"
        navigateToBlogPost="blog-details/03"
      />
      <BlogPost
        blogImg={
          "https://res.cloudinary.com/dfruoqaze/image/upload/c_fill,w_1125,h_750,g_auto/v1722991909/iso_certificate_ad_flyer_a6getv.jpg"
        }
        blogHeader="ISO certification made easy"
        blogDate="Monday, August 05, 2024"
        blogCategory="Framework"
        blogDetail="Getting ISO certification can be quite stressful especially with all the technicalities and strict standards involved.
                      In light of these challenges, going through the process manually can mean loss of business time and money that could be used for other areas of the business."
        blogDesktopPaddingTop="50"
        navigateToBlogPost="blog-details/02"
      />
      <BlogPost
        blogImg={blogPostImgTwo}
        blogHeader="How noncompliance could bring your company to it knees"
        blogDate="Monday, July 22, 2024"
        blogCategory="Framework"
        blogDetail="Welcome to the vital world of financial compliance, where adherence to regulations is not just
              a best practice but a critical safeguard against business catastrophe. In this article, we will
              dissect the silent threats posed by non-compliance, unveiling how ignoring financial standards
              can dismantle even the most successful enterprises."
        blogDesktopPaddingTop="20"
        navigateToBlogPost="blog-details/01"
      />
      <BlogPost
        blogImg={blogPostImgOne}
        blogHeader="How to implement a GRC Strategy"
        blogDate="Monday, March 4, 2024"
        blogCategory="Framework"
        blogDetail="Governance, risk, and compliance (GRC) refers to an organization’s
              strategy for managing risks, maintaining compliance, and abiding
              by the company’s guiding principles."
        blogDesktopPaddingTop="96"
        navigateToBlogPost="blog-details"
      />
    </div>
  );
};

export default All;
