import React, { useEffect, useState } from "react";
import arrowLeft from "../../assets/icons/blog-detail-arrow-left.svg";
import CustomIcon from "../../components/CustomIcon";
import { ReactComponent as Avatar } from "../../assets/icons/blog-detail-avatar.svg";
import avatarOne from "../../assets/icons/blog-detail-avatar.svg";
import { ReactComponent as Twitter } from "../../assets/icons/blog-detail-x-icon.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/blog-detail-link-icon.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/blog-detail-linkedin-icon.svg";
import blogMainImg from "../../assets/images/blog-detail-main-img.svg";
import { BlogPost } from "../Blog/All";
import blogPostImgTwo from "../../assets/images/non-compliance.jpg";
import { useNavigate } from "react-router-dom";
import arrowUpRight from "../../assets/icons/blog-details-up-right.svg";
import copyLinkIcon from "../../assets/icons/blog-detail-desktop-link-angled.svg";
import { useMenuContext } from "../../context";
import { ReactComponent as Times } from "../../assets/icons/times.svg";

export default function BlogDetails() {
  const navigate = useNavigate();
  const { closeDropdown } = useMenuContext();
  const linkValue = "https://www.vegeel.com/";
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = () => {
    navigate(-1);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(linkValue);
      setShowCopyMessage(true);
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div onClick={() => closeDropdown()}>
      <div className=" pt-[2rem] lg:pt-[6rem] px-[18px] lg:px-[5rem] flex justify-start lg:justify-center items-start lg:items-start flex-col">
        <div
          onClick={handleNavigate}
          className="gap-2 text-[1rem] text-[#060B59] flex justify-center items-center font-medium hero-blog-detail-go-back-border py-[8px] px-[13px] lg:px-[16px] rounded-[6px] cursor-pointer"
        >
          <span>
            <img src={arrowLeft} alt="icon" />
          </span>
          Back to blog
        </div>
        <div className="pt-[32px] lg:px-[3.5rem] ">
          <h1 className="text-[#060B59] text-[2.375rem] lg:text-[3.75rem] font-bold pb-[16px] lg:pb-[24px] leading-[50px]">
            How to implement a GRC strategy{" "}
          </h1>
          <div className="lg:hidden">
            <div className="pt-[16px] flex justify-start items-center gap-2">
              <CustomIcon SvgIcon={Avatar} size={28} />
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <div className="flex justify-between items-center pt-[16px]">
              <h1 className="text-[#455468] text-[1rem]">
                Monday, March 4, 2024
              </h1>
              <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
                Framework
              </h2>
            </div>
          </div>
          <div className="hidden lg:flex lg:justify-start lg:items-center lg:gap-3">
            <div className="flex justify-start items-center gap-2">
              <div className="h-[32px] w-[32px]">
                <img src={avatarOne} alt="avater" className="w-full h-full" />
              </div>
              <h2 className="text-[1.125rem] text-[#111111] font-medium">
                by Vegeel
              </h2>
            </div>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>
            <h1 className="text-[#455468] text-[1rem]">
              Monday, March 4, 2024
            </h1>
            <span className="text-[#455468] text-[1rem] font-bold">•</span>

            <h2 className="text-[#1B4DFF] text-[0.875rem] py-[4px] px-[12px] border border-[#1B4DFF] font-medium rounded-[6px]">
              Framework
            </h2>
          </div>
        </div>
        <div className="pt-[16px] w-full lg:px-[3.5rem]">
          <img
            src={blogMainImg}
            alt="blog-main"
            className="rounded-[15px] w-full h-full"
          />
        </div>

        <div className="lg:py-[48px] lg:flex lg:justify-between lg:items-start lg:px-[3.5rem]">
          <div className="lg:w-[28%]">
            <div className="pt-[32px] ">
              <h1 className="text-[#060B59] font-bold text-[1.125rem] lg:text-[1.25rem] pb-[8px] lg:pb-[16px]">
                Contents
              </h1>

              <div className="flex">
                <div className="relative">
                  <div className="absolute bg-[#E5EAFC] w-1 h-full rounded-[6px]">
                    <div
                      className={`absolute left-0 bg-blue-500 w-1 h-[50%] rounded-[6px]`}
                    ></div>
                  </div>
                </div>

                <div className="pl-[12px]">
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium  mb-[8px]  lg:w-full">
                    Introduction to governance, risk and compliance
                  </h1>
                  <h1 className="text-[#1B4DFF] text-[1rem] font-medium">
                    5 steps to help unify your approach to GRC
                  </h1>
                </div>
              </div>
            </div>

            <div className="hidden lg:flex lg:pt-[64px] lg:flex-col lg:justify-start lg:items-start">
              <h1 className="text-[#060B59] text-[1.25rem] pb-[1rem]">
                Share article
              </h1>

              <h1 className="text-[#1B4DFF] text-[1rem] font-medium flex justify-start items-center gap-2 cursor-pointer">
                LinkedIn{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1 className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start items-center gap-2 cursor-pointer">
                Twitter{" "}
                <span>
                  <img src={arrowUpRight} alt="icon" />
                </span>
              </h1>
              <h1
                onClick={copyLink}
                className="text-[#1B4DFF] text-[1rem] font-medium pt-[1rem] flex justify-start  items-center gap-2 cursor-pointer"
              >
                Copy link{" "}
                <span>
                  <img src={copyLinkIcon} alt="icon" />
                </span>
              </h1>
            </div>
          </div>

          <div className="pt-[32px] lg:px-[rem] lg:w-[68%]">
            <h1 className="text-[#111111] text-[2rem] lg:text-[2.25rem] font-bold leading-[36px] lg:leading-[50px] pb-[12px] lg:pb-[20px]">
              Introduction to Governance, Risk and Compliance
            </h1>
            <p className="text-[#111111] text-[1rem] lg:text-[1.125rem] leading-[28px] lg:leading-[30px] tracking-wide">
              Governance, risk, and compliance (GRC) refers to an organization’s
              strategy for managing risks, maintaining compliance, and abiding
              by the company’s guiding principles.{" "}
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              Organizations face complex challenges involving regulations,
              technology, and processes. GRC is important because it combines an
              organization’s governance, risk, and compliance functions into one
              single strategy. Each area of GRC includes its own set of rules,
              regulations, and responsibilities, which makes it easy for
              organizations to lose sight of how the three are interrelated.
            </p>
            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  tracking-wide">
              We share five steps to help unify your approach to GRC, whether
              you’re starting one from scratch or need to bolster an existing
              strategy.{" "}
            </p>

            <div className="pt-[24px] lg:pt-[32px]">
              <h1 className="pb-[8px] text-[#111111] text-[1.25rem] lg:text-[1.6rem] font-medium">
                1. Define what matters
              </h1>
              <p className="text-[1rem] text-[#111111] leading-[28px] lg:text-[1.125rem] tracking-wide">
                Gather all leaders tied to GRC to collectively define what GRC
                will mean for your organization. Consider what GRC may look
                like, how current processes are lacking, and identify silos.
              </p>
            </div>
            <div className="pt-[24px] lg:pt-[32px]">
              <h1 className="pb-[8px] text-[#111111] text-[1.25rem] lg:text-[1.6rem] font-medium">
                2. Identify your risks
              </h1>
              <p className="text-[1rem] text-[#111111] leading-[28px] lg:text-[1.125rem]  tracking-wide">
                Identify all the regulations, standards, and controls your
                organization manages. Pinpoint the types of risks your
                organization faces, such as organizational and strategic.
              </p>
            </div>
            <div className="pt-[24px] lg:pt-[32px]">
              <h1 className="pb-[8px] text-[#111111] text-[1.25rem] lg:text-[1.6rem] font-medium">
                3. Design a plan
              </h1>
              <p className="text-[1rem] text-[#111111] lg:text-[1.125rem]  leading-[28px] tracking-wide">
                Build a roadmap to guide your GRC strategy. Consider the scope,
                cost, and specific duties related to GRC. You should also define
                what success looks like and how it will be measured.
              </p>
            </div>
            <div className="pt-[24px] lg:pt-[32px]">
              <h1 className="pb-[8px] text-[#111111] text-[1.25rem] lg:text-[1.6rem] font-medium">
                4. Start small
              </h1>
              <p className="text-[1rem] lg:text-[1.125rem]  text-[#111111] leading-[28px] tracking-wide">
                Start with the organization’s highest priorities. Once the first
                phase has been optimized, then expand the program. This will
                help show value faster and get buy-in from stakeholders.
              </p>
            </div>
            <div className="pt-[24px] lg:pt-[32px]">
              <h1 className="pb-[8px] text-[#111111] text-[1.25rem] lg:text-[1.6rem] font-medium">
                5. Continuously monitor
              </h1>
              <p className="text-[1rem] lg:text-[1.125rem]  text-[#111111] leading-[28px] tracking-wide">
                Conduct regular audits and reviews of your GRC strategy to gauge
                its effectiveness and make adjustments. Communicate wins to
                stakeholders and use data to make risk-informed decision.
              </p>
            </div>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              Book an instant demo:{" "}
              <a
                className="text-blue-600"
                target="_blank"
                rel="noreferrer"
                href="https://tidycal.com/36kgx91/vegeel-demo"
              >
                Vegeel demo
              </a>
            </p>

            <p className="text-[#111111] text-[1rem]  lg:text-[1.125rem] leading-[28px] lg:leading-[30px]  py-[12px] lg:py-[20px] tracking-wide">
              For further inquiries, contact:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="mailto:hello@vegeel.com"
              >
                hello@vegeel.com
              </a>{" "}
              <br />
              Follow Us <br />
              Twitter:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://x.com/Vegeel_Tech"
              >
                Vegeel_Tech
              </a>{" "}
              <br />
              Linkedin:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.linkedin.com/company/vegeel/"
              >
                Vegeel
              </a>{" "}
              <br />
              Instagram:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://www.instagram.com/vegeelcompliance"
              >
                Vegeelcompliance
              </a>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="py-[48px] flex justify-center items-center gap-[48px] w-full lg:hidden">
          <h1 className="text-[1.125rem] text-[#060B59] font-medium">
            Share article
          </h1>
          <div className="flex justify-start items-center gap-[24px]">
            <CustomIcon SvgIcon={Twitter} size={24} />
            <CustomIcon SvgIcon={Linkedin} size={24} />
            <CustomIcon SvgIcon={LinkIcon} size={24} />
          </div>
        </div>
      </div>
      <div className="py-[48px] px-[18px] bg-[#EDF1FF] flex justify-center items-center lg:items-start flex-col lg:px-[8.5rem]">
        <h1 className="pb-[32px] text-[#060B59] font-bold text-[1.75rem]">
          Related resources
        </h1>
        <div className="lg:flex lg:justify-center lg:items-start lg:flex-col">
          <BlogPost
            blogImg={blogPostImgTwo}
            blogHeader="How noncompliance could bring your company to it knees"
            blogDate="Monday, July 22, 2024"
            blogCategory="Framework"
            blogDetail="Welcome to the vital world of financial compliance, where adherence to regulations is not just
              a best practice but a critical safeguard against business catastrophe. In this article, we will
              dissect the silent threats posed by non-compliance, unveiling how ignoring financial standards
              can dismantle even the most successful enterprises."
            blogDesktopPaddingTop="20"
            navigateToBlogPost="/blogs/blog-details/01"
          />
        </div>
      </div>
      {showCopyMessage && (
        <div className="h-[10vh] w-[100vw] fixed top-0 bg-green-500 z-20 flex justify-between items-center p-[20px] px-[40px]">
          <h1 className="text-white font-medium text-[1rem]">
            ✔ Copied to clipboard
          </h1>
          <div onClick={() => setShowCopyMessage(false)}>
            <CustomIcon SvgIcon={Times} size={24} />
          </div>
        </div>
      )}
    </div>
  );
}
