import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Layout from "../layout";
import Home from "../pages/Home";
import BecomeAPartner from "../pages/BecomeAPartner";
import Security from "../pages/Security";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import About from "../pages/About";
import Solutions from "../pages/Solutions";
import Faq from "../pages/FAQ";
import Legal from "../pages/Legal";
import PCSiDSS from "../components/solution-components/pcsi_Dss";
import BlogDetailsTwo from "../pages/BlogDetailTwo";
import BlogDetailThree from "../pages/BlogDetailThree";
import BlogDetailFour from "../pages/BlogDetailFour";
import BlogDetailFive from "../pages/BlogDetailsFive";
import BlogDetailSix from "../pages/BlogDetailSix";
import BlogDetailSeven from "../pages/BlogDetailSeven";
import BlogDetailEight from "../pages/BlogDetailEight";
import BlogDetailNine from "../pages/BlogDetailNine";

const MainRoutes = () => {
  const routes = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },

        {
          path: "/become-a-partner",
          element: <BecomeAPartner />,
        },
        {
          path: "/security",
          element: <Security />,
        },
        {
          path: "/blogs",
          element: <Blog />,
        },
        {
          path: "/blogs/blog-details",
          element: <BlogDetails />,
        },
        {
          path: "/blogs/blog-details/01",
          element: <BlogDetailsTwo />,
        },
        {
          path: "/blogs/blog-details/02",
          element: <BlogDetailThree />,
        },
        {
          path: "/blogs/blog-details/03",
          element: <BlogDetailFour />,
        },
        {
          path: "/blogs/blog-details/04",
          element: <BlogDetailFive />,
        },
        {
          path: "/blogs/blog-details/05",
          element: <BlogDetailSix />,
        },
        {
          path: "/blogs/blog-details/06",
          element: <BlogDetailSeven />,
        },
        {
          path: "/blogs/blog-details/07",
          element: <BlogDetailEight />,
        },
        {
          path: "/blogs/blog-details/08",
          element: <BlogDetailNine />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/solutions",
          children: [
            {
              path: "iso-27001",
              element: <Solutions />,
            },
            {
              path: "pci-dss",
              element: <PCSiDSS />,
            },
          ],
        },
        {
          path: "/faqs",
          element: <Faq />,
        },
        {
          path: "/legal",
          element: <Legal />,
        },
        {
          path: "*",
          element: <Navigate to="/" />,
        },
      ],
    },
  ]);

  return <RouterProvider router={routes} />;
};

export default MainRoutes;
