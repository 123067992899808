export default function TermsOfService() {
  return (
    <article className="text-[#455468] lg:w-[70%] lg:py-[48px]">
      <header className="py-5 flex flex-col gap-3 lg:gap-[20px] items-center">
        <h1 className="lg:text-[60px] text-4xl leading-[3.125rem] tracking-[-1%] text-[#060B59] font-bold">
          Terms of Service
        </h1>
        <p className="flex gap-2 text-xl leading-[2.125rem] tracking-[-0.3%]">
          <span className="text-[#455468] font-normal">Last Updated: </span>
          <span className="font-medium text-[#111111]">June 1, 2024</span>
        </p>
      </header>

      <section className="lg:pt-[48px] px-3">
        <div className="flex flex-col gap-3">
          <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%]">
            Introduction
          </h1>
          <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
            <p>
              Welcome to Vegeel Technology Limited ["Company", "we", "us", or
              "our"]. We are dedicated to providing top tier cyber security
              solutions to protect your digital assets and ensure your online
              safety. These Terms of Service ["Terms"] govern your use of our
              services, including but not limited to, our website, software, and
              any related services we offer [collectively referred to as the
              "Services"]. By accessing or using our Services, you agree to
              comply with and be bound by these Terms. Please read them
              carefully.
            </p>
            <p>
              This document is entitled "Terms of Service" and constitutes a
              legally binding agreement between you, the user ["you", "your"],
              and Vegeel Technology Limited. It outlines the rights,
              obligations, and conditions for using our Services. This agreement
              aims to protect both your interests and ours, ensuring a clear
              understanding of the terms under which we provide our Services.
            </p>
            <p>
              These Terms were last updated on <b>June 1, 2024.</b> We reserve
              the right to update or modify these Terms at any time, and we will
              notify you of any significant changes by posting the new Terms on
              our website. It is your responsibility to review these Terms
              periodically to stay informed about any updates. Your continued
              use of our Services after any changes signifies your acceptance of
              the revised Terms.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-3 lg:pt-[12px]">
          <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
            Overview of the Agreement
          </h1>
          <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3 lg:pt-[12px]">
            <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
              Purpose
            </h2>
            <div>
              <p>
                The purpose of these Terms is to establish the legal framework
                under which Vegeel Technology Limited offers its Services to
                you. Our services are designed to provide robust cyber security
                solutions, including automated compliance management,
                certification support, continuous monitoring and reporting,
                system integrations, policy management, and audit preparation
                and support.
              </p>
              <p>
                At Vegeel, we automate various compliance processes such as data
                protection, policy enforcement, and continuous monitoring to
                ensure our operations consistently meet industry standards and
                regulatory requirements. We assist in achieving and maintaining
                essential certifications for standards like GDPR, NDPR, and
                HIPAA, offering guidance and resources to navigate the
                certification process efficiently.
              </p>
            </div>
            <p>
              Our platform provides continuous monitoring of security controls
              and generates real-time reports, enabling us to promptly identify
              and address any compliance issues. To facilitate these processes,
              Vegeel seamlessly integrates with various tools and systems you
              may already be using, making evidence collection and compliance
              management straightforward.
            </p>
            <p>
              We also aid in the creation, management, and enforcement of
              security policies and procedures, ensuring they align with
              relevant compliance standards. Additionally, Vegeel offers
              comprehensive support for audit preparation, providing tools and
              resources to effectively demonstrate compliance to auditors and
              stakeholders. By clearly delineating the scope of our Services and
              your responsibilities as a user, we aim to create a secure and
              reliable environment for all parties involved.
            </p>
          </div>
          <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3 lg:pt-[12px]">
            <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
              Service Provided
            </h2>
            <div>
              <p>
                Vegeel Technology Limited offers a comprehensive suite of
                cybersecurity services tailored to meet the unique needs of our
                clients. Our offerings include, but are not limited to:
              </p>
            </div>
            <ul className="list-disc px-5">
              <li>
                Automated Compliance Management: Vegeel automates various
                compliance processes, such as data protection, policy
                enforcement, and continuous monitoring, to ensure our operations
                meet industry standards and regulatory requirements.
              </li>
              <li>
                Certification Support: We assist in achieving and maintaining
                certifications for key standards like GDPR, HIPAA, and other
                relevant regulations. Our platform provides guidance and
                resources to help you navigate the certification process
                efficiently.
              </li>
              <li>
                Continuous Monitoring and Reporting: Vegeel offers continuous
                monitoring of our security controls and generates real-time
                reports to maintain compliance. This helps us promptly identify
                and address any compliance issues.
              </li>
              <li>
                System Integrations: Our platform seamlessly integrates with
                various tools and systems you may already be using, facilitating
                easy evidence collection and streamlined compliance processes.
              </li>
              <li>
                Policy Management: Vegeel aids in the creation, management, and
                enforcement of security policies and procedures, ensuring they
                align with compliance standards.
              </li>
              <li>
                Audit Preparation and Support: We provide comprehensive tools
                and resources to prepare for external audits, helping to
                demonstrate compliance effectively to auditors and stakeholders.
              </li>
            </ul>
            <div>
              <p>
                The specific services provided to you will be detailed in a
                separate service agreement or order form, which will be
                considered part of these Terms, also by using
                <a href="www.facebook.com" target="_blank">
                  www.vegeel.com
                </a>
                , you agree to adhere to these standards and practices as
                outlined in our Terms.
              </p>
            </div>
          </div>
          <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
            <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
              User Obligation
            </h2>
            <div>
              <p>
                As a user of our Services, you agree to adhere to the following
                obligations:
              </p>
            </div>
            <ul className="list-disc pl-5">
              <li>
                Compliance with Laws: You must comply with all applicable local,
                national, and international laws and regulations while using our
                Services.
              </li>
              <li>
                Accurate Information: You are required to provide accurate and
                complete information when registering for our Services and keep
                such information updated.
              </li>
              <li>
                Account Security: You are responsible for maintaining the
                confidentiality of your account credentials and for any
                activities that occur under your account.
              </li>
              <li>
                Appropriate Use: You agree to use our Services only for lawful
                purposes and in accordance with these Terms. Prohibited
                activities include, but are not limited to, unauthorized access
                to other systems, distribution of malware, and any actions that
                compromise the security of any network or system.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Acceptance of Terms
            </h1>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Binding Agreement
              </h2>
              <p>
                By accessing or using our Services, you acknowledge that you
                have read, understood, and agree to be bound by these Terms,
                along with our Privacy Policy and any additional terms and
                conditions that may apply to specific sections of the Services
                or to products and services we make available to you. If you do
                not agree to these Terms, you must not use our Services.
              </p>
            </div>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Consent to Electronic Communications
              </h2>
              <p>
                By using our Services, you consent to receive communications
                from us electronically. We will communicate with you by email or
                by posting notices on our website. You agree that all
                agreements, notices, disclosures, and other communications we
                provide to you electronically satisfy any legal requirement that
                such communications be in writing.
              </p>
            </div>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Representations and Warranties
              </h2>
              <div>
                <p>By using our Services, you represent and warrant that:</p>
              </div>
              <ol className="list-decimal pl-5">
                <li>
                  Legal Capacity: You have the legal capacity and authority to
                  enter into this agreement on behalf of yourself or the entity
                  you represent.
                </li>
                <li>
                  Accurate Information: The information you provide to us is
                  accurate, complete, and current.
                </li>
                <li>
                  Compliance: You will comply with all applicable laws and
                  regulations in connection with your use of the Services.
                </li>
              </ol>
            </div>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Modifications of Terms
              </h2>
              <p>
                As a dynamic cyber security company, Vegeel Technology Limited
                is committed to continuously improving and adapting our Services
                to meet evolving security challenges and client needs. To
                maintain the effectiveness and relevance of our Terms of
                Service["Terms"], we reserve the right to modify these Terms at
                any time. This section outlines your and our responsibilities
                concerning modifications to the Terms, ensuring clarity and
                transparency in our communications and operations.
              </p>
            </div>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Right to Modify
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Necessity of Modification</li>
                <div className="pt-5">
                  <p>
                    In the rapidly changing landscape of cyber security, it is
                    essential to update our Terms periodically. Modifications
                    may be necessary to:
                  </p>
                  <ul className="list-disc pl-10 py-5">
                    <li>
                      Reflect changes in legal and regulatory requirements.
                    </li>
                    <li>
                      Incorporate advancements in technology and security
                      practices.
                    </li>
                    <li>Respond to feedback from clients and stakeholders.</li>
                    <li>Enhance clarity and readability.</li>
                    <li>
                      Address new features, services, or business practices.
                    </li>
                  </ul>
                </div>
                <li className="ml-5 font-bold">Scope of Modifications</li>
                <div className="pt-5">
                  <p>
                    Modifications to the Terms can include, but are not limited
                    to:
                  </p>
                  <ul className="list-disc pl-10 py-5">
                    <li>Additions or deletions of provisions.</li>
                    <li>Amendments to existing clauses.</li>
                    <li>
                      Changes to our service offerings, pricing, or payment
                      terms.
                    </li>
                    <li>
                      Updates to our privacy practices or data handling
                      procedures.
                    </li>
                  </ul>
                </div>
                <li className="ml-5 font-bold">Authority to Modify</li>
                <div className="pt-5">
                  <p>
                    Vegeel Technology Limited reserves the exclusive right to
                    modify these Terms at our sole discretion. We may implement
                    these changes with or without notice, depending on the
                    nature and significance of the modification. It is our
                    priority to ensure that any changes align with our
                    commitment to providing exceptional and secure services to
                    our clients.
                  </p>
                </div>
              </ol>
            </div>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Notification of Changes
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Methods of Notification</li>
                <div className="pt-5">
                  <p>
                    When we make changes to these Terms, we will endeavor to
                    inform you in a timely and effective manner. The primary
                    methods of notification include:
                  </p>
                  <ul className="list-disc pl-10 py-5">
                    <li>
                      Email Notification: We will send an email to the address
                      associated with your account, detailing the changes and
                      their implications.
                    </li>
                    <li>
                      Website Notification: We will post a notice on our
                      website, highlighting the modifications and providing a
                      link to the revised Terms.
                    </li>
                    <li>
                      In-Service Notifications: For significant changes, we may
                      display notifications within the service interfaces you
                      use, ensuring that you are aware of the updates as you
                      interact with our platform.
                    </li>
                  </ul>
                </div>
                <li className="ml-5 font-bold">Timing of Notification</li>
                <div className="pt-5">
                  <p>
                    We understand the importance of giving you sufficient time
                    to review and understand any changes to our Terms .For
                    material changes, we will provide at least 30 days' notice
                    before the new terms take effect. This period allows you to
                    review the changes, seek clarification if needed, and make
                    informed decisions about your continued use of our Services.
                  </p>
                </div>
                <li className="ml-5 font-bold">
                  Understanding Material Changes
                </li>
                <div className="py-5">
                  <p>
                    Material changes are those that could significantly impact
                    your rights, obligations, or use of our Services. These may
                    include, but are not limited to:
                  </p>
                  <ul className="list-disc pl-10 py-5">
                    <li>
                      Changes to our data collection, usage, or sharing
                      practices.
                    </li>
                    <li>Alterations to dispute resolution procedures.</li>
                    <li>
                      Modifications that affect the scope or cost of the
                      Services provided.
                    </li>
                  </ul>
                </div>
                <div className="pb-5">
                  <p>
                    Non-material changes, such as clarifications or
                    typographical corrections, may be implemented without prior
                    notice.
                  </p>
                </div>
                <li className="ml-5 font-bold">Your Responsibility</li>
                <div className="py-5">
                  <p>
                    It is your responsibility to review any notifications of
                    changes to the Terms. By continuing to use our Services
                    after the effective date of any modifications, you agree to
                    be bound by the updated Terms. If you do not agree to the
                    new Terms, you must discontinue your use of the Services.
                  </p>
                </div>
                <li className="ml-5 font-bold">Access to Previous Versions</li>
                <div className="pt-5">
                  <p>
                    For transparency, we will maintain an archive of previous
                    versions of our Terms on our website. This allows you to
                    review historical changes and understand the evolution of
                    our policies and practices overtime.
                  </p>
                </div>
              </ol>
            </div>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Implication of Changes
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Continous Improvement</li>
                <div className="py-5">
                  <p>
                    Modifications to our Terms reflect our commitment to
                    continuous improvement. By adapting our policies and
                    practices, we aim to enhance the security, reliability, and
                    user experience of our Services. Your feedback plays a
                    crucial role in this process, and we encourage you to share
                    your thoughts and suggestions with us.
                  </p>
                </div>
                <li className="ml-5 font-bold">Legal Compliance</li>
                <div className="py-5">
                  <p>
                    Staying compliant with evolving legal and regulatory
                    requirements is paramount in the cybersecurity industry.
                    Changes to our Terms ensure that we meet our legal
                    obligations and provide a framework that supports ethical
                    and lawful business practices.
                  </p>
                </div>
                <li className="ml-5 font-bold">Enhanced Clarity</li>
                <div className="py-5">
                  <p>
                    We strive to make our Terms as clear and understandable as
                    possible. Updates and modifications often aim to simplify
                    language, reduce ambiguity, and provide more precise
                    definitions and explanations. This helps you better
                    understand your rights and obligations when using our
                    Services.
                  </p>
                </div>
                <li className="ml-5 font-bold">
                  Adapting to Technological Advances
                </li>
                <div className="py-5">
                  <p>
                    The field of cybersecurity is characterized by rapid
                    technological advancements. By updating our Terms, we can
                    incorporate the latest technologies and best practices into
                    our Services, ensuring that you benefit from the most
                    effective and cutting-edge security solutions available.
                  </p>
                </div>
              </ol>
            </div>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Commitment to Transparency
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Open Communication</li>
                <div className="py-5">
                  <p>
                    We are committed to maintaining open and transparent
                    communication with our clients. When significant changes to
                    the Terms are made, we will provide detailed explanations of
                    the reasons for the changes and how they impact you. Our
                    goal is to build and maintain trust by keeping you informed
                    and engaged.
                  </p>
                </div>
                <li className="ml-5 font-bold">Feedback and Queries</li>
                <div className="py-5">
                  <p>
                    Your understanding and satisfaction are important to us. If
                    you have any questions or concerns about changes to the
                    Terms, we encourage you to contact us. Our support team is
                    available to provide clarification and assist you in
                    understanding how the modifications affect your use of our
                    Services.
                  </p>
                </div>
                <li className="ml-5 font-bold">
                  Collaboration with Stakeholders
                </li>
                <div className="py-5">
                  <p>
                    We value the input of our clients and other stakeholders in
                    the cybersecurity community. When appropriate, we may seek
                    feedback from our user base before implementing significant
                    changes to the Terms. This collaborative approach helps us
                    create policies that better serve the needs and expectations
                    of our clients.
                  </p>
                </div>
              </ol>
            </div>
            <div className="text-justify font-normal text-base leading-7 tracking-[0.3%] flex flex-col gap-3">
              <h2 className="font-medium text-xl leading-[2.125rem] tracking-[-0.3%]">
                Dispute Resolution
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Addressing Disagreements</li>
                <div className="py-5">
                  <p>
                    In the event of a disagreement or dispute regarding changes
                    to the Terms, we are committed to resolving issues amicably
                    and efficiently. We encourage you to contact us directly to
                    discuss any concerns. We will make every effort to address
                    your concerns and find a satisfactory resolution.
                  </p>
                </div>
                <li className="ml-5 font-bold">
                  Arbitration and Legal Proceedings
                </li>
                <div className="py-5">
                  <p>
                    If a dispute cannotbe resolved throughdirect communication,
                    the revised Terms will outlinethe procedures for arbitration
                    or legal proceedings. These procedures are designed to
                    ensure a fair and impartial resolution of disputes, in
                    accordance with applicable laws and regulations.
                  </p>
                </div>
              </ol>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Use of the Site
            </h1>
            <p>
              By accessing or using our Platform, you agree to comply with and
              be bound by these Terms of Service ["Terms"]. This section
              outlines your obligations regarding the lawful use of the Platform
              and specifies activities that are strictly prohibited. Our goal is
              to maintain a secure, respectful, and lawful environment for all
              users.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Lawful Use
            </h1>
            <ol className="list-decimal">
              <li className="ml-5 font-bold">Compliance with Laws</li>
              <div className="py-5">
                <p>
                  When using our Platform, you must comply with all applicable
                  local, national, and international laws and regulations. This
                  includes but is not limited to:
                </p>
                <ul className="list-disc ml-5 py-5">
                  <li>
                    Data Protection Laws: You must adhere to all relevant data
                    protection and privacy laws, including the General Data
                    Protection Regulation [GDPR], Nigeria Data Protection
                    Regulation [NDPR], and other applicable legislation.
                  </li>
                  <li>
                    Intellectual Property Laws: You must respect all
                    intellectual property rights, including copyrights,
                    trademarks, patents, and trade secrets.
                  </li>
                  <li>
                    Cyber Security Laws: You must comply with laws related to
                    cyber security, including those governing unauthorized
                    access to computer systems and data.
                  </li>
                </ul>
              </div>
              <li className="ml-5 font-bold">Accurate Information</li>
              <div className="py-5">
                <p>
                  You agree to provide accurate, current, and complete
                  information when interacting with our Platform. This includes
                  any information you provide when registering for an account,
                  subscribing to our services, or contacting us. Providing false
                  or misleading information is strictly prohibited and may
                  result in termination of your access to the Platform.
                </p>
              </div>
              <li className="ml-5 font-bold">Responsible Use</li>
              <div className="py-5">
                <p>
                  You are responsible for using the Platform in a manner that
                  does not harm or disrupt its functionality or the experience
                  of other users. This includes, but is not limited to:
                </p>
                <ul className="list-disc ml-5 py-5">
                  <li>
                    System Integrity: You must not interfere with or compromise
                    the integrity or performance of the Platform, its servers,
                    or the networks connected to it.
                  </li>
                  <li>
                    Respect for Others: You must use the Platform in a
                    respectful and lawful manner, avoiding any actions that
                    could harm other users or third parties.
                  </li>
                </ul>
              </div>
              <li className="ml-5 font-bold">Personal Use and Business Use</li>
              <div className="py-5">
                <p>
                  Our Platform is designed to support both personal and business
                  use. Whether you are an individual or a representative of a
                  business entity, you agree to use the Platform solely for
                  lawful and legitimate purposes. Unauthorized commercial use of
                  the Platform is strictly prohibited unless expressly
                  authorized by Vegeel Technology Limited.
                </p>
              </div>
              <li className="ml-5 font-bold">Account Security</li>
              <div className="py-5">
                <p>
                  If you create an account on our Platform, you are responsible
                  for maintaining the confidentiality of your login credentials.
                  You agree to:
                </p>
                <ul className="list-disc ml-5 pt-5">
                  <li>
                    Keep Credentials Secure: Ensure that your username and
                    password are kept secure and confidential.
                  </li>
                  <li>
                    Monitor Activity: Regularly monitor your account for any
                    unauthorized access or unusual activity.
                  </li>
                  <li>
                    Report Incidents: Immediately notify us of any suspected
                    security breaches or unauthorized use of your account.
                  </li>
                </ul>
              </div>
            </ol>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Prohibited Activities
            </h1>
            <div className="py-5">
              <p>
                To maintain a safe and lawful environment, certain activities
                are strictly prohibited on our Platform. Engaging in any of the
                following activities may result in immediate suspension or
                termination of your access to the Platform, and may also result
                in legal action:
              </p>
            </div>
            <ol className="list-decimal">
              <li className="ml-5 font-bold">Unauthorized Access and Use</li>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Hacking: You must not attempt to gain unauthorized access to
                  any part of the Platform, its servers, or any systems or
                  networks connected to it. This includes using hacking,
                  phishing, or other techniques to breach security.
                </li>
                <li>
                  Data Theft: You must not attempt to obtain or steal data from
                  the Platform or from other users without authorization.
                </li>
              </ul>
              <li className="ml-5 font-bold">Malicious Activities</li>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Malware Distribution: You must not distribute or transmit any
                  viruses, worms, malware, or any other harmful software or code
                  that could damage or disrupt the Platform or the systems of
                  other users.
                </li>
                <li>
                  Denial of Service Attacks: You must not engage in activities
                  that could overwhelm or disrupt the normal operation of the
                  Platform, such as denial-of-service [DoS] or distributed
                  denial-of-service [DDoS] attacks.
                </li>
              </ul>
              <li className="ml-5 font-bold">Fraud and Misrepresentation</li>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Identity Theft: You must not impersonate any person or entity,
                  or falsely state or otherwise misrepresent your affiliation
                  with a person or entity.
                </li>
                <li>
                  False Information: You must not provide false or misleading
                  information when using the Platform, including during the
                  registration or subscription process.
                </li>
              </ul>
              <li className="ml-5 font-bold">Unlawful Content</li>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Illegal Activities: You must not use the Platform to promote
                  or engage in any illegal activities, including but not limited
                  to, activities related to drugs, human trafficking, or
                  terrorism.
                </li>
                <li>
                  Obscene or Offensive Content: You must not post, upload, or
                  distribute any content that is defamatory, obscene, offensive,
                  or otherwise inappropriate. This includes content that
                  promotes hate speech, violence, or discrimination..
                </li>
              </ul>
              <li className="ml-5 font-bold">
                Intellectual Property Infringement
              </li>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Unauthorized Use: You must not use, copy, or distribute any
                  content from the Platform without proper authorization. This
                  includes the unauthorized use of copyrighted materials,
                  trademarks, or other intellectual property.
                </li>
                <li>
                  Piracy: You must not engage in or promote software piracy or
                  the unauthorized sharing of digital content.
                </li>
              </ul>
              <li className="ml-5 font-bold">
                Spamming and Unsolicited Communications
              </li>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Spam: You must not use the Platform to send unsolicited or
                  unauthorized advertising, promotional materials, junk mail,
                  spam, chain letters, or any other form of solicitation.
                </li>
                <li>
                  Harvesting: You must not harvest or collect email addresses or
                  other contact information of users from the Platform for the
                  purpose of sending unsolicited emails or other communications.
                </li>
              </ul>
              <li className="ml-5 font-bold">
                Exploitation of Vulnerabilities
              </li>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Security Testing: You must not conduct security testing on the
                  Platform without explicit permission from Vegeel Technology
                  Limited. Unauthorized testing, including vulnerability
                  scanning and penetration testing, is strictly prohibited.
                </li>
                <li>
                  Exploiting Bugs: You must not exploit any bugs, errors, or
                  vulnerabilities in the Platform to gain unauthorized access or
                  advantages.
                </li>
              </ul>
              <li className="ml-5 font-bold">Data Privacy Violations</li>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Unauthorized Data Collection: You must not collect or harvest
                  any personally identifiable information [PII] from the
                  Platform without proper authorization.
                </li>
                <li>
                  Privacy Breach: You must not use the Platform in any manner
                  that violates the privacy of others, including the
                  unauthorized sharing or exposure of personal data.
                </li>
              </ul>
            </ol>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Consequences of Violations
            </h1>
            <ol className="list-decimal">
              <li className="ml-5 font-bold">
                Account Suspension or Termination
              </li>
              <div className="py-5">
                <p>
                  If you engage in any prohibited activities or violate these
                  Terms, we reserve the right to suspend or terminate your
                  access to the Platform without prior notice. Depending on the
                  severity of the violation, we may also pursue legal action to
                  protect our interests and those of other users.
                </p>
              </div>
              <li className="ml-5 font-bold">Legal Action</li>
              <div className="py-5">
                <p>
                  Engaging in illegal activities or actions that cause harm to
                  the Platform or other users may result in civil or criminal
                  penalties. We will cooperate with law enforcement authorities
                  to investigate and prosecute violations to the fullest extent
                  of the law.
                </p>
              </div>
              <li className="ml-5 font-bold">Notification of Violations</li>
              <div className="py-5">
                <p>
                  If we become aware of a violation of these Terms, we will take
                  appropriate action, which may include:
                </p>
              </div>
              <ul className="ml-5 py-5 list-disc">
                <li>
                  Investigating the Violation: Conducting an investigation to
                  understand the nature and extent of the violation.
                </li>
                <li>
                  Notifying the User: Informing the user involved about the
                  violation and the actions being taken.
                </li>
                <li>
                  Rectifying the Issue: Implementing measures to rectify the
                  issue and prevent future occurrences.
                </li>
              </ul>
            </ol>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Reporting Violations
            </h1>
            <ol className="list-decimal">
              <li className="ml-5 font-bold">User Responsibility</li>
              <div className="py-5">
                <p>
                  If you become aware of any prohibited activities or violations
                  of these Terms on our Platform, we encourage you to report
                  them to us immediately. Your proactive reporting helps us
                  maintain a safe and lawful environment for all users.
                </p>
              </div>
              <li className="ml-5 font-bold">Reporting Mechanisms</li>
              <div className="py-5">
                <p>You can report violations through the following channels:</p>
              </div>
              <ul className="list-disc ml-5 py-5">
                <li>
                  Email: Send an email to{" "}
                  <a href="gmail.com">support@vegeel.com</a> with details of the
                  suspected violation.
                </li>
                <li>
                  Contact Form: Use the contact form available on our Platform
                  to submit a report.
                </li>
                <li>
                  Customer Support: Contact our customer support team directly
                  via phone or chat.
                </li>
              </ul>
            </ol>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Commitment to a Safe Environment
            </h1>
            <ol className="list-decimal">
              <li className="ml-5 font-bold">User Cooperation</li>
              <div className="py-5">
                <p>
                  We appreciate the cooperation of our users in maintaining the
                  integrity and security of our Platform. By adhering to these
                  Terms and promptly reporting violations, you contribute to a
                  safe and respectful online community.
                </p>
              </div>
              <li className="ml-5 font-bold">User Cooperation</li>
              <div className="py-5">
                <p>
                  We appreciate the cooperation of our users in maintaining the
                  integrity and security of our Platform. By adhering to these
                  Terms and promptly reporting violations, you contribute to a
                  safe and respectful online community.
                </p>
              </div>
              <li className="ml-5 font-bold">Continuous Monitoring</li>
              <div className="py-5">
                <p>
                  Vegeel Technology Limited employs advanced monitoring and
                  security measures to detect and prevent prohibited activities.
                  We continuously review and update our security protocols to
                  address emerging threats and vulnerabilities.
                </p>
              </div>
              <li className="ml-5 font-bold">Education and Awareness</li>
              <div className="py-5">
                <p>
                  We are committed to educating our users about cyber security
                  best practices. Through our Platform and other communication
                  channels, we provide resources and guidance to help you
                  protect yourself and others from online threats.
                </p>
              </div>
              <li className="ml-5 font-bold">Feedback and Improvement</li>
              <div className="py-5">
                <p>
                  Your feedback is invaluable in helping us improve our services
                  and security measures. We encourage you to share your
                  suggestions and concerns, enabling us to enhance the user
                  experience and ensure a secure environment.
                </p>
              </div>
            </ol>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Account Registration
            </h1>
            <div>
              <p>
                We are committed to providing top-notch cyber security services
                to safeguard your digital assets. To utilize our services
                effectively, you will need to register for an account. This
                section outlines the terms and conditions related to account
                registration, including account creation, the requirement for
                accurate information, and the importance of account security.
              </p>
            </div>
            <div>
              <h2 className="text-[#455468] font-bold text-2xl leading-9 tracking-[-0.4%] py-5">
                Account Creation
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Eligibility</li>
                <div className="py-5">
                  <p>
                    To create an account with Vegeel Technology Limited, you
                    must meet the following eligibility criteria:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Legal Age: You must be at least 18 years old or the age of
                    majority in your jurisdiction to create an account. If you
                    are under the age of majority, you must have the consent of
                    a parent or guardian to use our services.
                  </li>
                  <li>
                    Legal Authority: You must have the legal authority to enter
                    into a binding agreement with Vegeel Technology Limited,
                    whether on your own behalf or on behalf of an organization.
                  </li>
                </ul>
                <li className="ml-5 font-bold">Registration Process</li>
                <div className="py-5">
                  <p>
                    The account creation process involves the following steps:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Account Registration: Visit our website and navigate to the
                    registration page. Provide the required information,
                    including your name, email address, and other necessary
                    details.
                  </li>
                  <li>
                    Verification: We may require you to verify your email
                    address or phone number to activate your account. This
                    verification helps ensure the security and integrity of our
                    services.
                  </li>
                  <li>
                    Acceptance of Terms: By creating an account, you agree to
                    comply with these Terms of Service and any other applicable
                    terms and policies, including our Privacy Policy.
                  </li>
                </ul>
                <li className="ml-5 font-bold">User Responsibilities</li>
                <div className="py-5">
                  <p>As a user, you are responsible for:</p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Maintaining the confidentiality of your account information,
                    including your username and password.
                  </li>
                  <li>
                    Ensuring that your account details are accurate and
                    up-to-date.
                  </li>
                  <li>
                    All activities that occur under your account, whether
                    authorized by you or not.
                  </li>
                </ul>
              </ol>
            </div>
            <div>
              <h2 className="text-[#455468] font-bold text-2xl leading-9 tracking-[-0.4%] py-5">
                Accurate Information
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Truthful Information</li>
                <div className="py-5">
                  <p>
                    You agree to provide accurate, current, and complete
                    information during the registration process and to update
                    such information as necessary to ensure its accuracy.
                    Providing false, misleading, or incomplete information may
                    result in the suspension or termination of your account and
                    may also lead to legal consequences.
                  </p>
                </div>
                <li className="ml-5 font-bold">Contact Information</li>
                <div className="py-5">
                  <p>
                    It is essential to provide valid contact information,
                    including your email address and phone number, to facilitate
                    communication regarding your account and our services. We
                    use this information to:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>Verify your identity during the registration process.</li>
                  <li>
                    Send important notifications, including updates to our Terms
                    of Service and Privacy Policy.
                  </li>
                  <li>
                    Contact you regarding any issues or inquiries related to
                    your account.
                  </li>
                </ul>
                <li className="ml-5 font-bold">Updating Information</li>
                <div className="py-5">
                  <p>
                    You agree to promptly update your account information if
                    there are any changes. This includes changes to your contact
                    information, billing details, and any other relevant
                    information. Keeping your information up-to date ensures
                    that we can provide you with timely and accurate services.
                  </p>
                </div>
                <li className="ml-5 font-bold">
                  Consequences of Inaccurate Information
                </li>
                <div className="py-5">
                  <p>
                    Providing inaccurate or outdated information may result in:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>Delayed or failed service delivery.</li>
                  <li>Inability to access certain features or services.</li>
                  <li>Suspension or termination of your account.</li>
                </ul>
              </ol>
            </div>
            <div>
              <h2 className="text-[#455468] font-bold text-2xl leading-9 tracking-[-0.4%] py-5">
                Account Security
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Password Security</li>
                <div className="py-5">
                  <p>
                    You are responsible for maintaining the confidentiality of
                    your account password and for taking appropriate measures to
                    secure your account. This includes:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Choosing a Strong Password: Use a combination of letters,
                    numbers, and special characters to create a strong password.
                    Avoid using easily guessable information such as your name
                    or birthdate.
                  </li>
                  <li>
                    Changing Your Password Regularly: Periodically update your
                    password to enhance security. We recommend changing your
                    password at least every six months.
                  </li>
                  <li>
                    Not Sharing Your Password: Do not share your password with
                    anyone. If you suspect that your password has been
                    compromised, change it immediately and notify us.
                  </li>
                </ul>
                <li className="ml-5 font-bold">Unauthorized Access</li>
                <div className="py-5">
                  <p>
                    You agree to notify us immediately if you become aware of
                    any unauthorized access to or use of your account. This
                    includes:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Unusual Account Activity: Monitor your account regularly for
                    any unusual or suspicious activity.
                  </li>
                  <li>
                    Changing Your Password Regularly: Periodically update your
                    password to enhance security. We recommend changing your
                    password at least every six months.
                  </li>
                  <li>
                    Immediate Reporting: Contact our customer support team
                    immediately if you suspect thatyour account has been
                    compromised. Provide as much information as possible to help
                    usinvestigate and secure your account.
                  </li>
                </ul>
                <li className="ml-5 font-bold">Security Measures</li>
                <div className="py-5">
                  <p>
                    We implement various security measures to protect your
                    account and personal information. These measures include:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Encryption: We use encryption to protect sensitive
                    information transmitted over the internet.
                  </li>
                  <li>
                    Two-Factor Authentication [2FA): We offer 2FA to provide an
                    additional layer of security foryour account. We strongly
                    encourage you to enable this feature.
                  </li>
                  <li>
                    Regular Security Audits: We conduct regular security audits
                    and assessments to identify andaddress potential
                    vulnerabilities.
                  </li>
                </ul>
                <li className="ml-5 font-bold">User Responsibility</li>
                <div className="py-5">
                  <p>
                    While we take security seriously and implement various
                    measures to protect your account, you also play a crucial
                    role in ensuring the security of your account. Your
                    responsibilities include:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Securing Your Devices: Ensure that the devices you use to
                    access our services are secureand protected from malware and
                    unauthorized access.
                  </li>
                  <li>
                    Using Secure Networks: Avoid accessing your account over
                    unsecured or public Wi-Fi networks.Use secure and trusted
                    networks whenever possible.
                  </li>
                  <li>
                    Being Vigilant: Be cautious of phishing attempts and other
                    fraudulent activities that may compromise your account. Do
                    not click on suspicious links or provide your account
                    information to unverified sources.
                  </li>
                </ul>
              </ol>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              Intellectual Property
            </h1>
            <div>
              <p>
                We prioritize the protection of intellectual property rights for
                the benefit of our company and our users. This section outlines
                the terms and conditions related to the ownership of content on
                our website and services, as well as the restrictions on the use
                of our intellectual property.
              </p>
            </div>
            <div>
              <h2 className="text-[#455468] font-bold text-2xl leading-9 tracking-[-0.4%] py-5">
                Accurate Information
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">
                  Company’s Intellectual Property
                </li>
                <div className="py-5">
                  <p>
                    All content provided on our website and through our services
                    is the property of Vegeel Technology Limited or its
                    licensors. This includes, but is not limited to:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Text: All written content, including articles, reports,
                    blogs, and other textual materials. Graphics: All graphical
                    elements, such as logos, icons, images, and illustrations.
                  </li>
                  <li>
                    Software: All software, including source code, object code,
                    and related materials.
                  </li>
                  <li>
                    Design: The overall design, layout, look, and feel of our
                    website and services.
                  </li>
                  <li>
                    Multimedia: All multimedia content, including videos, audio
                    recordings, and animations.
                  </li>
                </ul>
                <div className="py-5">
                  <p>
                    This content is protected by copyright, trademark, patent,
                    trade secret, and other intellectual property laws.
                  </p>
                </div>
                <li className="ml-5 font-bold">User-Generated Content</li>
                <div className="py-5">
                  <p>
                    When you submit, upload, or otherwise provide content to our
                    website or services ["User-GeneratedContent"], you retain
                    ownership of your content. However, by providing
                    User-Generated Content, you grant Vegeel Technology Limited
                    a worldwide, non-exclusive, royalty-free, transferable,
                    andsublicensable license to use, reproduce, distribute,
                    prepare derivative works of, display, and perform your
                    User-Generated Content in connection with the operation of
                    our services.
                  </p>
                </div>
                <li className="ml-5 font-bold">Third-Party Content</li>
                <div className="py-5">
                  <p>
                    Some content on our website and services may be owned by
                    third parties and used with theirpermission. This
                    third-party content is also protected by intellectual
                    property laws and is subject tothe terms and conditions
                    imposed by the respective owners.
                  </p>
                </div>
              </ol>
            </div>
            <div>
              <h2 className="text-[#455468] font-bold text-2xl leading-9 tracking-[-0.4%] py-5">
                Restriction of Use
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">Prohibited Uses</li>
                <div className="py-5">
                  <p>
                    You agree not to use our intellectual property in any way
                    that:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Violates Laws: Infringes upon or violates any applicable
                    local, national, or international laws and regulations.
                  </li>
                  <li>
                    Infringes Rights: Infringes upon or violates our
                    intellectual property rights or the rights of others,
                    including copyrights, trademarks, patents, and trade
                    secrets.
                  </li>
                  <li>
                    Misuses Content: Uses our content in a manner that is
                    deceptive, misleading, or fraudulent.
                  </li>
                </ul>
                <li className="ml-5 font-bold">
                  Limitations on Reproduction and Distribution
                </li>
                <div className="py-5">
                  <p>
                    Unless expressly permitted by these Terms of Service or
                    authorized in writing by Vegeel Technology Limited, you
                    agree not to:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Copy or Reproduce: Copy, reproduce, distribute, or create
                    derivative works based on our content.
                  </li>
                  <li>
                    Public Display or Performance: Publicly display or perform
                    any of our content without our prior written consent.
                  </li>
                  <li>
                    Modification or Adaptation: Modify, adapt, or alter our
                    content in any way.
                  </li>
                </ul>
                <li className="ml-5 font-bold">Use of Trademarks</li>
                <div className="py-5">
                  <p>
                    Our trademarks, logos, and service marks ["Marks"] are the
                    exclusive property of Vegeel Technology Limited. You are
                    prohibited from using our Marks without our prior written
                    consent. This includes, but is not limited to:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Misrepresentation: Using our Marks in a manner that suggests
                    an endorsement or affiliation thatdoes not exist.
                  </li>
                  <li>
                    Domain Names: Registering domain names that include our
                    Marks or are confusingly similar to our Marks.
                  </li>
                  <li>
                    Advertising: Using our Marks in any advertising, marketing,
                    or promotional materials without authorization.
                  </li>
                </ul>
                <li className="ml-5 font-bold">
                  Digital Millennium Copyright Act (DCMA) Compliance
                </li>
                <div className="py-5">
                  <p>
                    Vegeel Technology Limited complies with the provisions of
                    the Digital Millennium Copyright Act [DMCA]. If you believe
                    that your copyrighted work has been copied in a way that
                    constitutes copyright infringement, please provide our
                    designated DMCA agent with the following information:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Identification of the copyrighted work: Provide a
                    description of the copyrighted work that you claim has been
                    infringed.
                  </li>
                  <li>
                    Identification of the infringing material: Provide a
                    description of where the infringing material islocated on
                    our website or services.
                  </li>
                  <li>
                    Contact Information: Provide your contact information,
                    including your name, address, telephone number, and email
                    address.
                  </li>
                  <li>
                    Good Faith Statement: Include a statement that you have a
                    good faith belief that the use of the material is not
                    authorized by the copyright owner, its agent, or the law.
                  </li>
                  <li>
                    Statement of Accuracy: Include a statement that the
                    information in your notice is accurate and, under penalty of
                    perjury, that you are authorized to act on behalf of the
                    copyright owner.
                  </li>
                  <li>
                    Signature: Provide a physical or electronic signature of the
                    copyright owner or a person authorized to act on their
                    behalf.
                  </li>
                </ul>
                <div className="py-5">
                  <p>
                    Our designated DMCA agent for notice of claims of copyright
                    infringement can be reached at: [Your □MCA Agent Contact
                    Information].
                  </p>
                </div>
                <li className="ml-5 font-bold">Licensing and Permissions</li>
                <div className="py-5">
                  <p>
                    If you wish to use any of our content for purposes not
                    expressly permitted by these Terms ofService, you must
                    obtain written permission from Vegeel Technology Limited.
                    Requests for permissionshould be directed to: 35 Adebisi St,
                    Yaba, Lagos, Nigeria.
                  </p>
                </div>
                <li className="ml-5 font-bold">
                  User-Generated Content Restrictions
                </li>
                <div className="py-5">
                  <p>
                    When you submit User-Generated Content to our website or
                    services, you agree not to:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Infringe Rights: Submit content that infringes the
                    intellectual property rights of others.
                  </li>
                  <li>
                    Submit Illegal Content: Submit content that is unlawful,
                    defamatory, obscene, or otherwise objectionable.
                  </li>
                  <li>
                    Misrepresent Ownership: Misrepresent the origin or ownership
                    of the content you submit.
                  </li>
                </ul>
                <li className="ml-5 font-bold">
                  Reporting Intellectual property Infringements
                </li>
                <div className="py-5">
                  <p>
                    If you believe that your intellectual property rights have
                    been violated on our website or services, please contact us
                    at 35 Adebisi St, Yaba, Lagos, Nigeria with a detailed
                    description of the alleged infringement. We will investigate
                    the matter and take appropriate action, which may include
                    removing the infringing content or terminating the accounts
                    of repeat infringers.
                  </p>
                </div>
              </ol>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%] pt-5">
              User Content
            </h1>
            <div>
              <h2 className="text-[#455468] font-bold text-2xl leading-9 tracking-[-0.4%] py-5">
                User Responsibilities
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">
                  Compliance with Laws and Policies
                </li>
                <div className="py-5">
                  <p>
                    When submitting content to our website or services, you
                    agree to comply with all applicable laws and regulations, as
                    well as our Terms of Service and Privacy Policy.
                    Specifically, you agree not to:
                  </p>
                  <ul className="ml-5 py-5 list-disc">
                    <li>
                      Submit Illegal Content: Upload or share any content that
                      is unlawful, defamatory, obscene, offensive, or otherwise
                      objectionable.
                    </li>
                    <li>
                      Infringe Rights: Submit content that infringes the
                      intellectual property rights, privacy rights, or other
                      legal rights of any third party.
                    </li>
                  </ul>
                </div>
                <li className="ml-5 font-bold">Accuracy and Integrity</li>
                <div className="py-5">
                  <p>
                    You are responsible for ensuring that any content you submit
                    is accurate, truthful, and does not mislead or deceive
                    others. This includes:
                  </p>
                  <ul className="ml-5 py-5 list-disc">
                    <li>
                      Representations and Claims: Ensuring that any statements,
                      claims, or representations made in your content are
                      accurate and can be substantiated.
                    </li>
                    <li>
                      Personal Information: Avoiding the submission of false or
                      misleading personal information, both about yourself and
                      others.
                    </li>
                  </ul>
                </div>
                <li className="ml-5 font-bold">Quality of Content</li>
                <div className="py-5">
                  <p>
                    You agree to ensure that any content you submit is of high
                    quality and relevant to the topics and discussions on our
                    platform. This includes:
                  </p>
                  <ul>
                    <li>
                      Clarity and Relevance: Making sure that your content is
                      clear, well-written, and relevant to the context in which
                      it is posted.
                    </li>
                    <li>
                      Respectful Communication: Engaging in respectful and
                      constructive communication with other users, avoiding
                      abusive or harassing language.
                    </li>
                  </ul>
                </div>
                <li className="ml-5 font-bold">Security Practices</li>
                <div className="py-5">
                  <p>
                    As a user of a cyber security platform, you are expected to
                    follow best practices for maintaining the security of your
                    account and content. This includes:
                  </p>
                  <ul className="ml-5 py-5 list-disc">
                    <li>
                      Account Security: Keeping your account credentials
                      confidential and not sharing them with others. Notify us
                      immediately if you suspect any unauthorized use of your
                      account.
                    </li>
                    <li>
                      Secure Submissions: Ensuring that any files or links you
                      submit are free from viruses, malware, or other harmful
                      components.
                    </li>
                  </ul>
                </div>
                <li className="ml-5 font-bold">
                  Content Monitoring and Removal
                </li>
                <div className="py-5">
                  <p>
                    You acknowledge that Vegeel Technology Limited reserves the
                    right to monitor, review, and remove any content that
                    violates these Terms of Service or is deemed inappropriate.
                    This includes:
                  </p>
                  <ul className="ml-5 py-5 list-disc">
                    <li>
                      Content Review: We may review user content to ensure
                      compliance with our policies and guidelines.
                    </li>
                    <li>
                      Content Removal: We reserve the right to remove or disable
                      access to any content that wedetermine, in our sole
                      discretion, violates these Terms of Service or is
                      otherwise harmful to ourusers or platform.
                    </li>
                  </ul>
                </div>
              </ol>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%]">
              License Grant
            </h1>
            <ol className="list-decimal">
              <li className="ml-5 font-bold">
                Grant of License to Vegeel Technology Limited
              </li>
              <div className="py-5">
                <p>
                  By submitting content to our platform, you grant Vegeel
                  Technology Limited a worldwide, non-exclusive, royalty-free,
                  transferable, and sublicensable license to use, reproduce,
                  distribute, prepare derivative works of, display, and perform
                  your content in connection with the operation of our services.
                  This license includes:
                </p>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Content Use: The right to use your content for purposes such
                    as marketing, promotion, and improving our services.
                  </li>
                  <li>
                    Content Distribution: The right to distribute your content
                    across various media and platforms operated by Vegeel
                    Technology Limited.
                  </li>
                  <li>
                    Content Modification: The right to modify or adapt your
                    content as necessary for formatting, clarity, or
                    compatibility with our platform.
                  </li>
                </ul>
              </div>
              <li className="ml-5 font-bold">Retention of Rights</li>
              <div className="py-5">
                <p>
                  You retain all ownership rights to your content. This license
                  does not transfer ownership of your content to Vegeel
                  Technology Limited. Instead, it allows us to use your content
                  in accordance with these Terms of Service.
                </p>
              </div>
              <li className="ml-5 font-bold">Duration of License</li>
              <div className="py-5">
                <p>
                  The license granted to Vegeel Technology Limited continues for
                  as long as your content is available on our platform. If you
                  remove your content or terminate your account, the license
                  will terminate, except for content that has been shared or
                  used prior to removal.
                </p>
              </div>
              <li className="ml-5 font-bold">
                User Representations and Warranties
              </li>
              <div className="py-5">
                <p>By submitting content, you represent and warrant that:</p>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    Ownership or Rights: You own the content or have the
                    necessary rights and permissions to grant the license
                    described in these Terms of Service.
                  </li>
                  <li>
                    No Infringement: Your content does not infringe on the
                    intellectual property rights, privacy rights, or any other
                    legal rights of any third party.
                  </li>
                  <li>
                    Compliance: Your content complies with all applicable laws
                    and regulations, as well as our Terms of Service and Privacy
                    Policy.
                  </li>
                </ul>
              </div>
              <li className="ml-5 font-bold">Indemnification</li>
              <div className="py-5">
                <p>
                  You agree to indemnify and hold Vegeel Technology Limited
                  harmless from any claims, damages, losses, or expenses arising
                  from your content, including but not limited to claims of
                  intellectual property infringement, privacy violations, or
                  breach of these Terms of Service.
                </p>
              </div>
              <li className="ml-5 font-bold">Feedback and Suggestions</li>
              <div className="py-5">
                <p>
                  If you provide feedback, suggestions, or ideas to Vegeel
                  Technology Limited regarding our services, you grant us a
                  worldwide, non-exclusive, royalty-free, transferable, and
                  sublicensable license to use, reproduce, distribute, prepare
                  derivative works of, display, and perform the feedback for any
                  purpose.This includes improving our services and developing
                  new features.
                </p>
              </div>
            </ol>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[#455468] font-bold text-[1.75rem] leading-9 tracking-[-0.4%]">
              Third-Party Links
            </h1>
            <div className="py-5">
              <p>
                We understand that our users may find value in the resources and
                information provided through external links on our website. Here
                our policies and disclaimers regarding third-party links, as
                well as the responsibilities of users when interacting with
                these links.
              </p>
            </div>
            <div>
              <h2 className="text-[#455468] font-bold text-2xl leading-9 tracking-[-0.4%]">
                External Links Disclaimer
              </h2>
              <ol className="list-decimal">
                <li className="ml-5 font-bold">
                  No Endorsement or Responsibility
                </li>
                <div className="py-5">
                  <p>
                    Our website and services may contain links to third-party
                    websites, services, or resources that are not owned or
                    controlled by Vegeel Technology Limited. These external
                    links are provided for your convenience and informational
                    purposes only. We do not endorse, sponsor, or assume
                    responsibility for the content, privacy policies, practices,
                    or services offered by these third-party websites.
                    Specifically:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    No Control: We do not have control over the content or
                    policies of third-party websites.These websites may have
                    their own terms of service and privacy policies, which we
                    encourage you to review.
                  </li>
                  <li>
                    No Endorsement: The inclusion of any external link on our
                    website does not imply endorsement or approval by Vegeel
                    Technology Limited of the linked website, its content, or
                    any products or services it may offer.
                  </li>
                </ul>
                <li className="ml-5 font-bold">Accuracy and Reliability</li>
                <div className="py-5">
                  <p>
                    While we strive to provide links to reliable and reputable
                    sources, we cannot guarantee the accuracy, completeness, or
                    reliability of information found on third-party websites.
                    You acknowledge that:
                  </p>
                </div>
                <ul className="ml-5 py-5 list-disc">
                  <li>
                    <strong>No Guarantee:</strong> Vegeel Technology Limited
                    makes no guarantees regarding the accuracy or reliability of
                    content provided through external links.
                  </li>
                </ul>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
}
