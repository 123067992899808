import { ReactNode, createContext, useContext, useState } from "react";

interface MenuContextType {
  isMenuOpen: boolean;
  showDropdown: string | null;
  activeMenu: string | null;
  openMenu: () => void;
  closeMenu: () => void;
  closeDropdown: () => void;
  toggleDropdown: (label: string) => void;
  handleNavigate: (path: string, label: string, navigate: any) => void;
}

const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const MenuProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const [showDropdown, setShowDropdown] = useState<string | null>(null);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  const openMenu = () => {
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleDropdown = (label: string) => {
    if (showDropdown === label) {
      setShowDropdown(null);
    } else {
      setShowDropdown(label);
    }
  };

  const closeDropdown = () => {
    setShowDropdown(null);
  };

  const handleNavigate = (path: string, label: string, navigate: any) => {
    navigate(path);
    setShowDropdown(null);
  };

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        showDropdown,
        activeMenu,
        openMenu,
        closeMenu,
        closeDropdown,
        toggleDropdown,
        handleNavigate,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = (): MenuContextType => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenu must be used within a MenuProvider");
  }
  return context;
};
